import React,{useState,useContext} from 'react'
import axios from 'axios';
import { SocketContext } from '../../../context/SocketContext';
import { authContext } from '../../../context/Authcontext';
import config from '../../../config';
import Swal from 'sweetalert2'
import { Usepeticion } from '../../peticiones/usepeticion';

const UseAddPettiton = (data) => {
  const { handlerCleandata } = Usepeticion()
  const{socket,online,recolectoressocket}=useContext(SocketContext);
  const {user, Token,RefreshToken,handlerRefreshToken} = useContext( authContext );


    const addpetition = (data) =>{
       
          console.log(data,"DESDE HOOK")
          let headeraddpetition = {
            method: 'post',
            url: `${config.API_URL}Geolocalizacion/addpetition`,
            // url: 'https://wsdesarrollo.paq1.com.mx/api/v2/Geolocalizacion/addpetition',
            headers: { 
              'Authorization': `Bearer  ${Token}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(headeraddpetition)
          .then(function (response) {
            console.log(response.data.idPeticion,"RESOUESTA")
            console.log(typeof(response.data.idPeticion),"RESOUESTA")
            socket.invoke('NewPetition', {id: response.data.idPeticion})
          Swal.fire({
            icon: 'success',
            title: 'Peticion agregada',
            text: '',
            showConfirmButton: false,
            timer: 1000
          })
            console.log(response.data);
           // socket.emit('nueva:peticion', response.data );

          })
          .catch(function (error) {
            if( error.response.status === 401){
              var Requesttoken = JSON.stringify({
                "token": Token,
                "refreshToken": RefreshToken
              });
              
              var Refreshtokenapi = {
                method: 'post',
              maxBodyLength: Infinity,
                url: `${config.API_URL}Login/RefreshToken`,
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : Requesttoken
              };
              
              axios(Refreshtokenapi)
              .then(function (responseToken) {
                console.log(JSON.stringify(responseToken.data));
                handlerRefreshToken(responseToken.data.token,responseToken.data.refreshToken)
              })
              .catch(function (error) {
                console.log(error);
              });
             
          }
            console.log(error);
          });          
    }
  
    return{
      addpetition
  }
}

export default UseAddPettiton