import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { SocketContext } from '../../context/SocketContext';
import { usePeticiones } from '../../hooks/apis/get/usePeticiones';
import useGetusuariosuc from '../../hooks/apis/get/useUsuarioSuc';
import { LayoutTablaPeticion } from '../../themes/layout';
import { LayoutSeleccionarRec } from '../../themes/layout';
// import imgnvo from '../../assets/img/nvo.svg';
import { useNavigate } from 'react-router-dom';
import addRec from '../../assets/img/addRec.svg'
import reasignarRec from '../../assets/img/reasignar.svg';
import imgUsuario from '../../assets/img/usuarioRecolector.png';
import primero from '../../assets/img/primero.svg';
import segundo from '../../assets/img/segundo.svg';
import penultimo from '../../assets/img/penultimo.svg';
import ultimo from '../../assets/img/ultimo.svg';
import editEstatus from '../../assets/img/editar.svg';
import config from '../../config';



const ListaPeticionesTabla = (props) => {

    const customStylesRec = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-5%',
            transform: 'translate(-50%, -50%)',
            background: '#F0F1F3',
            borderRadius: '18px',
            boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
            width: '25%',
            height: '50%'
        },
    };
    const { datousuariosuc } = useGetusuariosuc();
    const [isselected, setisselected] = useState();
    const [RecolectoresArr, setRecolectoresArr] = useState([])

    // const navigate = useNavigate();
    const [modalrecolectores, setmodalrecolectores] = useState(false);
    const [modalEstatus, setmodalEstatus] = useState(false)
    const [paraId, setparaId] = useState();
    const [Selectedactpetition, setSelectedactpetition] = useState()
    // const handleraddpetition = () => {
    //     navigate("/peticiones")
    // }
    const handlerSelectedrec = (data) => {
     
        if (isselected === data) {
            setisselected()
        } else {
            setisselected(data)
        }
    }
    const handlemodalRec = (e) => {
        setmodalrecolectores(true)

    }
    const handlecerrarmodalRec = () => {
        setmodalrecolectores(false)
    }
    const handlemodalEstatus = (e, dataid, dataEst) => {
        setmodalEstatus(true)
        setparaId(dataid)
    }
    const handlecerrarmodalEstatus = () => {
        setmodalEstatus(false)
    }
const handlerupdate = (type, idpeticion, idusuario) =>{
    console.log(type, "aa")
    switch (type) {
        case "A":
            console.log("uwu")
            
            var updatepetition = {
                method: 'get',
                url: `${config.API_SOCKET}/api/Picker/PetitionStatusUpdate/${idpeticion}/${idusuario}/R`,
                headers: {}
            };

            axios(updatepetition)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    handlecerrarmodalEstatus()
                })
                .catch(function (error) {
                    console.log(error);
                });
  
            break;
            case "R":
                console.log("uwu")
                var updatepetition = {
                    method: 'get',
                    url: `${config.API_SOCKET}/api/Picker/PetitionStatusUpdate/${idpeticion}/${idusuario}/R`,
                    headers: {}
                };
    
                axios(updatepetition)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        handlecerrarmodalEstatus()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                
                break;
                case "D":
                    var updatepetition = {
                        method: 'get',
                        url: `${config.API_SOCKET}/api/Picker/PetitionStatusUpdate/${idpeticion}/${idusuario}/D`,
                        headers: {}
                    };
        
                    axios(updatepetition)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            handlecerrarmodalEstatus()
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
            break;
            case "T":
                var updatepetition = {
                    method: 'get',
                    url: `${config.API_SOCKET}/api/Picker/PetitionStatusUpdate/${idpeticion}/${idusuario}/T`,
                    headers: {}
                };
    
                axios(updatepetition)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        handlecerrarmodalEstatus()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
        break;
        case "C":
            var updatepetition = {
                method: 'get',
                url: `${config.API_SOCKET}/api/Picker/PetitionStatusUpdate/${idpeticion}/${idusuario}/C`,
                headers: {}
            };

            axios(updatepetition)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    handlecerrarmodalEstatus()
                })
                .catch(function (error) {
                    console.log(error);
                });
    break;
        default:
            break;
    }
}
    return (

        <LayoutTablaPeticion>
            {/* <div className='containerlistpetitionactions'>
                <div className='containerbuttonsheaderlist'>
                    <div>
                    </div>
                    <div></div>
                    <div></div>
                </div>
                <div onClick={() => handleraddpetition()}>
                    <img src={imgnvo} />
                </div>
            </div> */}
            {
                props.loading === true ?
                    <>
                        <h1>CARGANDO ...</h1>
                    </>
                    :
                    <>
                        <div className="headerTabla">
                            <div className='divOrigen'>
                                <p>Origen</p>
                            </div>
                            <div className='divRec'>
                                <p>Recolector</p>
                            </div>
                            <div className='divCliente'>
                                <p>Cliente</p>
                            </div>
                            <div className='divDir'>
                                <p>Dirección</p>
                            </div>
                            <div className='divEstatus'>
                                <p>Estatus</p>
                            </div>
                            <div className='divHora'>
                                <p>Hora</p>
                            </div>
                        </div>
                        <div className="bodyTabla">
                            {
                                props.data === undefined
                                    ?
                                    <>
                                        <p>Sin datos</p>
                                    </>
                                    :
                                    props.data?.map(respet => {
                                 

                                        return (
                                            <div className='containerrowstablepetitions'>
                                                <div className='divOrigen'>
                                                    <img src={respet.origen === "SUCURSAL" ? "https://wsdesarrollo.paq1.com.mx/IMG/sucursal.png" : "https://wsdesarrollo.paq1.com.mx/IMG/onsite.png"} alt="" />
                                                </div>
                                                <div className='divRec'>
                                                    <p>{respet.nombreusuarioasignado === "" ? "Sin asignar" : respet.nombreusuarioasignado}</p>
                                                    {/* {
                                            datousuariosuc?.map(reco => {
                                               
                                                
                                                {
                                                    if(reco.id === respet.usuarioasignado){
                                                        return(
                                                            <p>{reco.descripcion}</p>
                                                        )
                                                    }
                                                    
                                                }
                                            })
                                           } */}
                                                </div>
                                                <div className='divCliente'>
                                                    <p>{respet.cliente}</p>
                                                </div>
                                                <div className='divDir'>
                                                    <p>{respet.direccion}</p>
                                                </div>
                                                <div className='divEstatus'>
                                                    <p>{respet.estatus === 'A' ? "Asignado"
                                                        :
                                                        respet.estatus === 'D' ? "Documentando"
                                                            :
                                                            respet.estatus === 'R' ? "Recolectando"
                                                                :
                                                                respet.estatus === 'T' ? "Terminado"
                                                                    :
                                                                    respet.estatus === 'C' ? "Cancelado"
                                                                        :
                                                                        "Pendiente"}</p>
                                                </div>
                                                < div className='containerlastpetitions divHora'>
                                                    <p>{respet.fecha} {JSON.stringify(respet.usuarioasignado)}</p>
                                                    <div className={`containeractionstablepetitions ${respet.id === isselected ? "active" : ""}`} onClick={() => handlerSelectedrec(respet.id)}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                    <div className="containerAction" style={respet.id !== isselected ? { display: 'none' } : { background: '#D9D9D9' }}>
                                                        <div className="add" style={respet.usuarioasignado === "0" ? { display: 'block' } : { display: 'none' }} >
                                                            <img src={addRec} alt="" />
                                                            <p onClick={(e) => handlemodalRec(e)}>Agregar recolector</p>
                                                        </div>
                                                        <div className="reasignar" style={respet.usuarioasignado != "0" ? { display: 'none' } : { display: 'block' }}>
                                                            <img src={reasignarRec} alt="" />
                                                            <p onClick={(e) => handlemodalRec(e)}>Reasignar recolector</p>
                                                        </div>
                                                        <div className="modificarEstatus">
                                                            <img src={editEstatus} alt="" />
                                                            <p onClick={(e) => handlemodalEstatus(e, respet.id, respet.estatus)}>Modificar estatus</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                            }

                        </div>
                    </>
            }
            <Modal isOpen={modalrecolectores} style={customStylesRec}>
                <LayoutSeleccionarRec>
                    <div className="modalDetallesPeticion">
                        <div className="cabezera">
                            <div className="cerrar" onClick={() => handlecerrarmodalRec()}>x</div>
                            <div className='recolectores'>
                                <h3>
                                    Seleccionar recolector
                                </h3>
                            </div>
                        </div>
                        <div className="bodyClientes">
                            <div style={{ displ: 'flex', flexDirection: 'column' }}>
                                {
                                    datousuariosuc?.map(r => {

                                        if (r.id !== 0) {
                                            return (
                                                <>
                                                    <div className='containerlistclientes'  >
                                                        <img src={imgUsuario} alt="" />
                                                        <p>{r.descripcion}</p>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </LayoutSeleccionarRec>
            </Modal >

            <Modal isOpen={modalEstatus} style={customStylesRec}>
                <LayoutSeleccionarRec>
                    <div className="modalDetallesPeticion">
                        <div className="cabezera">
                            <div className="cerrar" onClick={() => handlecerrarmodalEstatus()}>x</div>
                            <div className='recolectores'>
                                <h3>
                                    Actualizar estatus
                                </h3>
                            </div>
                        </div>
                        <div className="bodyClientes" style={{ displ: 'flex', flexDirection: 'column' }}>
                            {
                                props.data?.map(respEst => {
                                    console.log(respEst, "xddd")
                                    if (respEst.id === paraId) {
                                        return (
                                            <>
                                                <div className='clienteEstatus'>
                                                    <h3>
                                                        Cliente:
                                                    </h3>
                                                    <p>{respEst.cliente}</p>
                                                </div>
                                                <div className="editarEstatus">
                                                    <div>
                                                        <h3>
                                                            Estatus actual:
                                                        </h3>
                                                        <p>{respEst.estatus === "A" ? "Asignado"
                                                            :
                                                            respEst.estatus === "R" ? "Recolectando"
                                                                :
                                                                respEst.estatus === "D" ? "Documentando"
                                                                    :
                                                                    respEst.estatus === "T" ? "Terminado"
                                                                        :
                                                                        respEst.estatus === "C" ? "Cancelado"
                                                                            :
                                                                            "Pendiente"}</p>
                                                    </div>
                                                    <div>
                                                        <h3>
                                                            Actualizar estatus:
                                                        </h3>
                                                        <ul>
                                                                <li
                                                                 style={respEst.estatus === "A" ? {background: 'transparent'} : {display: "none"} }
                                                                onClick={()=> setSelectedactpetition("R")}
                                                                 >Recolectando</li>
                                                                <li
                                                                style={respEst.estatus === "R" ? {background: 'transparent'} : {display: "none"} }
                                                                onClick={()=> setSelectedactpetition("D")}
                                                                >Documentando</li>
                                                                <li
                                                                style={respEst.estatus === "D" ? {background: 'transparent'} : {display: "none"} }
                                                                onClick={()=> setSelectedactpetition("T")}
                                                                >Terminar</li>
                                                                <li
                                                                onClick={()=> setSelectedactpetition("C")}
                                                                >Finalizar</li>
                                                               
                                                            </ul>
                                                    </div>
                                                </div>
                                                <button onClick={() => handlerupdate(Selectedactpetition, respEst.id, respEst.idusuarioasignado)}>Guardar</button>
                                            </>
                                        )
                                    }
                                }
                                )
                            }
                        </div>
                    </div>
                </LayoutSeleccionarRec>
            </Modal >
            {/* <div className="paginador">
    <div className='flecha'>
        <img src={primero} alt="" />
    </div>
    <div className="flecha">
        <img src={segundo} alt="" />
    </div>
    <div className='num'>
        <p>1</p>
    </div>
    <div className="num">
        <p>2</p>
    </div>
    <div className="num">
        <p>3</p>
    </div>
    <div className="num">
        <p>4</p>
    </div>
    <div className="num">
        <p>5</p>
    </div>
    <div className="flecha">
        <img src={penultimo} alt="" />
    </div>
    <div className="flecha">
        <img src= {ultimo} alt="" />
    </div>
</div> */}
        </LayoutTablaPeticion >
    )
}

export default ListaPeticionesTabla