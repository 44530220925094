import React,{useContext} from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import CrearZona from '../pages/CrearZona';
import Dashboard from '../pages/dashboard';
import Loginpage from '../pages/login';
import Zona from '../pages/Zona';
import { ProtectedRoute } from './Privateroute';
import Mapa from '../pages/Mapa';
import Peticiones from '../pages/Peticiones';
import Analiticapage from '../pages/Analitica';
import ListaPeticiones from '../pages/ListaPeticiones';
import PdfTest from '../pages/pdftest';

  

function Navigation() {
  return (
    <Router>
       <Routes>
          <Route path="/login" element={<Loginpage />} />

          {/* RUTAS PRIVADAS */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Mapa"
            element={
              <ProtectedRoute>
                <Mapa />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Nuevazona"
            element={
              <ProtectedRoute>
                <CrearZona />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Zonas"
            element={
              <ProtectedRoute>
                <Zona />
              </ProtectedRoute>
            }
          />
          <Route
          path="/Peticiones"
          element= {
            <ProtectedRoute>
              <Peticiones />
            </ProtectedRoute>
          }/>

        <Route
          path="/analitica"
          element= {
            <ProtectedRoute>
              <Analiticapage />
            </ProtectedRoute>
          }/>
          
        <Route
          path="/listapeticiones"
          element= {
            <ProtectedRoute>
              <ListaPeticiones />
            </ProtectedRoute>
          }/>
            <Route
          path="/pdfviwer"
          element= {
            <ProtectedRoute>
              <PdfTest />
            </ProtectedRoute>
          }/>


        </Routes>
    </Router>
  )
}

export default Navigation