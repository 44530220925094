import React,{useState,useContext} from 'react';
import { authContext } from '../../../context/Authcontext';
import axios from 'axios';
import config from '../../../config';

const UsePostAddfance = () => {
    const{user, Token, RefreshToken,handlerRefreshToken}= useContext(authContext)
    const [cordsaddfance, setcordsaddfance] = useState()

    const handleraddfance = (data) => {
        let Addgeofance = {
        method: 'post',
        url: `${config.API_URL}Geolocalizacion/addfance`,
        headers: { 
            'Authorization': `Bearer  ${Token}`, 
            'Content-Type': 'application/json'
          },
        data : data
        };

        axios(Addgeofance)
        .then(function (response) {
        console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            if( error.response.status === 401){
                var Requesttoken = JSON.stringify({
                  "token": Token,
                  "refreshToken": RefreshToken
                });
                
                var Refreshtokenapi = {
                  method: 'post',
                maxBodyLength: Infinity,
                  url: `${config.API_URL}Login/RefreshToken`,
                  headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : Requesttoken
                };
                
                axios(Refreshtokenapi)
                .then(function (responseToken) {
                  console.log(JSON.stringify(responseToken.data));
                  handlerRefreshToken(responseToken.data.token,responseToken.data.refreshToken)
                })
                .catch(function (error) {
                  console.log(error);
                });
               
            }
        console.log(error);
        });
    }
    const handleraddcords = (data) =>{
        setcordsaddfance(data)
    }
  
    return{
        handleraddfance,
        handleraddcords,
        cordsaddfance
  }
}

export default UsePostAddfance