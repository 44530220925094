import React,{useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {LayoutGraficahome} from '../../themes/layout'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

const WidgetGraficahome = (props) => {

  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)


    const [Actual, setActual] = useState(props.data.mesanterior)
    const [Anterior, setAnterior] = useState(props.data.mesactual)
    const [Usuariossuc, setUsuariossuc] = useState(props.usuarios)
    ChartJS.register(ArcElement, Tooltip, Legend);
    const options = { // config options <<<<<<<<---------
      plugins: {
      legend: {
      display: false,
      },
      },
      };
    const data = {
        labels: ['Mes anterior','Mes actual'],
        datasets: [
          {
            labels: ['Mes anterior','Mes actual'],
            data: [Actual, Anterior],
            backgroundColor: [
              '#F0F1F3',
              '#3BAF2A'
            ],
            borderColor: [
              '#151B4E',
              '#151B4E'            
            ],
            borderWidth: 2,
          },
        ],
      };

      const hanlderopenModalAnalitica = async() => {
        const dd = document.querySelector("#grfica")
        const response = await htmlToImage.toPng(dd);
        // let test = test.toPng(dd)
     
        navigate(
          "/pdfviwer", {state:{
            image: response,
            type: "dona",
            datadona:{
              "Actual": Actual,
              "Anterior": Anterior,
              "colorActual": "#3BAF2A",
              "colorAnterior": "#F0F1F3",
              
            },
            "usuarios": props.usuarios,
            dataradar:{

            }
          }}
        )
        
        // MySwal.fire(
        //   <PDFViewer>
        //      {/* <img src={response} alt="" srcset="" /> */}
        //      <Document>
        //         <Page size="A4" style={{width:'100%'}}>
        //             <View>
        //                <Text>Hola mundo</Text>
        //                <Image src={response} alt="" srcset="" />
        //             </View>
        //             <View>
                      
        //             </View>
        //         </Page>
        //      </Document>
        //   </PDFViewer>
        // )
      }

  return (
    <LayoutGraficahome>
        <div className="graficaHome" onClick={() => hanlderopenModalAnalitica()}>
<div className="containerGrafica">
<Doughnut data={data}  options={options}  id="grfica"/>
    </div>
        <div className='containerbodycarthome'>
        <div className="letrasGrafica">
        <h3>Venta de toda la ciudad</h3>
        <p> Diciembre 2022</p>
    </div>
        <p className='floattotal'>${props.data.total}</p>
        <div className='containeran'>
            {
              data.labels.map(res => {
                return(
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center',position:'relative'}}>
                     <span className='contanieranc' style={res.includes("actual") ? {background: '#3BAF2A'} : {background: '#F0F1F3'}} ></span><p>{res}</p>
                  </div>
                )
              })
            }
        </div>
        </div>
    </div> 
    </LayoutGraficahome>
  )
}

export default WidgetGraficahome