import React,{useEffect,useContext,useState} from 'react';
import { useAuth } from '../../context/Authcontext';
import { Usemapboxgeofance } from '../../hooks/usemapboxgeofance';
import { Datossuc } from '../../hooks/apis/get/datossuc';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAhZ8bJJ1Od9n-eR9-Hx-jkAmMLml4EZlA");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const puntoInicial = {
    lng: -108.98972335988667,
    lat: 25.79109091258725,
    zoom: 12
  }

  export const MapcomponentNewfance = (props) =>{
    const [puntoinicial, setpuntoinicial] = useState(JSON.parse(localStorage.getItem('coordenadamapa')))
    const{datossuc}=Datossuc()
    const { setRef } = Usemapboxgeofance(puntoInicial,props.datosdezona, props.accionguardarcordenada,props.geoJsonZona,datossuc.data);
 
    return(
        <div 
        ref={ setRef }
        className="mapContainer"
    />
    )

 }