import React,{useState,useEffect,useContext} from 'react';
import config from '../../../config';
import axios from 'axios';

export const UseCliente = (busqueda) => {
    // A R E A  D E  V A R I A B L E S //
    const [clientes, setcliente] = useState();
    const [Direcciones, setDirecciones] = useState()
    const [serch, setserch] = useState(busqueda);
    const [loadingCliente, setloadingCliente] = useState(true)
    // A R E A   D E  F U N C I O N E S //
    const loadcliente = (data) =>{
      setloadingCliente(true)
        let datas = '';
        let Getclientes = {
          method: 'get',
          url: `${config.API_URL}Cliente/paginator/all?search=${busqueda === undefined ? "" : busqueda}&page=1&registerbypage=10`,
          headers: { },
          data : datas
        };
        axios(Getclientes)
        .then(function (response) {
          setloadingCliente(false)
          setcliente(response.data.resultado)
        })
        .catch(function (error) {
          setloadingCliente(false)
          console.log(error);
        });
    }
    const loadAdressSn = (data) =>{
      setloadingCliente(true)
      let datas = '';
      let Getclientes = {
        method: 'get',
        url: `${config.API_URL}Cliente/Address/Get/${data}?page=1&registerbypage=9`,
        headers: { },
        data : datas
      };
      axios(Getclientes)
      .then(function (response) {
        setloadingCliente(false)
        setDirecciones(response.data.resultado)
        console.log(`${config.API_URL}Cliente/Address/Get/${data}?page=1&registerbypage=9`,"URL")
      })
      .catch(function (error) {
        setloadingCliente(false)
        console.log(error);
      });
  }
    
   
  return{
    clientes,
    loadcliente,
    Direcciones,
    loadAdressSn,
    loadingCliente
  }
}

