import React from 'react'

export const Datossuc = () => {
    const datossuc = {
        "data": [
          {
            "id": 1,
            "nombre": "Los Mochis",
            "lng": "-108.98975476758359",
            "lat": "25.791012265106975"
          },
          {
            "id": 2,
            "nombre": "Guadalajara",
            "lng": "-103.3735566306815",
            "lat": "20.6556081050003"
          }
          ,
          {
            "id": 3,
            "nombre": "Monterrey",
            "lng": "-100.23118758343404",
            "lat": "25.659682086624464"
          }
          ,
          {
            "id": 4,
            "nombre": "Culiacán",
            "lng": "-107.40900343631458",
            "lat": "24.79418691388473"
          }
          ,
          {
            "id": 9,
            "nombre": "Hermosillo",
            "lng": "-110.97228450608758",
            "lat": "29.100528789750044"
          }
          ,
          {
            "id": 28,
            "nombre": "Chihuahua",
            "lng": "-106.094788433692",
            "lat": "28.642300744104237"
          },
          {
            "id": 57,
            "nombre": "Querétaro",
            "lng": "-100.39677119562438",
            "lat": "20.584785260756675"
          },
          {
            "id": 75,
            "nombre": "Querétaro",
            "lng": "-116.99101041380388",
            "lat": "32.52826663997811"
          },
          {
            "id": 90,
            "nombre": "Monterrey General Escobedo",
            "lng": "-100.30349594110463",
            "lat": "25.776341400003716"
          }
        ]
      }
  return {
    datossuc
  }
}
