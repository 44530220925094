import React, { useContext, useState, useEffect } from 'react';
import usuario from '../assets/img/usuario.png';
import contra from '../assets/img/contra.png';
import { useAuth } from '../context/Authcontext';
import { useNavigate } from "react-router-dom";
import moment from 'moment/moment';
import { LayoutcontainerLogin } from '../themes/layout';
import Loader from '../components/loader/loader';
function Loginpage() {
  const [mesActual, setmesActual] = useState(moment().format("MM"))
  const { login, loading, messageerror, setLoading } = useAuth();
  //console.log(process.env)
  const navigate = useNavigate()
  const [user1, setuser] = useState('');
  const [pass, setpass] = useState('');





  // const auth = null; // determine if authorized, from context or however you're doing it


  const handleSubmit = async (e) => {
    e.preventDefault();
   
    let data = JSON.stringify({
      "nombreUsuario": user1,
      "pass": pass
    });
    try {
      await login(data, navigate);
     

    } catch (error) {
      
    }
  };

  return (
    <>
      <LayoutcontainerLogin>
        {
          loading ?
            <>
              <div className="login-principal" style={
                mesActual === '1' ? { backgroundImage: `url('https://api.pktuno.mx//IMG/fondoa%C3%B1onvo.png')` } :
                  mesActual === '5' ? { background: `url('https://api.pktuno.mx//IMG/fondoaniversario.png')` } :
                    mesActual === '11' ? { background: `url('https://api.pktuno.mx//IMG/fondonavidad.png')` } :
                      mesActual === '12' ? { background: `url('https://api.pktuno.mx//IMG/fondonavidad.png')` }
                        : { backgroundImage: `url('https://api.pktuno.mx//IMG/fondopkt1.png')` }
              }>
                <div className="contendor-login">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="contenedor-datos">
                      <h1>Bienvenido
                        {process.env.REACT_APP_SECRET_NAME}
                      </h1>
                      <h2><span><img src={usuario} /></span>Usuario</h2>
                      <input onChange={(e) => setuser(e.target.value)}></input>
                      <h2><span><img src={contra} /></span>Contraseña</h2>
                      <input type="password" onChange={(e) => setpass(e.target.value)}></input>
                      <h3>Recuperar contraseña</h3>
                      <div className="btnIngresar">
                        <button type="submit" value="Ingresar">Ingresar</button>
                      </div>
                    </div>
                  </form>
                  <div className="contenedor-img">
                    <img src={mesActual === "1" ? "https://api.pktuno.mx//IMG/loginizqnuevo.png" :
                      mesActual === "5" ? "https://api.pktuno.mx//IMG/loginizqaniversario.png" :
                        mesActual === "11" ? "https://api.pktuno.mx//IMG/loginizqnavidad.png" :
                          mesActual === "12" ? "https://api.pktuno.mx//IMG/loginizqnavidad.png"


                            : "https://api.pktuno.mx//IMG/loginizqpkt1.png"} alt="" />
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh', background: '#D9D9D9' }}>
                <Loader
                  title="Iniciando sesión ...">
                </Loader>
              </div>
            </>
        }

      </LayoutcontainerLogin>
    </>
  )
}

export default Loginpage