import React,{useState} from 'react';
import UsePostAddfance from '../hooks/apis/post/usePostAddfance';
import { useAuth } from '../context/Authcontext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Addfance = (props) => {
    const MySwal = withReactContent(Swal);
    const{user,ciudadSelecionada}=useAuth();
    const{handleraddfance}=UsePostAddfance();
    const [nombre, setnombre] = useState('');
    const [color, setcolor] = useState('');
    const [disabled, setdisabled] = useState(false)
    const handlechanguenombre = (data) => {
        setnombre(data)
    }
    const handlegotorecolector = () =>{
        window.scrollTo(0, 500);
    }
    const handlesubmitaddfance = async(e) => {
// disabled={props.data.length <= 0 ? true : false}
        if(props.data?.length === 0){
            Swal.fire({
                icon: 'error',
                title: 'No hay recolectores seleccionados',
                text: '',
                confirmButtonColor: "#3BAF29"
              }).then((result) => {
                window.scrollBy(0, 900)
                props.navegar()
              })
        } else {
            let color;
            let disabled2 = false;
            e.preventDefault()
             const Arrayporciudad = props.datosdezona === "" ? [] : props.datosdezona?.filter(datos => JSON.stringify(datos.idciudad).includes(JSON.stringify(ciudadSelecionada.idSucursal)));
            if(Arrayporciudad.length === 0){
                color ="#3BAF29"
            }else if(Arrayporciudad?.length === 1){
                color="#35C1ED"
            }else if(Arrayporciudad?.length === 2){
                color="#1EE27B"
            }else if(Arrayporciudad?.length === 3){
                color="#F98500"
            }else if(Arrayporciudad?.length === 4){
                color="#FFB531"
            }else if(Arrayporciudad?.length === 5){
                color="#EDA15C"
            }else if(Arrayporciudad?.length > 5){
                disabled2=true
                setdisabled(true)
            }
            var data = JSON.stringify({
                "idfranquicia": user.idsucursal,
                "cordenadas": props?.cordenadas,
                "estatus": "A",
                "nombre": nombre,
                "recolectores": props.data?.map(res => res[0].id),
                "idciudad": ciudadSelecionada.idSucursal,
                "color": color
                })
               
                if(color === undefined){
                    Swal.fire({
                        icon: 'error',
                        title: 'Se alcanzo el maximo de zonas para la ciudad',
                        text: '',
                        confirmButtonColor: "#3BAF29"
                      })
                } else {
                    // handleraddfance(data)
                    Swal.fire({
                        title: 'Esta por crear una nueva Zona',
                        showDenyButton: true,
                        confirmButtonText: 'Creear',
                        denyButtonText: `Cancelar`,
                        denyButtonColor: "#DC1D1D",
                        confirmButtonColor: "#3BAF29"
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            handleraddfance(data)
                          Swal.fire('Creada!', '', 'success').then((result) =>{
                           window.location.reload();
                            
                          })
                        } else if (result.isDenied) {
                          Swal.fire('No se agrego la zona', '', 'info')
                        }
                      })
                }
        }
       
    }
  return (
    <div className='containneraddfanceg' style={{width:'15%'}}>
        <div className='title' style={{textAlign:'center'}}>
           <h3>Nueva Zona</h3>
           
        </div>

        <form>
        <div style={{textAlign:'center'}}>  
            <input onChange={(e) => handlechanguenombre(e.target.value)} className='inputnameaddfance' type="text" placeholder='Nombre' />
        </div>

        <div>
            <h4 style={{textAling:'center'}}>{props.data?.length === 0 ? 'Sin recolectores selecionados' : 'Recolectores seleccionados'}</h4>
            {
                props.data?.map(res =>{
                    return(
                        <>
                        <div>
                            <h4>{props.data?.length <= 0 ? 'Sin Recolector' : ''}</h4>
                        </div>
                        <p>{res[0].nombre}</p>
                        </>
                    )
                })
            }
        </div>
        <div className='containerbuttonadd'>
            <button onClick={(e) => handlesubmitaddfance(e)} type='submit' >Guardar</button>
        </div>
        </form>
    </div>
  )
}

export default Addfance