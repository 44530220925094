import React from 'react';
import Layoutgrafica from '../components/graficas/Layoutgrafica';

const Analiticapage = () => {
  return (
    <>
        <Layoutgrafica/>
    </>
  )
}

export default Analiticapage