import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Oscar Castro', power: 87 },
  { name: 'Mario Lopez', power: 60 },
  { name: 'Roberto Calvo', power: 45 },
  { name: 'Iris Galavis', power: 55 }
];

const VerticalBarChart = () => {
    return (
        <BarChart width={500} height={300} data={data} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip />
          <Legend />
          <Bar dataKey="power" fill="#8884d8" />
        </BarChart>
      );
    };

export default VerticalBarChart;