import React, { useState, useEffect } from 'react';
import { LayouPeticiones } from '../../themes/layout';
import { useAuth } from '../../context/Authcontext';
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Usepeticion } from '../../hooks/peticiones/usepeticion';
import { UseCliente } from '../../hooks/apis/get/useCliente';
import { Useaddrecolector } from '../../hooks/apis/useAddrecolector';
import { usePeticiones } from '../../hooks/apis/get/usePeticiones';
import UseAddPettiton from '../../hooks/apis/post/useAddpetition';
import moment from 'moment/moment';
import { LayoutModalDetalles } from '../../themes/layout';
import { LayoutSeleccionarCliente } from '../../themes/layout';
import useGetusuariosuc from '../../hooks/apis/get/useUsuarioSuc';
import imgSucursal from '../../assets/img/LogoPKT1.svg';
import imgOnsite from '../../assets/img/Onsite.png';
import imgBuscar from '../../assets/img/buscarbtn.svg';
import imgCarritoCarga from '../../assets/img/imgCarrito.svg'
import imgAgregar from '../../assets/img/agregar.svg';
import imgEditar from '../../assets/img/editar.svg';
import imgEliminar from '../../assets/img/eliminar.svg';
import imgRecolector from '../../assets/img/usuarioRecolector.png'
import imgAsignar from '../../assets/img/userCheck.svg';
import imgSinAsignar from '../../assets/img/userDontCheck.png';
import imgCaja from '../../assets/img/iconCaja.png';
import imgSobre from '../../assets/img/iconoSobre.png';
import imgTarima from '../../assets/img/iconoTarima.png';
import imgDireccion from '../../assets/img/direccion.svg';
import 'moment-timezone';
import Loader from '../loader/loader';

const Peticionescomponent = () => {
    const customStylesDetalles = {
        content: {
            top: '50%',
            left: '60%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-5%',
            transform: 'translate(-70%, -50%)',
            background: '#F0F1F3',
            borderRadius: '18px',
            boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
            width: '40%'
        },
    };
    const customStylesClientes = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-5%',
            transform: 'translate(-50%, -50%)',
            background: '#F0F1F3',
            borderRadius: '18px',
            boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
            width: '80%',
            height: '90%'
        },
    };
    const customStylesDirecciones = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-5%',
            transform: 'translate(-50%, -50%)',
            background: '#F0F1F3',
            borderRadius: '18px',
            boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
            width: '80%',
            height: '80%'
        },
    };

    // A R E A  D E  V A R I A B L E S 
    const MySwal = withReactContent(Swal);
    const [modalDetallePet, setmodalDetallePet] = useState(false);
    const [modalcliente, setmodalcliente] = useState(false);
    const [modalDirecciones, setmodalDirecciones] = useState(false)
    const [isactiveorigen, setisactiveorigen] = useState("");
    const [isactivetipo, setisactivetipo] = useState();
    const [isnewmercancia, setisnewmercancia] = useState(0);
    const [contenido, setcontenido] = useState("");
    const [cantidad, setcantidad] = useState("");
    const [largo, setlargo] = useState(0);
    const [ancho, setancho] = useState(0);
    const [alto, setalto] = useState(0);
    const [peso, setpeso] = useState("");
    const [origentipo, setorigentipo] = useState("New");
    const [isIdedit, setisIdedit] = useState();
    const [recolectoresselecionados, setrecolectoresselecionados] = useState([])
    const [isSelectedcliente, setisSelectedcliente] = useState(false);
    const [isSelectedclientedireccion, setisSelectedclientedireccion] = useState(false);
    const [selectedcliente, setselectedcliente] = useState();
    const { origen, handlerOrigen, cliente, handlerCliente, codigop, handlerCodigop, calle, handlerCalle, numero, handlerNumero, colonia, handlerColonia, ciudad, handlerCiudad, estado, handlerEstado, pais, handlerPais, detalles, handleradddetalle, handlerChanguedetalle } = Usepeticion()
    const { clientes, loadcliente, Direcciones, loadAdressSn,loadingCliente } = UseCliente(cliente);
    const { datousuariosuc } = useGetusuariosuc();
    const [idcliente, setidcliente] = useState();
    const [fechaactual, setfechaactual] = useState(moment().tz('US/Arizona').format())
    const { handleactiveOrigen,
        isactiveOrigen, user } = useAuth()
    const { recolectores, handlerecolectorgeo } = Useaddrecolector();
    const { addpetition } = UseAddPettiton();
    const [isclientselected, setisclientselected] = useState(false)
    const handlemodalpet = () => {
        setmodalDetallePet(true)
    }
    const handlecerrarmodal = () => {
        setmodalDetallePet(false)
    }
    const handlemodalcliente = () => {
        setmodalcliente(true)
    }
    const handlecerrarmodalcliente = () => {
        setmodalcliente(false)
    }
    const handlemodaldirecciones = () => {
        setmodalDirecciones(true)
    }
    const handlecerrarmodaldirecciones = () => {
        setmodalDirecciones(false)
    }
    const handleDetalles = () => {
        setorigentipo("New")
        handlemodalpet()
    }
    const handleCambio = (e, type) => {
        e.preventDefault()
        if (type === "OrigenSuc") {
            setisactiveorigen(type)
            handlerOrigen("Sucursal")
        } else if (type === "origenOS") {
            setisactiveorigen(type)
            handlerOrigen("Onsite")
        }
    }
    const handleisclientselected = (e) => {
        e.preventDefault()
        if (isclientselected === true) {
            setisclientselected(true)
        } else {
            setisclientselected(false)
        }
    }
    useEffect(() => {
        const newdataarraymercancia = {
            cantidad: "paquete",
            tipo: "1",
            contenido: null,
            alto: null,
            ancho: null,
            alto: null,
            peso: null,
            clavesat: null
        }
        setisactivetipo("1")
        handleradddetalle([...detalles, newdataarraymercancia])
    }, [setisactivetipo])

    const handlerchangueTipo = (e, id, data) => {
        e.preventDefault()
        const newArraymerc = detalles.map((respdetalle, index) => {
            if (id === index) {
                respdetalle[0].tipo = data;
            }
            return respdetalle
        })
        setisactivetipo(data)
        handlerChanguedetalle(newArraymerc)
    }
    const handleraddmerc = (e, id, data) => {
        setorigentipo("New")
        e.preventDefault()
        const newArraymerc = detalles.map((respdetalle, index) => {
            if (id === index) {
                respdetalle[0].contenido = contenido;
                respdetalle[0].cantidad = cantidad;
                respdetalle[0].alto = alto;
                respdetalle[0].ancho = ancho;
                respdetalle[0].alto = largo;
                respdetalle[0].peso = peso;
            }
            return respdetalle
        })
        handlerChanguedetalle(newArraymerc)
        setmodalDetallePet(false)
    }

    const handlernewmerc = (e) => {
        e.preventDefault()
        setisnewmercancia(isnewmercancia + 1)
        const newdataarraymercancia = {
            cantidad: "paquete",
            tipo: "1",
            contenido: null,
            alto: null,
            ancho: null,
            alto: null,
            peso: null,
            clavesat: null
        }
        setisactivetipo("1")
        handleradddetalle([newdataarraymercancia])
        handlemodalpet()
    }
    const handlerEditmerc = (e, id, data) => {
        setorigentipo("Edit")
        setisIdedit(id)
        setisnewmercancia(id)
        e.preventDefault()

    }
    const handleOpenEditModal = () => {
        handlemodalpet()
    }
    const handleremovemerc = (data) => {
        const remover = detalles.filter((data, index) => index != isIdedit)
        handlerChanguedetalle(remover)
    }
    const addcliente = (e) => {
        e.preventDefault()
        handlemodalcliente()
        loadcliente()
        setisclientselected(true)
    }
    const handleselectcliente = (e, data) => {
        e.preventDefault()
        handlecerrarmodalcliente()
        setisSelectedclientedireccion(false)
        setisSelectedcliente(true)
        setselectedcliente(data)
        handlerCliente(data.nombrecompleto)
        handlerCodigop(data.cp)
        handlerCalle(data.calle)
        handlerNumero(data.noext)
        handlerColonia(data.colonia)
        handlerCiudad(data.ciudad)
        handlerEstado(data.estado)
        handlerPais(data.pais)
        setidcliente(data.id)
    }
    const handleselectclientedireccion = (e, data) => {
        e.preventDefault()
        handlecerrarmodaldirecciones()
        setisSelectedcliente(true)
        setisSelectedclientedireccion(true)
        setselectedcliente(data)
        handlerCliente(data.nombrecompleto)
        handlerCodigop(data.cp)
        handlerCalle(data.calle)
        handlerNumero(data.noext)
        handlerColonia(data.colonia)
        handlerCiudad(data.localidad)
        handlerEstado(data.estado)
        handlerPais(data.pais)
    }
    const handlerdeselectedcliente = (e) => {
        e.preventDefault()
        setisSelectedcliente(false)
        setisSelectedclientedireccion(false)
        setselectedcliente("")
        handlerCliente("")
        handlerCodigop("")
        handlerCalle("")
        handlerNumero("")
        handlerColonia("")
        handlerCiudad("")
        handlerEstado("")
        handlerPais("")
        setidcliente(undefined)
        setisclientselected(false)
    }
    const handlerselecteddireccion = (e, id) => {
        e.preventDefault()
        loadAdressSn(idcliente)
        handlemodaldirecciones()
    }
    // const handlerpeso = (e, tipo, value) => {
    //     tipo === 0;
    //     if (value <= 2) {
    //         setpeso(value)
    //     } else{
    //         Swal.fire({
    //             icon: 'error',
    //             title: "uwu",
    //             confirmButtonColor: "#3BAF29"
    //         }).then((result) => {
    //             window.scrollBy(0.900)

    //         })

    //     }
    const handleraddrecolector = (e, index, data) => {
        if (recolectoresselecionados.includes(data.id)) {  // esta dentro ?
            const remover = recolectoresselecionados.filter((rr, index) => rr != data.id)  // funcion para remover va a valer todo menos lo buscado
            setrecolectoresselecionados(remover) // asignas valor sin el dato buscado
        }
        else if (recolectoresselecionados.length > 0) {

        } else {
            setrecolectoresselecionados([...recolectoresselecionados, data.id]) // Añadir a un Array   ...Copia
        }
    }
    const hanlderSubmint = (e) => {
        if (cliente.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'No hay cliente seleccionado',
                confirmButtonColor: "#3BAF29"
            }).then((result) => {
                window.scrollBy(0.900)
            })
        }
        if (calle.length === 0 | numero.length === 0 | colonia.length === 0 | codigop.length === 0 | ciudad.length === 0 | estado.length === 0 | pais.length === 0) {
            Swal.fire({
                icon: 'error',
                title: "Favor de escribir la dirección completa",
                confirmButtonColor: "#3BAF29"
            }).then((result) => {
                window.scrollBy(0.900)
            })
        }
        if (!origen){
            Swal.fire({
                icon: 'error',
                title: "Favor de seleccionar origen",
                confirmButtonColor: "#3BAF29"
            }).then((result) => {
                window.scrollBy(0.900)
            })
        }
        else {
            let asignado = recolectoresselecionados.length === 0 ? undefined : JSON.parse(recolectoresselecionados);
  
            const remover = detalles.filter((data, index) => index != 0)
         
            let data = JSON.stringify({
                "origen": origen,
                "direccion": `${calle} ${numero}, ${colonia}, ${codigop}, ${ciudad} , ${estado}., ${pais}`,
                "cliente": cliente,
                "estatus": asignado === undefined || asignado === "" || asignado === [] ? "P" : "A",
                "fecha": fechaactual,
                "usuarioasignado": asignado,
                "usuario": user.id,
                "detalles": remover.map(r => r[0])
            });
            addpetition(data)
            handlerdeselectedcliente(e)
        }
    }


    return (
        <LayouPeticiones>
            <div className="containerPeticiones">
                <div className="containerIzq">
                    <div className="containerAdd">
                        <div className='containerTituloAdd'>
                            <h3>Añadir</h3>
                        </div>
                        <div className="origen">
                            <p>Origen:</p>
                            <div className="imgOrigen">
                                <div className={`containerimgorigen ${isactiveorigen === 'OrigenSuc' ? "active" : ""}`} onClick={(e) => handleCambio(e, "OrigenSuc")}>
                                    <img src={imgSucursal} />
                                </div>
                                <div className={`containerimgorigen ${isactiveorigen === 'origenOS' ? "active" : ""}`} onClick={(e) => handleCambio(e, "origenOS")}>
                                    <img src={imgOnsite} />
                                </div>

                            </div>
                        </div>
                        <div className="campocliente">
                            <div className="buscar">
                                <p>Cliente:</p>
                                <input disabled={isclientselected ? true : false } onChange={(e) => handlerCliente(e.target.value)} value={isSelectedcliente === true ? selectedcliente.nombrecompleto : cliente}></input>
                            </div>
                            <div className="iconoBuscar">
                                <img src={imgBuscar} alt="" onClick={(e) => addcliente(e)} />
                            </div>
                            <div className='iconoUbi' style={isSelectedcliente === false ? { display: 'none' } : { background: 'transparent' }}>
                                <img src={imgDireccion} alt="" onClick={(e) => handlerselecteddireccion(e)} />
                            </div>
                            <div className='iconoEliminar' style={isSelectedcliente === false ? { display: 'none' } : { background: 'transparent' }}>
                                <img src={imgEliminar} alt="" onClick={(e) => handlerdeselectedcliente(e)} />
                            </div>
                        </div>
                        <div className='containerDatosAdd'>
                            <div className="container1">
                                <div>
                                    <p>Código postal:</p>
                                    <input onChange={(e) => handlerCodigop(e.target.value)} value={isSelectedcliente === true ? selectedcliente.cp : codigop} ></input>
                                </div>
                                <div className='campoG'>
                                    <p>Calle:</p>
                                    <input onChange={(e) => handlerCalle(e.target.value)} value={isSelectedcliente === true ? selectedcliente.calle : calle}></input>
                                </div>
                            </div>
                            <div className="container2">
                                <div>
                                    <p>Número:</p>
                                    <input onChange={(e) => handlerNumero(e.target.value)} value={isSelectedcliente === true ? selectedcliente.noext : numero}></input>
                                </div>
                                <div className='campoG'>
                                    <p>Colonia:</p>
                                    <input onChange={(e) => handlerColonia(e.target.value)} value={isSelectedcliente === true ? selectedcliente.colonia : colonia}></input>
                                </div>
                            </div>
                            <div className="container3">
                                <div className='campoCiudad'>
                                    <p>Ciudad:</p>
                                    <input style={isSelectedclientedireccion === true ? { display: 'none' } : { background: 'transparent' }} onChange={(e) => handlerCiudad(e.target.value)} value={isSelectedcliente === true ? selectedcliente.ciudad : ciudad}></input>
                                    <input style={isSelectedclientedireccion === true ? { background: 'transparent' } : { display: 'none' }} onChange={(e) => handlerCiudad(e.target.value)} value={isSelectedcliente === true ? selectedcliente.localidad : ciudad}></input>
                                </div>
                                <div className='campoEstado'>
                                    <p>Estado:</p>
                                    <input onChange={(e) => handlerEstado(e.target.value)} value={isSelectedcliente === true ? selectedcliente.estado : estado}></input>
                                </div>
                                <div className='campoPais'>
                                    <p>País:</p>
                                    <input onChange={(e) => handlerPais(e.target.value)} value={isSelectedcliente === true ? selectedcliente.pais : pais}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="containerDer">
                    <div className="containerResumen">
                        <div className="resumen">
                            <h3>Mercancia</h3>
                        </div>
                        <div className="containerInfoResumen">
                            <div className='containerLista'>
                                <div>
                                    <p>Cantidad</p>
                                </div>
                                <div>
                                    <p>Tipo</p>
                                </div>
                                <div>
                                    <p>Contenido</p>
                                </div>
                                <div>
                                    <p>Dimensiones</p>
                                </div>
                            </div>
                            {
                                detalles.map((rest, index) => {

                                    if (rest[0].contenido != null) {
                                        return (
                                            <div className={`containerInfoLista ${isIdedit === index ? "active" : ""}`} onClick={(e) => handlerEditmerc(e, index, rest[0])}>
                                                <div>
                                                    <p>{rest[0].cantidad}</p>
                                                </div>
                                                <div>
                                                    <p>{rest[0].tipo === "0" ? "Sobre" : rest[0].tipo === 1 ? "Paquete" : "Tarima"}</p>
                                                </div>
                                                <div>
                                                    <p>{rest[0].contenido}</p>
                                                </div>
                                                <div>
                                                    <p>{`${rest[0].alto}x${rest[0].ancho}x${rest[0].alto}x${rest[0].peso}Kg`}</p>
                                                </div>
                                            </div>
                                        )
                                    } else {

                                    }
                                })
                            }
                        </div>
                        <div className="iconosAcciones">
                            <img src={imgAgregar} alt="" onClick={(e) => handlernewmerc(e)} />
                            <img src={imgEditar} alt="" onClick={(e) => handleOpenEditModal(e)} />
                            <img src={imgEliminar} alt="" onClick={(e) => handleremovemerc(e)} />
                        </div>
                    </div>
                    <div className="containerRecolectores">
                        <h3>Recolectores disponibles</h3>
                        {
                            datousuariosuc?.map((respusuarios, index) => {
                                
                                if (respusuarios.id != 0) {
                                    return (
                                        <div className="recolectores" onClick={(e) => handleraddrecolector(e, index, respusuarios)}>
                                            <div className='imgrecolector'>
                                                <img src={imgRecolector} alt="" />
                                            </div>
                                            <div className='nombreRecolector'>
                                                <p>{respusuarios.descripcion}</p>
                                            </div>
                                            <div className="asignar">
                                                <img src={recolectoresselecionados.includes(respusuarios.id) ? imgAsignar : imgSinAsignar} alt="" />
                                            </div>
                                        </div>
                                    )
                                }

                            })
                        }
                        <div className='btnAceptarPeticion'>
                            <a onClick={(e) => hanlderSubmint(e)}>Enviar</a>
                        </div>
                        <img src={imgCarritoCarga} alt="" />
                    </div>
                </div>
            </div>

            <Modal isOpen={modalDetallePet} style={customStylesDetalles}>
                <LayoutModalDetalles>
                           <div className="modalDetallesPeticion">
                        <div className="cabezera">
                            <div className="cerrar" onClick={() => handlecerrarmodal()}>x</div>
                            <div className='detalle'>
                                <h3>
                                    Detalles
                                </h3>
                            </div>
                        </div>
                        {
                            detalles?.map((rest, index) => {

                                if (isnewmercancia === index) {
                                    return (
                                        <>
                                            {/* <div className='containerTipo'>
                                                <p>Tipo {rest[0].tipo}</p>
                                            </div> */}
                                            <div className="containerimg">
                                                <div className={`caja ${rest[0].tipo === 1 ? "active" : ""}`} onClick={(e) => handlerchangueTipo(e, index, 1)}>
                                                    <img src={imgCaja} alt="" />
                                                </div>
                                                <div className={`sobre ${rest[0].tipo === 0 ? "active" : ""}`} onClick={(e) => handlerchangueTipo(e, index, 0)} >
                                                    <img src={imgSobre} alt="" />

                                                </div>
                                                <div className={`tarima ${rest[0].tipo === 2 ? "active" : ""}`} onClick={(e) => handlerchangueTipo(e, index, 2)}>
                                                    <img src={imgTarima} alt="" />
                                                </div>
                                            </div>
                                            <div className='containerCantidad'>
                                                <div>
                                                    <p>Cantidad</p>
                                                    <input type="text" onChange={(e) => setcantidad(e.target.value)} placeholder={origentipo === "Edit" ? rest[0].cantidad : ""} />
                                                </div>
                                                <div className='inputCont'>
                                                    <p>Contenido</p>
                                                    <input type="text" onChange={(e) => setcontenido(e.target.value)} placeholder={origentipo === "Edit" ? rest[0].contenido : ""} />
                                                </div>
                                            </div>
                                            <div className='containerMedidas'>
                                                <div>
                                                    <p>Largo</p>
                                                    <input type="text" placeholder={origentipo === "Edit" ? rest[0].alto : "cm"} onChange={(e) => setlargo(e.target.value)} value={rest[0].tipo === 0 ? 0 : undefined} />
                                                </div>
                                                <div>
                                                    <p>Ancho</p>
                                                    <input type="text" placeholder={origentipo === "Edit" ? rest[0].ancho : "cm"} onChange={(e) => setancho(e.target.value)} value={rest[0].tipo === 0 ? 0 : undefined} />
                                                </div>
                                                <div>
                                                    <p>Alto</p>
                                                    <input type="text" placeholder={origentipo === "Edit" ? rest[0].alto : "cm"} onChange={(e) => setalto(e.target.value)} value={rest[0].tipo === 0 ? 0 : undefined} />
                                                </div>
                                                <div>
                                                    <p>Peso</p>
                                                    <input type="text" placeholder={origentipo === "Edit" ? rest[0].peso : "kg"} onChange={(e) => setpeso(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="btnAceptarDetalles" onClick={(e) => handleraddmerc(e, index)}>
                                                <button>Aceptar</button>
                                            </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </LayoutModalDetalles>

            </Modal>


            <Modal isOpen={modalcliente} style={customStylesClientes}>
                <LayoutSeleccionarCliente>
                {
                        !loadingCliente ?
                        <>
                                <div className="modalDetallesPeticion">
                                    <div className="cabezera">
                                        <div className="cerrar" onClick={() => handlecerrarmodalcliente()}>x</div>
                                        <div className='clientes'>
                                            <h3>
                                                Clientes
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="listaClientes">
                                        <div className="headerClientes">
                                            <div className='nombre'>
                                                <p>Nombre</p>
                                            </div>
                                            <div className='rfc'>
                                                <p>RFC</p>
                                            </div>
                                            <div className='direccion'>
                                                <p>Dirección</p>
                                            </div>
                                        </div>
                                        <div className="bodyClientes">
                                            {
                                                clientes?.map(restcliente => {
                                                
                                                    return (
                                                        <>
                                                            <div className='containerlistclientes'>
                                                                <div className='nombre' onClick={(e) => handleselectcliente(e, restcliente)}>
                                                                    <p>{restcliente.nombrecompleto}</p>
                                                                </div>
                                                                <div className='rfc' onClick={(e) => handleselectcliente(e, restcliente)}>
                                                                    <p>{restcliente.rfc}</p>
                                                                </div>
                                                                <div className='direccion' onClick={(e) => handleselectcliente(e, restcliente)}>
                                                                    <p>{restcliente.calle} {restcliente.noext}, {restcliente.colonia}, {restcliente.cp}, {restcliente.ciudad}, {restcliente.estado}, {restcliente.pais}</p>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                        </>
                        :
                        <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '90vh'
                                }}>
                                    <Loader></Loader>
                                </div>                        </>
                    }
                 
                </LayoutSeleccionarCliente>
            </Modal>

            <Modal isOpen={modalDirecciones} style={customStylesDirecciones}>
                <LayoutModalDetalles>
                    {
                        !loadingCliente ?
                            <>
                                <div className="modalDetallesDireccion">
                                    <div className="cabezera">
                                        <div className="cerrar" onClick={() => handlecerrarmodaldirecciones()}>x</div>
                                        <div className='detalle'>
                                            <h3>
                                                Direcciones
                                            </h3>
                                        </div>
                                    </div>


                                    <div className="headerDirecciones">
                                        <div className='calle'>
                                            <p>Calle</p>
                                        </div>
                                        <div className='Num'>
                                            <p>Número</p>
                                        </div>
                                        <div className='colonia'>
                                            <p>Colonia</p>
                                        </div>
                                        <div className="ciudad">
                                            <p>Ciudad</p>
                                        </div>
                                        <div className='estado'>
                                            <p>Estado</p>
                                        </div>
                                    </div>
                                    {
                                        Direcciones?.map(restdirecciones => {
                                      
                                            return (
                                                <>
                                                    <div className=' containerlistdirec' onClick={(e) => handleselectclientedireccion(e, restdirecciones)}>
                                                        <div className="bodyDirecciones">
                                                            <div className='calle'>
                                                                <p>{restdirecciones.calle}</p>
                                                            </div>
                                                            <div className='Num'>
                                                                <p>{restdirecciones.noext}</p>
                                                            </div>
                                                            <div className='colonia'>
                                                                <p>{restdirecciones.colonia}</p>
                                                            </div>
                                                            <div className="ciudad">
                                                                <p>{restdirecciones.localidad}</p>
                                                            </div>
                                                            <div className='estado'>
                                                                <p>{restdirecciones.estado}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                        </>
                        :
                            <>
                            <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '90vh'
                                }}>
                            <Loader></Loader>
                            </div>
                            </>
                    }
                  
                    </LayoutModalDetalles>
            </Modal>
        </LayouPeticiones >

    )
}
export default Peticionescomponent