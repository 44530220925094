import moment from 'moment'
import React,{useState} from 'react'
import { usePeticiones } from '../apis/get/usePeticiones'
export const Usepeticion = () => {
    // A R E A  D E  V A R I A B L E S //
    const [origen, setorigen] = useState("")
    const [cliente, setcliente] = useState("")
    const [codigop, setcodigop] = useState("")
    const [calle, setcalle] = useState("")
    const [numero, setnumero] = useState("")
    const [colonia, setcolonia] = useState("")
    const [ciudad, setciudad] = useState("")
    const [estado, setestado] = useState("")
    const [pais, setpais] = useState("")
    const [detalles, setdetalles] = useState([]);
    const [clientebuscador, setclientebuscador] = useState("")
    const [fechalistado, setfechalistado] = useState(new Date)
    const [fechalistado2, setfechalistado2] = useState()
    const [estatuslistado, setestatuslistado] = useState("");
    const {loadpeticiones,loadpeticionesPaginator}=usePeticiones();
    

  // Nota 
    // 0 sobre
    // 1 pauqete
    // 2 Tarima

    // A R E A   D E  F U N C I O N E S //

    const handlerCleandata = () =>{
        setorigen("")
        setcliente("")
        setcodigop("")
        setcalle("")
        setnumero("")
        setcolonia("")
        setciudad("")
        setestado("")
        setpais("")
        setdetalles("")
        setclientebuscador("")
        setfechalistado("")
        setfechalistado2("")
        setestatuslistado("")
        loadpeticionesPaginator("")
    }

    const handlerChangueCliente = (data) => {
        console.log(data,"GGG")
        setclientebuscador(data)
    }
    const handlerchanguedate = (data) =>{
        setfechalistado(moment(data).format('YYYY-MM-DD'))
        setfechalistado2(moment(data).format('YYYY-MM-DD'))
        let fecha = moment(data).format('YYYY-MM-DD')
        loadpeticionesPaginator(clientebuscador,fecha,estatuslistado)
    }
    const handlerchangueStatus = (data) => {
        console.log(data,"GGG")
        setestatuslistado(data)
    }


    const handleradddetalle = (data) => {
        setdetalles([...detalles, data])
    }
    const handlerChanguedetalle = (data) =>{
        console.log(data,"datos enviados de peticiones");
        setdetalles(data)
    }

    const handlerOrigen = (data) =>{
        setorigen(data)
    }

    const handlerCliente = (data) =>{
        setcliente(data)
    }

    const handlerCodigop = (data) =>{
        setcodigop(data)
    }

    const handlerCalle = (data) =>{
        setcalle(data)
    }

    const handlerNumero = (data) =>{
        setnumero(data)
    }

    const handlerColonia = (data) =>{
        setcolonia(data)
    }

    const handlerCiudad = (data) =>{
        setciudad(data)
    }

    const handlerEstado = (data) =>{
        setestado(data)
    }
    
    const handlerPais = (data) =>{
        setpais(data)
    }
    const hanlderSubmint = () =>{
    }
  return{
    origen,
    handlerOrigen,
    cliente,
    handlerCliente,
    codigop,
    handlerCodigop,
    calle,
    handlerCalle,
    numero,
    handlerNumero,
    colonia,
    handlerColonia,
    ciudad,
    handlerCiudad,
    estado,
    handlerEstado,
    pais,
    handlerPais,
    detalles,
    handleradddetalle,
    handlerChanguedetalle,
    hanlderSubmint,
    handlerCleandata,

    // VARIABLES DE LISTADO
    clientebuscador,
    handlerChangueCliente,
    fechalistado,
    handlerchanguedate,
    fechalistado2,
    handlerchangueStatus,
    estatuslistado
  }
}
