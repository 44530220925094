import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../../config';

const useSucursal = () => {
    const [Sucursales, setSucursales] = useState();
    const [loadingSuc, setloadingSuc] = useState(false);
console.log(config.API_URL,"APIURL")
    useEffect(() => {
        setloadingSuc(true)
        var getSucursales = {
            method: 'get',
            url: `${config.API_URL}Sucursal/GetBranchesActive?pagina=1&registros_por_pagina=10&activas=true`,
            headers: { }
          };
          
          axios(getSucursales)
          .then(function (response) {
            setloadingSuc(false)
            let datos= response.data.resultado
            setSucursales(datos.filter(datos => !datos.nombre.includes('Virtual')) )
          })
          .catch(function (error) {
            setloadingSuc(false)
            console.log(error);
          });
          
    }, [setSucursales])
    
    const loadSucursal = () => {
        setloadingSuc(true)
        var getSucursales = {
            method: 'get',
            url: `${config.API_DEV}Sucursal/GetBranchesActive?pagina=1&registros_por_pagina=10&activas=true`,
            headers: { }
          };
          
          axios(getSucursales)
          .then(function (response) {
            setloadingSuc(false)
            let datos= response.data.resultado
            setSucursales(datos.filter(datos => !datos.nombre.includes('Virtual')) )
          })
          .catch(function (error) {
            setloadingSuc(false)
            console.log(error);
          });
    }
    
    
  return {
    Sucursales,
    loadSucursal,
    loadingSuc
  }
}

export default useSucursal