import React from 'react'
import { Footerlayout } from '../../themes/layout'


const Footer = () => {
  return (
    <Footerlayout>
        <div className='copymonitor'>
            <p>Desarrollado por PKT1 DEVELOPER</p>
        </div>
    </Footerlayout>
  )
}

export default Footer