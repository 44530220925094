import React, {useState,useEffect} from 'react';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
export const usePeticiones = () => {
const [Peticiones, setPeticiones] = useState();
const [Peticionesporusuario, setPeticionesporusuario] = useState();
const [PeticionessinAsignar, setPeticionessinAsignar] = useState()
const [Alertas, setAlertas] = useState();
const [loadingpeticiones, setloadingpeticiones] = useState(false)
const [totalregistros, settotalregistros] = useState()
const [perpages, setperpages] = useState()
const [totalpaginas, settotalpaginas] = useState()
const [Pagina, setPagina] = useState();
const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))

let arlertasarray = new Array;


// FUNCIONES
  const handlerchangueFecha = (data) => {
    setFechAactual(moment().format('YYYY-MM-DD'))
  }



console.log(FechAactual,"ACTUAL")
useEffect(() => {
  setloadingpeticiones(true)
    var getPeticiones = {
        method: 'get',
        url: `${config.API_URL}Geolocalizacion/paginator/petition/${FechAactual}?&page=1&registerbypage=1000`,
      
      };
      axios(getPeticiones)
      .then(function (response) {
        if(response.status === 204){
          setloadingpeticiones(false)
          setPeticiones([])
          setPeticionessinAsignar([])
        }else{
          setloadingpeticiones(false)
          response.data.resultado.map(r =>{
            console.log(r.estatus,"RESULTADO")
            if(r.estatus != "T"){
              arlertasarray.push(r.id)
             

              let keyunicaalert = [];
              arlertasarray.forEach((elemento) => {
                  if (!keyunicaalert.includes(elemento)) {
                    keyunicaalert.push(elemento);
                  }
              });
              setAlertas(keyunicaalert)
            }
          })
          setPeticiones(response.data.resultado)
          setPeticionessinAsignar(
            response.data.resultado.filter(datos => JSON.stringify(datos.usuarioasignado).includes(0))
          )
        
        }
        console.log(response,"RESPUESTA GEOLOCALIZACION")
       
      })
      .catch(function (error) {
        console.log(error);
      });
}, [setPeticiones])

const loadpeticionesbyrecolector = (data) =>{
  console.log("FF")
  setloadingpeticiones(true)
  console.log(data,"MAMAMAM")
  var getPeticionesbyid = {
    method: 'get',
    url: `${config.API_URL}Geolocalizacion/petitionbyuser/${data}`,
  
  };
  
  axios(getPeticionesbyid)
  .then(function (response) {
    setloadingpeticiones(false)
    console.log()
    setPeticionesporusuario(response.data)
  })
  .catch(function (error) {
    setloadingpeticiones(false)
    console.log(error,"Error de peticion por usuario");
  });
}

const loadpeticiones = () => {
  setloadingpeticiones(true)
  var getPeticiones = {
    method: 'get',
    url: `${config.API_URL}Geolocalizacion/paginator/petition?&page=1&registerbypage=1000`,
  
  };
  axios(getPeticiones)
  .then(function (response) {
    
    if(response.status === 204){
      setloadingpeticiones(false)
      setPeticiones([])
      setPeticionessinAsignar([])
    }else{
      setloadingpeticiones(false)
      response.data.resultado.map(r =>{
        console.log(r.estatus,"RESULTADO")
        if(r.estatus != "T"){
          arlertasarray.push(r.id)
         

          let keyunicaalert = [];
          arlertasarray.forEach((elemento) => {
              if (!keyunicaalert.includes(elemento)) {
                keyunicaalert.push(elemento);
              }
          });
          setAlertas(keyunicaalert)

        }
      })
      setPeticiones(response.data.resultado)
      setPeticionessinAsignar(
        response.data.filter(datos => JSON.stringify(datos.usuarioasignado).includes(0))
      )
     
    }
    console.log(response,"RESPUESTA GEOLOCALIZACION")
   
  })
  .catch(function (error) {
    console.log(error);
  });
}

const loadpeticionesPaginator = (Cliente,Fecha,Estatus) => {
  console.log(Cliente,Fecha,Estatus,"MMMM")
  var getPeticiones = {
    method: 'get',
    url: `${config.API_URL}Geolocalizacion/paginator/petition?search=${!Cliente || !Fecha || !Estatus ?  Fecha : ''}&page=1&registerbypage=10 `,
  
  };
  axios(getPeticiones)
  .then(function (response) {
    if(response.status === 204){
      setPeticiones([])
      setPeticionessinAsignar([])
    }else{
      setPagina(response.data.paginaActual)
      settotalpaginas(response.data.totalPaginas)
      response.data.resultado.map(r =>{
        console.log(r.estatus,"RESULTADO")
        if(r.estatus != "T"){
          arlertasarray.push(r.id)
         

          let keyunicaalert = [];
          arlertasarray.forEach((elemento) => {
              if (!keyunicaalert.includes(elemento)) {
                keyunicaalert.push(elemento);
              }
          });
          setAlertas(keyunicaalert)

        }
      })
      setPeticiones(response.data.resultado)
      setPeticionessinAsignar(
        response.data.filter(datos => JSON.stringify(datos.usuarioasignado).includes(0))
      )
     
    }
    console.log(response,"RESPUESTA GEOLOCALIZACION")
   
  })
  .catch(function (error) {
    console.log(error);
  });
}
  return {
    Peticiones,
    loadpeticionesbyrecolector,
    Peticionesporusuario,
    PeticionessinAsignar,
    loadpeticiones,
    Alertas,
    Pagina,
    totalpaginas,
    loadpeticionesPaginator,
    loadingpeticiones
  }
}