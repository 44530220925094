
import { createContext, useContext, useEffect, useState } from "react";
import axios from 'axios'
import config from "../config";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export function AuthProvider({ children }) {
  const url = `${config.API_URL}Login/login?Origin=Movil`
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [ciudadSelecionada, setsucursalselecionada] = useState(JSON.parse(localStorage.getItem('sucursal')));
  const [messageerror, setmessageerror] = useState();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(true);
  const [isActiveSubmenu, setActiveSubmenu] = useState(true);
  const [isActiveMenu, setisActiveMenu] = useState(JSON.parse(localStorage.getItem("lastmenu")));
  const [Token, setToken] = useState()
  const [RefreshToken, setRefreshToken] = useState()
  // const [coordenadamapa, setcoordenadamapa] = useState({
  //   lng: -108.98972335988667,
  //   lat: 25.79109091258725,
  //   zoom: 13.5
  // })
  const [coordenadamapa, setcoordenadamapa] = useState(JSON.parse(localStorage.getItem("coordenadamapa")))

  const signup = (email, password) => {

  };
  const login =(data, navigate) => {
    setLoading(true);
   

    
    var headerLogin = {
      method: 'post',
      url: `${config.API_URL}Login/login?Origin=Interno`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(headerLogin)
    .then(function (responseLogin) {
      setLoading(false)
   
      var headerUsers = {
        method: 'get',
        url: `${config.API_URL}Usuario/getuser/${responseLogin.data.idusuario}`,
        headers: { }
      };
      
      axios(headerUsers)
      .then(function (responseUser) {
        setLoading(false)
       

        var headersuc = {
          method: 'get',
          url: `${config.API_URL}Sucursal/getBranch/${responseUser.data.idfranquicia}`,
          headers: { }
        };
        
        axios(headersuc)
        .then(function (responsesuc) {

   

          var gETCITYbyuser = {
            method: 'get',
          maxBodyLength: Infinity,
            url: `${config.API_URL}Ciudad/Get/${responsesuc.data.idciudad}`,
            headers: { }
          };
          
          axios(gETCITYbyuser)
          .then(function (responsecity) {
           
            setUser(
              {
                "id": responseLogin.data.idusuario,
                "descripcion": responseUser.data.descripcion,
                "idsucursal": responseUser.data.idfranquicia,
                "sucursal": responsesuc.data.nombre,
                "idciudad": responsesuc.data.idciudad,
                "colonia": responsesuc.data.colonia,
                "cp":  responsesuc.data.cp,
                "nombre": responseUser.datanombreusuario,
                "token": responseLogin.data.token
              }
            )
            const datauser ={
              "id": responseLogin.data.idusuario,
              "descripcion": responseUser.data.descripcion,
              "idsucursal": responseUser.data.idfranquicia,
              "sucursal": responsesuc.data.nombre,
              "idciudad": responsesuc.data.idciudad,
              "colonia": responsesuc.data.colonia,
              "cp":  responsesuc.data.cp,
              "nombre": responseUser.datanombreusuario,
              "token": responseLogin.data.token
            }
            setToken(responseLogin.data.token);
            setRefreshToken(responseLogin.data.refreshToken);
            setsucursalselecionada({ idSucursal: responsecity.data.id, nombre: responsecity.data.nombre })
            localStorage.setItem("sucursal", JSON.stringify({ idSucursal: data.id, nombre: data.nombre }))
          localStorage.setItem('auth', true);
          localStorage.setItem("user", JSON.stringify(datauser))
         
          if (responsecity.data.iata === "LMM") {
            data = {
              lng: -108.98972335988667,
              lat: 25.79109091258725,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } else if (responsecity.data.iata === "GDL") {
            data = {
              lng: -103.34331011836127,
              lat:  20.65852985156891,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } else if (responsecity.data.iata === "MTY") {
            data = {
              lng: -100.31670954103768,
              lat: 25.68679462562785,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } else if (responsecity.data.iata === "CUL") {
            data = {
              lng: -107.3946021011502,
              lat: 24.8090748412337,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } else if (responsecity.data.iata === "HMO") {
            data = {
              lng: -110.97834227713562,
              lat: 29.08802425126585,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } else if (responsecity.data.iata === "CUU") {
            data = {
              lng: -106.06828656786466,
              lat: 28.638682252359157,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          }else if (responsecity.data.iata === "QRO") {
            data = {
              lng: -100.40444662018706,
              lat: 20.60751362504742,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } else if (responsecity.data.iata === "TIJ"){
            data = {
              lng: -116.95192882916129,
              lat: 32.502241540900314,
              zoom: 10.5
            }
            localStorage.setItem("coordenadamapa", JSON.stringify(data))
          } 
          navigate("/")
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error.response);
          });


        
          // localStorage.setItem("sucursal", JSON.stringify({idSucursal: responseUser.data.idfranquicia, nombre: responsesuc.data.nombre, idCiudad: responsesuc.data.idciudad}))
          // const datasu = {
          //   lng: -108.98972335988667,
          //   lat: 25.79109091258725,
          //   zoom: 10.5
          // }
          // localStorage.setItem("coordenadamapa", JSON.stringify(datasu))
         
            // setSucursales(datos.filter(datos => !datos.nombre.includes('Virtual')) )
        
        
         
        })
        .catch(function (error) {
          console.log(`${config.API_URL}Sucursal/getBranch/${responseUser.data.idfranquicia}`,"URL DE SUC")
          console.log(error,"error suc");
        });

      })
      .catch(function (error) {
        setLoading(false)
       console.log(`${config.API_URL}Usuario/getuser/${responseLogin.data.idusuario}`,"URL DE USUARIOS")
       console.log(error,"Error de Usuarios");
      });

    })
    .catch(function (error) {

      setLoading(false)
      console.log(data, "REQUEST LOGIN")
      console.log(`${config.API_URL}Login/login?Origin=Interno`,"URL LOGIN")
      console.log(error.response.data, "error de login");
      // alert(JSON.stringify(error.response.data))
      Swal.fire({
        icon: 'error',
        title: "El usuario no existe",
        confirmButtonColor: "#3BAF29"
    }).then((result) => {
      setLoading(false)
      window.location.reload()
        window.scrollBy(0.900)
    })
    });
    
    // setmessageerror(err.response.data.message)
    setLoading(false)
  };

  const logout = () => {
    const data = "inicio"
    localStorage.removeItem("auth")
    localStorage.removeItem("user")
    localStorage.removeItem("sucursal")
    localStorage.removeItem("lastsubmenu")
    localStorage.setItem("lastmenu", JSON.stringify(data))
  };
  const handlercambioSucursal = (data) => {
    setsucursalselecionada({ idSucursal: data.id, nombre: data.nombre })
    localStorage.setItem("sucursal", JSON.stringify({ idSucursal: data.id, nombre: data.nombre }))
    if (data.iata === "LMM") {
      data = {
        lng: -108.98972335988667,
        lat: 25.79109091258725,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } else if (data.iata === "GDL") {
      data = {
        lng: -103.34331011836127,
        lat:  20.65852985156891,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } else if (data.iata === "MTY") {
      data = {
        lng: -100.31670954103768,
        lat: 25.68679462562785,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } else if (data.iata === "CUL") {
      data = {
        lng: -107.3946021011502,
        lat: 24.8090748412337,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } else if (data.iata === "HMO") {
      data = {
        lng: -110.97834227713562,
        lat: 29.08802425126585,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } else if (data.iata === "CUU") {
      data = {
        lng: -106.06828656786466,
        lat: 28.638682252359157,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    }else if (data.iata === "QRO") {
      data = {
        lng: -100.40444662018706,
        lat: 20.60751362504742,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } else if (data.iata === "TIJ"){
      data = {
        lng: -116.95192882916129,
        lat: 32.502241540900314,
        zoom: 10.5
      }
      localStorage.setItem("coordenadamapa", JSON.stringify(data))
    } 
    // else if (data.id === 80) {
    //   data = {
    //     lng: -108.98972335988667,
    //     lat: 25.79109091258725,
    //     zoom: 10.5
    //   } 
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.id ===97) {
    //     data = {
    //       lng: -100.31670954103768,
    //       lat: 25.68679462562785,
    //       zoom: 10.5
    //     }
    //     localStorage.setItem("coordenadamapa", JSON.stringify(data))
    //     setcoordenadamapa(data)
    // }
  }
  const handleractiveMenu = (data) => {
    setisActiveMenu(data)
    localStorage.setItem("lastmenu", JSON.stringify(data))
  }
  const handleractiveSubMenu = (data) => {
    setActiveSubmenu(data)

  }
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('sucursal', JSON.stringify(ciudadSelecionada));
    localStorage.setItem("lastmenu", JSON.stringify(isActiveMenu));
    localStorage.setItem ("coordenadamapa", JSON.stringify(coordenadamapa))
  }, [user])


  const handlerRefreshToken = (t ,r ) =>{
    setToken(t)
    setRefreshToken(r)
}

  return (
    <authContext.Provider
      value={{
        signup,
        login,
        user,
        logout,
        loading,
        messageerror,
        ciudadSelecionada,
        setsucursalselecionada,
        handlercambioSucursal,
        isActiveSubmenu,
        isActiveMenu,
        handleractiveMenu,
        handleractiveSubMenu,
        coordenadamapa,
        setLoading,
        Token,
        RefreshToken,
        handlerRefreshToken
      }}
    >
      {children}
    </authContext.Provider>
  );
}