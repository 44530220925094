import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Ventas', value: 400 },
  { name: 'Gastos', value: 300 },
  { name: 'Ganancias', value: 100 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const GraficoGanancias = () => (
  <PieChart width={450} height={330}>
    <Pie data={data} cx={200} cy={200} innerRadius={60} outerRadius={80} fill="#8884d8" >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
    <Tooltip />
    <Legend />
  </PieChart>
);

export default GraficoGanancias;