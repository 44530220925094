import React,{useState,useRef } from 'react'
import { MapcomponentNewfance } from '../components/mapas/Addgeofance'
import Tablarecolectores from '../components/tablas/recolectores'
import useGetusuariosuc from '../hooks/apis/get/useUsuarioSuc'
import { Useaddrecolector } from '../hooks/apis/useAddrecolector'
import useGetZona from '../hooks/apis/get/useZona'
import Addfance from '../components/addgeofance'
import { AddfanceLayout } from '../themes/layout'
import UsePostAddfance from '../hooks/apis/post/usePostAddfance'
import { Test } from '../hooks/test'
import Loader from '../components/loader/loader'


function CrearZona() {
  const {divprincipalcrearzona,divtablarecolectores}=Test()
  const{datousuariosuc}=useGetusuariosuc()
  const{cordsaddfance,handleraddcords}=UsePostAddfance()
  const{recolectores,handlerecolectorgeo}=Useaddrecolector()
  const {loadZonas,loadingzona,datoZona,geoJSonZona} = useGetZona()
  const [headerTable, setheaderTable] = useState({
    nombre: "Recolector",
    sucursal: "Sucursal",
    acciones: "Acciones"
  })

const handlemovemouse = (e) => {
  
}

const moverscroll = (data) =>{
    window.scrollTo(0,900)
    divtablarecolectores.current.scrollTo( 0, 900 );
   divprincipalcrearzona.current.scrollTo( 0, 900 );
   divtablarecolectores.current.scrollIntoView()
}
  return (
    <AddfanceLayout ref={divprincipalcrearzona}  onMouseMove={handlemovemouse}>
         {
      loadingzona ? 
      <>
       <div className='containerLoader' style={{display:'flex', flex: 1, justifyContent:'center', alignItems:'center',alignContent:'center',width:'100%', maxHeight:1200,marginTop:'25%'}}>
             <Loader title={"Crear Zona"}></Loader>
             </div>
      </>
      : 
      <>
      <div>
        <div style={{display:'flex', flexDirection:'row'}}>
          <MapcomponentNewfance
            datosdezona={datoZona}
            geoJsonZona={geoJSonZona}
            accionguardarcordenada={handleraddcords}
          ></MapcomponentNewfance>
          <Addfance
          data={recolectores}
          cordenadas={cordsaddfance}
          datosdezona={datoZona}
          navegar={moverscroll}
          ></Addfance>
        </div>

        <div>
          <Tablarecolectores
          header={headerTable}
          data= {datousuariosuc}
          accionguardar={handlerecolectorgeo}
          cordenadas={cordsaddfance}
          ref={divtablarecolectores}
          ></Tablarecolectores>
        </div>
      </div>
      </>
      }
    </AddfanceLayout>
  )
}

export default CrearZona