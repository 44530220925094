import { useEffect, useState } from 'react';
import './App.css';
import { AuthProvider } from './context/Authcontext';
import { SocketProvider } from './context/SocketContext';
import { Layoutcontainer, Wrapper } from './themes/layout';
import Navigation from './router/Navigation';
import 'chart.js/auto';


function App() {

  const [nombre, setuser] = useState('mario');
//   useEffect(() => {
//     localStorage.setItem( 'user', JSON.stringify(user) );
// }, [user])

  return ( 
  <SocketProvider>
    <AuthProvider value={{nombre}}>
        <Layoutcontainer>
          <Navigation></Navigation>
        </Layoutcontainer>
    </AuthProvider> 
    </SocketProvider>
  );
}

export default App;
