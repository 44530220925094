import React from 'react'
import { useRef, useState, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from "@turf/turf";
export const useZonaMap = (puntoInicial,datosdezona,datodesuc) => {

   
    var geocercadata;
    const mapaDiv = useRef();
      // Mapa y coords
      const mapa = useRef();
      const [iszonaclick, setiszonaclick] = useState(false)
      const [ coords, setCoords ] = useState( puntoInicial );
      const setRef = useCallback( (node) => {
        mapaDiv.current = node;
    },[]);

      useEffect( () => {
          const map = new mapboxgl.Map({
              container: mapaDiv.current,
              style: 'mapbox://styles/mapbox/streets-v11',
              center: [ puntoInicial.lng, puntoInicial.lat ],
              zoom: puntoInicial.zoom
          });
          mapa.current = map;
      },[ puntoInicial ]);

          // Cuando se mueve el mapa
    useEffect(() => {
        mapa.current?.on('move', () => {
            const { lng, lat } = mapa.current.getCenter();
            setCoords({
                lng: lng.toFixed(4),
                lat: lat.toFixed(4),
                zoom: mapa.current.getZoom().toFixed(2)
            })
        });

    },[]);
        // Agregar Zonas a mapa de zonas 
        useEffect(() => {
            mapa.current?.on('load', () => {
                datosdezona?.map(responsecords =>{
                    console.log(responsecords,"MMMMMMMMMMMMMMMMMMMMMMMMMMSSSSSSSSSSSSSSSSSSSSS")
                    let Arraygeocerca = new Array(responsecords?.cords)
                    let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                    const cordenadas = ObjGeocerca.map(resto => {return resto});  
                    geocercadata = turf.polygon(cordenadas);
                    mapa.current.addSource(responsecords.observaciones, {
                        "type": "geojson",
                        "data": {
                            "type": "Feature",
                            "geometry": geocercadata.geometry
                        }
                    })
                    mapa.current.addLayer({
                        'id': responsecords.observaciones,
                        'type': 'fill',
                        'source': responsecords.observaciones, // reference the data source
                        'paint': {
                        'fill-color': responsecords.color, // blue color fill
                        'fill-opacity': 0.5
                        },
                        'filter': ['==', '$type', 'Polygon']
                        });
                        mapa.current.on('click',responsecords.observaciones, (e) => {
                            if(iszonaclick === false){
                                setiszonaclick(true)
                            }else {
                                setiszonaclick(false)
                            }
                            });
                })
                // Agregar Sucursales a mapa de zona
                datodesuc.map(responsesucursales =>{
                    const popup = new mapboxgl.Popup({ offset: 25 }).setText(
                        'Construction on the Washington Monument began in 1848.'
                        );
                    const el = document.createElement('div');
                    el.className = 'MarcadorSuc';
                    const marcadorSuc = new mapboxgl.Marker(el);
                    marcadorSuc
                    .setLngLat([responsesucursales.lng,responsesucursales.lat])
                    .setPopup(popup) // sets a popup on this marker
                    .addTo( mapa.current )
                })
            })
        }, [])
  return {
    setRef
  }
}

