import React,{useEffect,useContext,useState} from 'react';
import { MapMonitor } from '../components/mapas/MapMonitor';
import useGetZona from '../hooks/apis/get/useZona';
import { usePeticiones } from '../hooks/apis/get/usePeticiones';
import { SocketContext } from '../context/SocketContext';
import imgs from '../assets/img/locationpetition.svg'


const Mapa = (props) => {
    const {loadZonas,loadingzona,datoZona,geoJSonZona} = useGetZona();
    const {socket}=useContext(SocketContext)
    const {Peticiones,
      loadpeticionesbyrecolector,
      Peticionesporusuario,
      PeticionessinAsignar,
      loadingpeticiones
    }=usePeticiones();

    console.log(imgs,"Peticiones");
  return (
   <>
    {
            loadingzona === true || socket === undefined ?
            <>
            <h1>Cargando...</h1>
            </>
            :
            <>
            <MapMonitor
                datosdezona={datoZona}
                geoJsonZona={geoJSonZona}
                Peticiones={Peticiones}
                PeticionesUsuario={Peticionesporusuario}
                loadingpeticion={loadingpeticiones}
                Accioncargarpeticiones={loadpeticionesbyrecolector}
                Peticionessinasignar={PeticionessinAsignar}
            />  
            </>
        }
    </>
  )
}

//

export default Mapa