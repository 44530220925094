import React, {useState,useEffect} from 'react';
import axios from 'axios';

export const Useaddrecolector = () => {
    const [recolectores, setrecolectores] = useState([]);
    const handlerecolectorgeo = (id, user, indexs) =>{
      const data =[
        {
          id: id,
          nombre: user
        }
      ]
      if(JSON.stringify(recolectores).indexOf(id) >=0){
        const remove= recolectores.filter((emba,index) => index !== indexs)
        setrecolectores(remove)
      } else{
        setrecolectores([...recolectores,data])

      }
    }




  return {
    recolectores,
    handlerecolectorgeo
  }
}
