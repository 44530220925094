import React from 'react'
import {useLocation} from 'react-router-dom';
import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
const PdfTest = () => {
  const location = useLocation();


  return (
     <div style={{background: 'gray', minHeight: "100vh"}}>
        <PDFViewer style={{width: '100%', height: "90vh"}}>
             {/* <img src={response} alt="" srcset="" /> */}
             <Document style={{with: '100%'}} >
                <Page size="A4" style={{width:'100%' ,backgroundColor:'#D9D9D9'}}>
                    <View style={{display:'flex', flexDirection:'column'}}>
                       <View style={{display:'flex', flexDirection:'column', backgroundColor:'#151B4E', width:'120%', height:'50px', position:'relative',justifyContent:'center', alignItems:'center'}}>
                          <View style={{width:'240px', textAlign:'center',marginLeft: "-120px", paddingTop: "2px",paddingBottom: "2px"}}>
                            <Text style={{color:'#fff', fontWeight:'bold'}}>Reporte de venta por ciudad Los Mochis</Text>
                          </View>
                       </View>
                       <View style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                       <Image src={location.state.image} style={{width: "120px", paddingTop: "20px",marginLeft: "11%"}}/>
                          {
                            location.state.type === "dona" 
                            ? 
                            <>
                              <View style={{marginLeft:"14%", backgroundColor: '#F0F1F3', paddingTop: '20px', width: '50%', paddingLeft: "20px", borderRadius:"20px", marginTop:"20px", position:'relative'}}>
                               
                                  <View style={{paddingBottom:'12px', position: 'relative', width: '190px',display: 'flex', justifyContent: 'center', paddingLeft:"20px"}}>
                                    <View style={{position: 'absolute', width: "18px", height: "12px", backgroundColor: `#3BAF2A`, borderRadius: '20%', top:5, borderWidth:"1px", borderColor:"#151B4E"}}></View>
                                  <Text >Mes actual</Text>
                                  <Text style={{marginLeft: "16px"}}>{location.state.datadona.Actual} Mxn</Text>
                                  </View>
                                  <View style={{paddingBottom:'12px', position: 'relative', width: '190px',display: 'flex', justifyContent: 'center', paddingLeft:"20px"}}>
                                    <View style={{position: 'absolute', width: "18px", height: "12px", backgroundColor: `#F0F1F3`, borderRadius: '20%', top:5, borderWidth:"1px", borderColor:"#151B4E"}}></View>
                                  <Text>Mes anterior</Text>
                                  <Text style={{marginLeft: "16px"}}>{location.state.datadona.Anterior} Mxn</Text>
                                  </View>
                                  <View 
                                  style={{position: 'absolute', top: 15, right: 15, width: "90px",height:"90px", backgroundColor:'#D9D9D9', textAlign:'center', padding: 10, borderWidth: 5, borderColor:"#151B4E", borderRadius:"50%", display:'flex', justifyContent:'center'}}>
                                      <Text>Total:</Text>
                                      <Text>{location.state.datadona.Actual + location.state.datadona.Anterior}</Text>
                                  </View>
                              </View>
                            </> 
                            : 
                            <>
                            <View>
                            <View style={{marginLeft:"14%", backgroundColor: '#F0F1F3', paddingTop: '20px', width: '50%', paddingLeft: "20px", borderRadius:"20px", marginTop:"20px", position:'relative'}}>
                               
                               <View style={{paddingBottom:'12px', position: 'relative', width: '190px',display: 'flex', justifyContent: 'center', paddingLeft:"20px"}}>
                                 <View style={{position: 'absolute', width: "18px", height: "12px", backgroundColor: `#3BAF2A`, borderRadius: '20%', top:5, borderWidth:"1px", borderColor:"#151B4E"}}></View>
                               <Text >Mes actual</Text>
                               <Text style={{marginLeft: "16px"}}>{location.state.datadona.Actual} Mxn</Text>
                               </View>
                               <View style={{paddingBottom:'12px', position: 'relative', width: '190px',display: 'flex', justifyContent: 'center', paddingLeft:"20px"}}>
                                 <View style={{position: 'absolute', width: "18px", height: "12px", backgroundColor: `#F0F1F3`, borderRadius: '20%', top:5, borderWidth:"1px", borderColor:"#151B4E"}}></View>
                               <Text>Mes anterior</Text>
                               <Text style={{marginLeft: "16px"}}>{location.state.datadona.Anterior} Mxn</Text>
                               </View>
                               <View 
                               style={{position: 'absolute', top: 15, right: 15, width: "90px",height:"90px", backgroundColor:'#D9D9D9', textAlign:'center', padding: 10, borderWidth: 5, borderColor:"#151B4E", borderRadius:"50%", display:'flex', justifyContent:'center'}}>
                                   <Text>Total:</Text>
                                   <Text>{location.state.datadona.Actual + location.state.datadona.Anterior}</Text>
                               </View>
                           </View>
                              </View>
                            </>
                          }
                         
                       </View>
                          <View style={{width: "100%", textAlign:'center',marginTop: 15}}>
                              <Text>Desglose general</Text>
                              <View>
                                <View style={{display:'flex', flexDirection:'row', width:'90%', justifyContent:'space-around', backgroundColor:'#151B4E', paddingTop:10, textAlign:'center',marginLeft: "5%",paddingBottom:10,borderTopRightRadius:10, borderTopLeftRadius:10,alignItems:'center',marginTop:5}}>
                                    <View style={{fontSize:12, borderRightWidth: 1,  paddingRight:2, color:"#fff",width:'25%'}}>
                                        <Text>Nombre</Text>
                                    </View>
                                    <View style={{fontSize:12, borderRightWidth: 1, paddingRight:2, color:"#fff",width:'20%'}}>
                                        <Text>Total peticiones</Text>
                                    </View>
                                    <View style={{fontSize:12, borderRightWidth: 1, paddingRight:2, color:"#fff",width:'20%'}}>
                                        <Text>Total peticiones PKT1</Text>
                                    </View>
                                    <View style={{fontSize:12, borderRightWidth: 1,  paddingRight:2, color:"#fff",width:'20%'}}>
                                        <Text>Total peticiones Onsite</Text>
                                    </View>
                                </View>
                                {
                                  location.state.usuarios?.map(r => {
                                    console.log(r,"RES")
                                      if(r.id !== 0){
                                        return(
                                          <View style={{display:'flex', flexDirection:'row', width:'90%', justifyContent:'space-around',textAlign:'center',marginLeft: "5%",alignItems:'center'}}>
                                          <View style={{fontSize:10,   paddingRight:2, color:"#000", width:'30%',paddingTop:10}}>
                                              <Text>{r.descripcion}</Text>
                                          </View>
                                          <View style={{fontSize:12,   paddingRight:2, color:"#000", width:'15%',paddingTop:10}}>
                                              <Text>33</Text>
                                          </View>
                                          <View style={{fontSize:12,   paddingRight:2, color:"#000", width:'15%',paddingTop:10}}>
                                              <Text>10</Text>
                                          </View>
                                          <View style={{fontSize:12,   paddingRight:2, color:"#000", width:'15%',paddingTop:10}}>
                                              <Text>20</Text>
                                          </View>
                                      </View>
                                        )
                                      }
                                  })
                                }
                              </View>
                          </View>
                    </View>
                    <View>
                      
                    </View>
                </Page>
             </Document>
          </PDFViewer>
     </div>
  )
}

export default PdfTest