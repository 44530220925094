import React, { useContext, useState } from 'react';
import { authContext } from '../context/Authcontext';
import imgLogo from '../assets/img/LogoPKT1.svg';
import imgInicio from '../assets/img/inicio.svg';
import imgMapa from '../assets/img/mapa.svg';
import imgAnalitica from '../assets/img/analitica.svg';
import imgPeticiones from '../assets/img/peticion.svg';
import imgSoporte from '../assets/img/soporte.svg';
import inicioAct from '../assets/img/InicioAct.svg';
import mapaAct from '../assets/img/mapaAct.svg';
import analiticaAct from '../assets/img/analiticaAct.svg';
import peticionesAct from '../assets/img/peticionesAct.svg';
import soporteAct from '../assets/img/soporteAct.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/Authcontext';
import { LayoutSidebar } from '../themes/layout';
const SidebarComponent = () => {
    const{user}=useContext(authContext)
    const { isActiveSubmenu,
        isActiveMenu,
        handleractiveMenu,
        handleractiveSubMenu } = useAuth()
    const nave = useNavigate()
    const handlernavigate = (e, type) => {
        if (type === "inicio") {
            handleractiveMenu(type)
            nave("/home")
        } else if (type === "mapa") {
            handleractiveMenu(type)
            nave("/Mapa", { replace: true })
            handleractiveMenu("none")
        }
        else if (type === "analitica") {
            handleractiveMenu(type)
            nave("/analitica")
        }
        else if (type === "peticiones") {
            handleractiveMenu(type)
            nave("/listapeticiones")
        }
        else if (type === "soporte") {
            handleractiveMenu(type)
            nave("/soporte")
        } else {
            handleractiveMenu("none")
        }
    }

    const handlersubmenu = (e, type) => {
        if (type === "inicio") {
            handleractiveSubMenu(type)
        } else if (type === "mapa") {
            handleractiveSubMenu(type)
        }
        else if (type === "analitica") {
            handleractiveSubMenu(type)
        }
        else if (type === "peticiones") {
            handleractiveSubMenu(type)
        }
        else if (type === "soporte") {
            handleractiveSubMenu(type)
        } else {
            handleractiveSubMenu("none")
        }
    }

    const handlersubmenufuera = (e, type) => {
        if (type === "inicio") {
            handleractiveSubMenu(type)
        } else if (type === "mapa") {
            handleractiveSubMenu("none")
        }
        else if (type === "analitica") {
            handleractiveSubMenu(type)
        }
        else if (type === "peticiones") {
            handleractiveSubMenu(type)
        }
        else if (type === "soporte") {
            handleractiveSubMenu(type)
        } else {
            handleractiveSubMenu("none")
        }
    }
    const handlernavigatesubmenu = (data) => {
        nave(`/${data}`)
        handleractiveMenu("mapa", { replace: true })
    }

    console.log(isActiveSubmenu,"MARIO")

    return (
        <LayoutSidebar>
            <div className="containerSidebar">
                <div className="containerLogo">
                    <img src={imgLogo} alt="" />
                </div>
                <div className={isActiveMenu === "inicio" ? "ActiveMenu" : "containerVerde"} onClick={(e) => handlernavigate(e, "inicio")}>
                    {/* <img src={imgInicio} alt="" /> */}
                    <img src={isActiveMenu === 'inicio' ? inicioAct : imgInicio} alt='' />
                    {/* <span>Inicio</span> */}
                    <span className={isActiveMenu === 'inicio' ? 'acti' : 'inactiv'}>Inicio</span>
                </div>
                <div className={isActiveMenu === "mapa" ? `containerVerdeActive` : "containerVerde"} onMouseEnter={(e) => handlersubmenu(e, "mapa")} onMouseLeave={(e) => handlersubmenufuera(e, "mapa")}>
                    {/* <img src={imgMapa} alt="" /> */}
                    <img src={isActiveMenu === "mapa" ? mapaAct : imgMapa} alt='' />
                    <div className='mapadiv' >
                        {/* <span className='letraMapa'>Mapa</span> */}
                        <span className={isActiveMenu === 'mapa' ? 'activmapa' : 'letraMapa'}>Mapa</span>
                    </div>
                    <div style={isActiveSubmenu === "mapa" ? { display: "block" } : { display: "none" }} className="submenu" >
                        <ul style={{ padding: '0px' }}>
                            <li onClick={() => handlernavigatesubmenu("mapa")}>
                                Mapa
                            </li>
                            <li onClick={() => handlernavigatesubmenu("Zonas")}>
                                Zona
                            </li>
                            <li onClick={() => handlernavigatesubmenu("Nuevazona")}>
                                Crear zona
                                </li>
                        </ul>
                    </div>
                </div>
                {
                    user?.id === 119 ?
                    <>
                    
                <div className={isActiveMenu === "analitica" ? "ActiveMenu" : "containerVerde"} onClick={(e) => handlernavigate(e, "analitica")}>
                   
                    <img src={isActiveMenu === 'analitica' ? analiticaAct : imgAnalitica} alt='' />
                    
                    <span className={isActiveMenu === 'analitica' ? 'acti' : 'inactiv'}>Analítica</span>
                </div>
                    </>
                    :
                    <>
                    </>
                }
               
                <div className={isActiveMenu === "peticiones" ? "ActiveMenu" : "containerVerde"} onClick={(e) => handlernavigate(e, "peticiones")}>
                    {/* <img src={imgPeticiones} alt="" /> */}
                    <img src={isActiveMenu === 'peticiones' ? peticionesAct : imgPeticiones} alt='' />
                    {/* <span>Peticiones</span> */}
                    <span className={isActiveMenu === 'peticiones' ? 'acti' : 'inactiv'}>Peticiones</span>
                </div>
                <div className={isActiveMenu === "soporte" ? "ActiveMenu" : "containerVerde"} onClick={(e) => handlernavigate(e, "soporte")}>
                    {/* <img src={imgSoporte} alt="" /> */}
                    <img src={isActiveMenu === 'soporte' ? soporteAct : imgSoporte} alt='' />
                    {/* <span>Soporte</span> */}
                    <span className={isActiveMenu === 'soporte' ? 'acti' : 'inactiv'}>Soporte</span>
                </div>
            </div>
        </LayoutSidebar>
    )
}

export default SidebarComponent