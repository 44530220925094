import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../config';
import { useAuth } from '../../../context/Authcontext';

const useGetusuariosuc = () => {
  const{ciudadSelecionada,user}=useAuth()

  const [datousuariosuc, setdatousuariosuc] = useState();
  const [loadingusuariosuc, setloadingusuariosuc] = useState(false)

  
  useEffect(() => {
    setloadingusuariosuc(true)
    var configzonas = {
      method: 'get',
      url: `${config.API_URL}Usuario/GetUserCity/${ciudadSelecionada.idSucursal}`,
      headers: {}
    };

    axios(configzonas)
      .then(function (response) {
        setloadingusuariosuc(false)
        let data = new Array;
        data = response.data;
        data.push(
          {
            "id": 0,
            "idsucursal": 1,
            "descripcion": "Sin asignar",
            "zona": 16
          }
        )
        setdatousuariosuc(data)
      })
      .catch(function (error) {
        setloadingusuariosuc(false)
      });
  }, [setdatousuariosuc])

  const loadusuariossuc = () => {
    setloadingusuariosuc(true)
    var configzonas = {
      method: 'get',
      url: `${config.API_URL}Usuario/GetUserCity/${ciudadSelecionada.idSucursal}`,
      headers: {}
    };

    axios(configzonas)
      .then(function (response) {
        setloadingusuariosuc(false)
        setdatousuariosuc(response.data)
      })
      .catch(function (error) {
        setloadingusuariosuc(false)
      });
  }


  return {
    datousuariosuc,
    loadusuariossuc,
    loadingusuariosuc
  }
}

export default useGetusuariosuc 