import React, { useState,useContext,useEffect,useRef} from 'react';
import ListaPeticionesTabla from '../components/tablas/listaPeticionesTabla';
import { SocketContext } from '../context/SocketContext';
import { useListadoPeticiones } from '../hooks/apis/get/uselistadopeticiones';
import { useNavigate } from 'react-router-dom';
import { LayoutListaPeticion } from '../themes/layout';
import Loader from '../components/loader/loader';
import imgBuscar from '../assets/img/buscarbtn.svg'
import imgnvo from '../assets/img/nvo.svg';
import imgCalendar from '../assets/img/calendar.svg';
import imgAbrir from '../assets/img/abrir.svg';
import { Usepeticion } from '../hooks/peticiones/usepeticion';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";


const ListaPeticiones = () => {

    const inputEl = useRef();
    const{socket}= useContext(SocketContext)
    const { 
        clientebuscador,
        handlerChangueCliente,
        fechalistado,
        fechalistado2,
        estatuslistado,
        handlerchangueStatus,
        handlerchanguedate 
    } = Usepeticion();
      const{DataPeticiones,handlerChargueListPetition,loadingListPetition,handlerchangueDate,FechaBuscador,
        Fechaselected,
        Page,
        registerperpage,
        Search,
        handlerChargueSerch,
    handlerchangueSearch}=useListadoPeticiones()
    const [active, setactive] = useState(false);
    const navigate = useNavigate();
    const handleraddpetition = () => {
        navigate("/peticiones")
    }
    const handlerActive = (e) => {
        e.preventDefault()
        if (active === true) {
            setactive(false)
        } else {
            setactive(true)
        }
    }

    const handlerselected = (e,data) =>{
        e.preventDefault()
        handlerchangueSearch(data)
        handlerChargueSerch(FechaBuscador,data,Page,registerperpage)
        setactive(false)
    }

    const handlerchangueRecolector = (e) =>{
        e.preventDefault()
        handlerChargueSerch(FechaBuscador,Search,Page,registerperpage)
    }

    const datajsonfake = [
        {
            "value": "A",
            "nombre": "Asigando"
        },
        {
            "value": "P",
            "nombre": "Pendiente"
        },
        {
            "value": "R",
            "nombre": "Recolectando"
        }
        ,
        {
            "value": "D",
            "nombre": "Documentando"
        },
        {
            "value": "T",
            "nombre": "Terminado"
        }
    ]

    useEffect(() => {
        handlerChargueListPetition(FechaBuscador,Search,Page,registerperpage)
    }, [])
    
    useEffect( () => {
        if (!socket) return;
        socket.on( 'UpdatePetition', ( data) => {
              console.log(data, "YES I dddd")
              handlerChargueListPetition(FechaBuscador,Search,Page,registerperpage)
        })
      },[ socket])

      useEffect( () => {
        if (!socket) return;
        socket.on( 'NewDocumentation', ( data) => {
              console.log(data, "YES I dddd")
              handlerChargueListPetition(FechaBuscador,Search,Page,registerperpage)
        })
      },[ socket])

      useEffect( () => {
        if (!socket) return;
        socket.on( 'NewPetition', ( data) => {
            handlerChargueListPetition(FechaBuscador,Search,Page,registerperpage)
        })
      },[ socket])
      
      console.log(DataPeticiones,"DDDd")

      const handleDateSelect = () => {
       console.log(inputEl,"REFERENCIA")
       inputEl.current.setOpen(true)
    }
    
    return (
        <LayoutListaPeticion>
            <div className="containerListaPeticiones">
                <div className="containerTitulo">
                    <h1>Peticiones</h1>
                   
                </div>
                <div className='containerlistpetitionactions'>
                    <div className='containerbuttonsheaderlist'>
                        <div className="buscar">
                            <div>
                                <p>Buscar:</p>
                                <input className='inputListPet' placeholder= {clientebuscador} onChange={(e)=> handlerchangueSearch(e.target.value)}></input>
                            </div>
                            <div className="iconoBuscar" onClick={(e) => handlerchangueRecolector(e)}>
                                <img src={imgBuscar} alt="" />
                            </div>
                        </div>
                        <div className='calendar'>
                            <div>
                                <p>Fecha:</p>
                            <DatePicker ref={inputEl} dateFormat="dd/MM/yy" className='inputListPet' selected={Fechaselected} onChange={(date) => handlerchangueDate(date)}/>
                            </div>
                            <div>
                                <img src={imgCalendar} alt="" onClick={() => handleDateSelect()}/>
                            </div>
                        </div>
                        <div className='estatus'>
                        <p>Estatus:</p>
                            <div className="containerflexselect" onClick={(e) => handlerActive(e)}>
                                <div className="containertextselected" >
                                    <div className="spanestatus"></div>
                                </div>
                                <div className="volvervolver">
                                    <img src={imgAbrir} alt="" srcset="" />
                                </div>
                            </div>
                            <div className="divselecteddd">
                                <ul className='listaEstatus' style={active === true ? { display: 'block' } : { display: 'none' }}>
                                   {
                                    datajsonfake.map(r => {
                                         return(
                                            <li onClick={(e) => handlerselected(e,r.value)}>{r.nombre}</li>
                                         )
                                        
                                    })
                                   }
                                </ul>
                            </div>
                        </div>
                        <div className="relleno"></div>
                        <div className='nuevo' onClick={() => handleraddpetition()}>
                            <img src={imgnvo} />
                        </div>
                    </div>
                </div>



                <div className="containerTabla">
                    <ListaPeticionesTabla
                    data={DataPeticiones}
                    loading={loadingListPetition}
                    ></ListaPeticionesTabla>
                </div>
                <div className="containerRegistros">

                </div>
            </div>

        </LayoutListaPeticion >
    )
}

export default ListaPeticiones