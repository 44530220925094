import React, {useState,useEffect} from 'react';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment/moment';


export const useListadoPeticiones = () => {
    // AREA DE VARIABLES
    const [DataPeticiones, setDataPeticiones] = useState();
    const [loadingListPetition, setloadingListPetition] = useState(false)
    const [Search, setSearch] = useState()
    const [FechaBuscador, setFechaBuscador] = useState(moment().format('YYYY-MM-DD'))
    const [Page, setPage] = useState(1);
    const [Pages, setPages] = useState()
    const [registerperpage, setregisterperpage] = useState(10)
    const [Fechaselected, setFechaselected] = useState(new Date)
    // AREA DE FUNCIONES

    const handlerchangueDate = (data) => {
        setFechaselected(data)
        setFechaBuscador(moment(data).format('YYYY-MM-DD'))
        let fechaactual = moment(data).format('YYYY-MM-DD')
        handlerChargueListPetition(fechaactual,Search,Page,registerperpage)
    }
    const handlerchangueSearch = (data)=>{
        setSearch(data)
    }
    const handlerChargueSerch = (fecha,serch,page,totalregistros) =>{
        handlerChargueListPetition(fecha,serch,page,totalregistros)
    }

    console.log(Search,"SEARCH")
    

    const handlerChargueListPetition = (fecha,busqueda,pagina,registros) =>{
        setloadingListPetition(true)
        var getPetitions = {
            method: 'get',
          maxBodyLength: Infinity,
            url: `${config.API_URL}Geolocalizacion/paginator/petition/${fecha}?search=${busqueda === undefined ? '' : busqueda}&page=${pagina}&registerbypage=${registros}`,
            headers: { }
          };
          
          axios(getPetitions)
          .then(function (response) {
            setloadingListPetition(false)
            console.log(JSON.stringify(response.data));
            setDataPeticiones(response.data.resultado)
            setPages(response.data.totalPaginas)
          })
          .catch(function (error) {
            setloadingListPetition(false)
            console.log(error);
          });
    }


  return {
    DataPeticiones,
    handlerChargueListPetition,
    loadingListPetition,
    handlerchangueDate,
    FechaBuscador,
    Fechaselected,
    Page,
    registerperpage,
    Search,
    handlerchangueSearch,
    handlerChargueSerch
  }
}