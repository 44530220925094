import React from 'react'
import { LayoutGrafica } from '../../themes/layout';
import GraficoGanancias from './widgets/graficapaste';
import GraficoTecnologia from './widgets/graficaderadar';
import VerticalBarChart from './widgets/GraficaVertical';

const Layoutgrafica = () => {

    const data = [
        { name: 'Group Al', value: 4000 },
        { name: 'Group Bs', value: 500 },
      ];

  return (
    <LayoutGrafica>
        <div className='wrapertop'>
           <div className='containergraficasdonas'>
                <div>
                  <h4>Grafica de ganancia</h4>
                </div>
                 <div className='containerbodyanalitica'>
                 <GraficoGanancias></GraficoGanancias>
                 </div>
           </div>
           <div className='containergraficasdonas'>
                <div>
                  <h4>Grafica de ganancia</h4>
                </div>
                 <div className='containerbodyanalitica'>
                    <GraficoTecnologia></GraficoTecnologia>
                 </div>
           </div>
           <div className='containergraficasdonas'>
                <div>
                  <h4>Grafica de ganancia</h4>
                </div>
                 <div className='containerbodyanalitica'>
                 <GraficoGanancias></GraficoGanancias>
                 </div>
           </div>
        </div>
        <div>
            <div>
              <VerticalBarChart></VerticalBarChart>
            </div>
        </div>
    </LayoutGrafica>
  )
}

export default Layoutgrafica