import React,{useEffect,useContext,useState} from 'react';
import { SocketContext } from '../../context/SocketContext';
import { useZonaMap } from '../../hooks/mapas/useZonaMap';
import { useMapbox } from '../../hooks/mapas/useMapbox';
import { Datossuc } from '../../hooks/apis/get/datossuc';
import { ZonaMonitor } from '../../themes/layout';
import { TableCustomMonitor } from '../../themes/layout';
import axios from 'axios';
import Loader from '../loader/loader'

import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// IMAGES
import imgUsuario from '../../assets/img/usuarioRecolector.png';
import imglocationr from '../../assets/img/locationr.png';
import imgcajitar from '../../assets/img/cajitar.png';
import imgwsp from '../../assets/img/wsp.png';
import config from '../../config';
import imglogotest from '../../assets/img/logo-react.png'
import { faL } from '@fortawesome/free-solid-svg-icons';
export const MapMonitor = (props) =>{
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const [modalmensaje, setmodalmensaje] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false);
  const handlepruebamodal = (data) =>{
    setmodalmensaje(true)
  }
  const handlecolsemodalmensaje = () =>{
    setmodalmensaje(false)
  }
  const{socket,online,recolectoressocket}=useContext(SocketContext);
  const [puntoinicial, setpuntoinicial] = useState(JSON.parse(localStorage.getItem('coordenadamapa')));
  const [showpeticiones, setshowpeticiones] = useState(false);
  const [userselected, setuserselected] = useState()
  const [textwsp, settextwsp] = useState();
  const [nombrewsp, setnombrewsp] = useState();
  const [numerowsp, setnumerowsp] = useState();
  const [recolectorfiltrado, setrecolectorfiltrado] = useState();
  const [isshowid, setisshowid] = useState()
  const{datossuc}=Datossuc();
  const [UsersSocket, setUsersSocket] = useState();
  const [teststatusrecolector, setteststatusrecolector] = useState()
  const [isselectedptetition, setisselectedptetition] = useState()
  const { setRef,agregarMarcador,actualizarPosicion,handleflyuser,Agregarnuevapeticion,isintozone,isSuc,isnotmarker,marcadores,analiticazona,Peticiones,AddpetitionsRec} = useMapbox(puntoinicial,props.datosdezona,datossuc.data,props.Peticiones);

  useEffect(() => {
        recolectoressocket?.map((res,index) => {
          if(!marcadores.current[res.id]){
            agregarMarcador(res,index)
          }
    })
}, [ socket, agregarMarcador ])
const handleraddmark = () =>{
  recolectoressocket?.map((res,index) => {
    if(!marcadores.current[res.id]){
      agregarMarcador(res,index)
      AddpetitionsRec(props.Peticiones)
    }

})
}
useEffect(() => {
  if (!socket) return;

  socket.on("UpdatedPicker", (marcadores) => {
    actualizarPosicion(marcadores);
  });
}, [socket]);


useEffect( () => {
  if (!socket) return;
  socket.on( 'Arrived', ( data) => {
     
  })
},[ socket])

useEffect( () => {
  if (!socket) return;
  socket.on( 'UpdatePetition', ( data) => {
        props.Accioncargarpeticiones(data.usuarioasignado)
        let pp = props.PeticionesUsuario?.map(r => { return r.estatus})
        setteststatusrecolector(pp)
  })
},[ socket])


const handlertest =() => {
  //handeraddmarker()
  // socket.invoke('NewPetition',"SSDSADASDASDASD")
  socket.invoke('Arrived', { id: 1, origin:"2", })
}
  // FUNCIONES AREA
  const handleshowrecoleciones = (data,index) =>{
      if(showpeticiones === false){
        setshowpeticiones(true)
        setisshowid(index)
        props.Accioncargarpeticiones(data)
      }else{
        setshowpeticiones(false)
        setisshowid()
      }
  }
  // Add Peticion
  useEffect(() => {
    if (!socket) return;
    socket.on( 'NewPetition', (ongg) => {
   
        var Headergetpet = {
          method: 'get',
          url: `${config.API_URL}Geolocalizacion/petition/${ongg.id}`,
          headers: { }
        };
        axios(Headergetpet)
        .then(function (response) {
    
          Agregarnuevapeticion(response.data,ongg)
        })
        .catch(function (error) {
        });
   });
  }, []);
  const handlesavetextwsp =(data) =>{

    settextwsp(data)
  }

  const handlerselectedpetition = async(data) =>{
    // if(isselectedptetition === data){
    //   setisselectedptetition()
    // }else{
    //   setisselectedptetition(data)
    //   handleshowrecoleciones(data)
    // }
    if(showpeticiones === true){
      setuserselected(null)
      setshowpeticiones(false)
    }else {
      setshowpeticiones(true)
      setuserselected(data)
      await props.Accioncargarpeticiones(data)
    }
   
  }
  const handlesendwsp =() =>{
      const url_wsp = `https://wa.me/${numerowsp}?text=${textwsp}` 
      window.open(url_wsp, '_blank');
  }
  const handlerSendWsp = (data, nombre) =>{
    setnumerowsp(data)
    setnombrewsp(nombre)
    handlepruebamodal()
  }
  return(
    <div>
      <ZonaMonitor>
      <div 
    ref={ setRef }
    className="mapContainerMon"
    />
    <div className='Monitorcads'>
        <div className='title' style={{textAlign:'center'}}>
           <h3>Recolectores</h3>
        </div>
        <div style={isnotmarker === true ? {background:'transparent'} : {display:'none'}}>
          <button onClick={() => window.location.reload()}>Recargar</button>
          <p>Ocurrio un error al crear marcador</p>
        </div>
    {
      // props.datosdezona?.map(responsef => {
      //   let userxzona = responsef.recolectores.map(res => res.id)
   
      //   return()
      // })
    
      recolectoressocket?.map((res,index) => {
        if(res.idCiudad == 1){
        
         return(
           <>
           <div className='containerporfile'>
              {/* <div>{res.estatus}</div> */}
              <img src={imgUsuario} 
              style={
                {width:'69px', 
                height:'69px', 
                borderRadius:'38px', 
                borderWidth: '3px', 
                borderStyle: 'dotted solid', 
                borderColor: res.estatus == "A"  
              ? "#3BAF2A" : res.estatus == "R" ? "#FFB531" : res.estatus == "D" ? "#1EE27B" : "#3BAF2A" } }/>
              <p>{res.nombre}</p>
            </div>
            <div>
              <>
                {
                  analiticazona.map(resanalitica => {
                  
                      if(resanalitica[0].id === res.id){
                          return(
                           <>
                              <p>{resanalitica[0].dentrozona != true ? "Fuera de Zona"  :resanalitica[0].zona}</p>
                              <p>{resanalitica[0].distanciasuc <= 40 ? "En Sucursal" : ""}</p>
                           </>

                          )
                      }
                  })
                }
              </>
            </div>
            <div className='containeractionsRecolectores'>
              <img style={{width:20,height:20}} src={marcadores.current[res.id] === undefined ? imglogotest :imglocationr} onClick={marcadores.current[res.id] === undefined ? () => handleraddmark() :() => handleflyuser(marcadores.current[res.id]._lngLat.lng,marcadores.current[res.id]._lngLat.lat)}/>
              <img src={imgwsp} onClick={() => handlerSendWsp("6682655751",res.nombre)} />
              <img src={imgcajitar} onClick={()=>handlerselectedpetition(res.id)} />
            </div>
            {/* <a onClick={() => handleflyuser(res.lng,res.lat)}>Localizar</a> */}
            {/* <a onClick={() => props.Accioncargarpeticiones(res.id)} >Ver peticiones</a> */}
                <div style={showpeticiones === true && userselected === res.id ? {background: 'transparent'} : {display: 'none'}}>
                {
                  props.loadingpeticion === true ?
                  <>
                  <Loader titulo="Peticiones"></Loader>
                  </>
                  :
                    props.PeticionesUsuario?.map(rp => {
                      return(
                        <>
                          {
                            showpeticiones === true && userselected === res.id ?
                            <> 
                            <div className='containerpeticionesdetalle'>
                              <div className='containerpeticionesbox'>
                                <p>Cliente:</p>
                                <p>{rp.cliente}</p>
                              </div>
                                  <div className='containerpeticionesbox'>
                                    <p>Origen peticion:</p>
                                    <p>{rp.origen}</p>
                                  </div>
                                  {
                                    rp.detalles.map(resdetalle => {
                                      return (
                                        <div className='containerpeticionesbox'>
                                          <p>Detalles:</p>
                                          <p>Contenido: {resdetalle.contenido}</p>
                                          <p>Largo:{resdetalle.largo}</p>
                                          <p>Ancho:{resdetalle.ancho}</p>
                                          <p>Alto:{resdetalle.alto}</p>
                                          <p>Peso:{resdetalle.peso}kg</p>
                                        </div>
                                      )
                                    })
                                  }
                                  <div className='containerdetallespeticion'>
                                    <p>Estatus</p>
                                    <p>{rp.estatus === 'A' ? "Activo"
                                      :
                                      rp.estatus === 'R' ? "Recolectando"
                                        :
                                        rp.estatus === 'D' ? "Documentando"
                                          :
                                          rp.estatus === 'T' ? "Terminado"
                                            :
                                            rp.estatus === 'C' ? "Cancelado"
                                              :
                                              "Pendiente"}</p>
                                    {
                                      analiticazona.map(resanalitica => {
                         
                                         return(
                                          <>
                                           <div style={resanalitica[0].id === userselected && rp.estatus !== 'A' ? {background:'transparent'} : {display: 'none'}}>
                                              <p>Distancia</p>
                                              <span>{resanalitica[0].distanciapeticion} {resanalitica[0].distanciapeticion > 1000 ? "Km" : "Metros"}</span> 
                                           </div>
                                          </>
                                         )
                                      })
                                    }
                                  </div>
                            </div>
                            </>
                            :
                            <> </>
                          }
                        </>
                      )
                    })
                  }
                </div>
          </>
         )
        }else{
        }  
       })
    }
<Modal isOpen={modalmensaje} style={customStyles}
        contentLabel="Example Modal" >
          <ZonaMonitor>
                   <div className='containermodal'>
                      <div>
                          <h2>Enviar mensaje a {nombrewsp}</h2>
                      </div>
                      <div>
                        <textarea className="containertextwsp" onChange={(e) => settextwsp(e.target.value)}></textarea>
                      </div>
                      <div className='containerbuttonswsp'>
                        <button disabled={textwsp === undefined ? true : false} className='containerbuttonenviar' onClick={() =>handlesendwsp() }>Enviar</button>
                        <button className='containerbuttoncerrar' onClick={() => handlecolsemodalmensaje()}>Regresar</button>
                      </div>
                   </div>
          </ZonaMonitor>
            </Modal>
    </div>
      </ZonaMonitor>

    <TableCustomMonitor style={props.Peticionessinasignar?.length === 0 ? {display:'none'} : {background:'transparent'}}>

      <div className='containertitlependientes'>
         <h4> - Peticiones pendientes - </h4>
      </div>
      <div className='containertablecustomMonitor'>
      <div className='containerhedercustomtable'>
                 <div>
                    <p>Cliente</p>
                 </div>
                 <div>
                    <p>Origen</p>
                 </div>
                 <div>
                    <p>Fecha</p>
                 </div>
                 <div>
                    <p>Acciones</p>
                 </div>
              </div>
        {
          props.Peticionessinasignar?.map(rest =>{
            return(
             <>
              <div className='containerbodycustomtable'>
                 <div>
                    <p>{rest.cliente}</p>
                 </div>
                 <div>
                    <p>{rest.origen}</p>
                 </div>
                 <div>
                    <p>{rest.fecha}</p>
                 </div>
                 <div>
                    <p>Asignar</p>
                 </div>
              </div>
             </>
            )
          })
        }
      </div>
    </TableCustomMonitor>


    {/* <div>
      <button onClick={() => handlertest()}>
        <p>Test</p>
      </button>
    </div> */}
  </div>
  )

}