import React from 'react';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip, Legend } from 'recharts';

const data = [
  { subject: 'Onsite', Zona1: 90, Zona2: 80},
  { subject: 'Pkt1', Zona1: 70, Zona2: 80},
  { subject: 'Pkt1 Movil', Zona1: 40, Zona2: 80},
];

const GraficoTecnologia = () => (
  <RadarChart cx={220} cy={220} outerRadius={100} width={450} height={330} data={data}>
    <PolarGrid />
    <PolarAngleAxis dataKey="subject" />
    <PolarRadiusAxis />
    <Tooltip />
    <Legend />
    <Radar name="Zona los mochis" dataKey="Zona1" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
    <Radar name="Zona Ejidal" dataKey="Zona2" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
  </RadarChart>
);

export default GraficoTecnologia;