import React from 'react';
import { LayoutLoader } from '../../themes/layout';
import imgHojaVerde from '../../assets/img/hojaverde.svg';
import imgHojaAzul from '../../assets/img/hojaazul.svg';


const Loader = (props) => {
    return (
        <LayoutLoader>
            <div class="loader">
                <div class="loaderImg">
                    <div class="duo duo1">
                        <div class="dot dot-a"> <img src={imgHojaVerde} /></div>
                        <div class="dot dot-b"> <img src={imgHojaAzul} /></div>
                    </div>
                    <div class="duo duo2">
                        <div class="dot dot-a"> <img src={imgHojaAzul} /></div>
                        <div class="dot dot-b"> <img src={imgHojaVerde} /></div>
                    </div>
                </div>
                <div className="loaderTexto">
                    <p>Cargando {props.title}...</p>
                </div>
            </div>
        </LayoutLoader>
    )
}

export default Loader