import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../../config';

const useCiudad = () => {
    const [Ciudades, setciudades] = useState();
    const [loadingCiudad, setloadingSuc] = useState(false);
    useEffect(() => {
        setloadingSuc(true)
        var getSucursales = {
            method: 'get',
            url: `${config.API_URL}Ciudad/All`,
            headers: { }
          };
          
          axios(getSucursales)
          .then(function (response) {
            setloadingSuc(false)
            let datos= response.data.resultado
            setciudades(response.data)
            // setSucursales(datos.filter(datos => !datos.nombre.includes('Virtual')) )
          })
          .catch(function (error) {
            setloadingSuc(false)
            console.log(error);
          });
          
    }, [setciudades])
    
    const loadCiudad = () => {
        setloadingSuc(true)
        var getSucursales = {
            method: 'get',
            url: `${config.API_DEV}Ciudad/All`,
            headers: { }
          };
          
          axios(getSucursales)
          .then(function (response) {
            setloadingSuc(false)
            let datos= response.data.resultado;
            setciudades(response.data)
            // setSucursales(datos.filter(datos => !datos.nombre.includes('Virtual')) )
          })
          .catch(function (error) {
            setloadingSuc(false)
            console.log(error);
          });
    }
    
    
  return {
    Ciudades,
    loadCiudad,
    loadingCiudad
  }
}

export default useCiudad