import React,{useState} from 'react'
import { useZonaMap } from '../../hooks/mapas/useZonaMap'
import { Datossuc } from '../../hooks/apis/get/datossuc';

// const puntoInicial = {
//   lng: -108.98972335988667,
//   lat: 25.79109091258725,
//   zoom: 12
// }

export const GeofanceMap = (props) =>{
  const [puntoinicial, setpuntoinicial] = useState(JSON.parse(localStorage.getItem('coordenadamapa')))
  const{datossuc}=Datossuc()
  const { setRef } = useZonaMap(puntoinicial,props.datosdezona,datossuc.data);

  return(
      <div 
      ref={ setRef }
      className="mapContainerZona"
  />
  )

}