import React from 'react';
import {LayoutDashboard} from '../../themes/layout'


const Widgettophome = (props) => {
// FFB531  naranja
// 35C1ED  azul
// 1EE27B  verde
  return (
    
      <div className="cardhome" style=
      { props.data.color === "Naranja" ? {background: '#f8d089', border: `4px solid #FFB531`} : props.data.color === "Azul" ? {background: '#35c1ed61', border: `4px solid #35C1ED`}  : {background: '#1ee27b6e', border: `4px solid #1EE27B`}}>
        <h3>
          {props?.titulo}
        </h3>
        <p>
          {props?.data.dataset}
        </p>
        <img src={props?.data.icon}
        />
      </div>
   

  )
}

export default Widgettophome