import { useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';
import { HubConnectionBuilder, HubConnectionState,HttpTransportType } from "@microsoft/signalr";

export const useSocket = ( serverPath ) => {
    
   // const socket = useMemo(() => io.connect( serverPath ), [ serverPath ] );
    const [ online, setOnline ] = useState(false);
    const [recolectoressocket, setrecolectores] = useState([])
    const [socket, setSocket] = useState();  // SOCKET SIGNAL

    // SOCKET MARIO LOPEZ
    // useEffect(() => {
    //     setOnline( socket.connected );
    // }, [socket])

    // useEffect(() => {
    //     socket.on('connect', () => setOnline( true ));
    // }, [ socket ])

    // useEffect(() => {
    //     socket.on('disconnect', () => setOnline( false ));
    // }, [ socket ])

    // useEffect(() => {
    //     let Arraycontorladorusuarios = new Array
    //     // let Arrayusuariosresp = new Array
    //     socket.on( 'marcadores-activos', (marcadores) => {
    //       //console.log(datosprueba,"DD");
    //       const data = Object.values(marcadores);
    //       setrecolectores(data)
    //     });
    // }, [ socket ])

    useEffect(() => {
        const connect = new HubConnectionBuilder()
          .withUrl(serverPath, {
            transport: HttpTransportType.LongPolling,
          })
          .withAutomaticReconnect()
          .build();
    
          connect.serverTimeoutInMilliseconds = 60000;
        setSocket(connect);
      }, []);
    
      useEffect(() => {
        if (!socket) return;
    
        socket
          .start()
          .then(() => console.log("Connected."))
          .catch((error) => console.log(error));
      }, [socket]);
    
      useEffect(() => {
        if (!socket) return;
    
        setOnline(socket.state == HubConnectionState.Connected);
      }, [socket]);
    
      useEffect(() => {
        if (!socket) return;
    
        socket.onreconnected(() => setOnline(true));
      }, [socket]);
    
      useEffect(() => {
        if (!socket) return;
    
        socket.onclose(() => setOnline(false));
      }, [socket]);
    
      useEffect(() => {
        if (!socket) return;
    
        socket.on("ActivePickers", (marcadores) => {
          setrecolectores(marcadores);
        });
      }, [socket]);



    return {
        socket,
        online,
        recolectoressocket
    }
}
