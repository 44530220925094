import React,{useContext} from 'react'
import { useRef, useState, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { v4 } from 'uuid';
import { Subject } from 'rxjs';
import { SocketContext } from '../../context/SocketContext';
import * as turf from "@turf/turf";
export const useMapbox = (puntoInicial,datosdezona,datodesuc,peticionesdata) => {

   
    var geocercadata;
    const [iszonaclick, setiszonaclick] = useState(false)
    const mapaDiv = useRef();
    const{socket}=useContext(SocketContext);
    const [isintozone, setisintozone] = useState([]);
    const [isSuc, setisSuc] = useState(false)
      // Mapa y coords
      const mapa = useRef();
      const marcadores = useRef({});
      const Sucursales = useRef({});
      const Peticiones = useRef({});
      const [ coords, setCoords ] = useState( puntoInicial );
      const [ Zona, setZona ] = useState();
      const [isnotmarker, setisnotmarker] = useState(false);
      const [isnomarker, setisnomarker] = useState()
      const [analiticazona, setanaliticazona] = useState([])
      const setRef = useCallback( (node) => {
        mapaDiv.current = node;
       
    },[]);
    var test = new Array;
    var arraypeticiones = new Array
    // FUNCIONES
    const analiticaZona = (id,data,distancia,nombrezona) =>{
        let distanciapr;
        arraypeticiones?.map(r => {
            if(r.idRecolectorAsignado === id && r.estatus === "R"){
                var to = turf.point(r.cords);
                var options = {units: 'kilometers'};
                var pt = turf.point([marcadores.current[id]._lngLat.lng, marcadores.current[id]._lngLat.lat])
                var distance = turf.distance(pt, to, options);
                distanciapr = distance.toFixed(2) * 1000;
            }
        })
        let datosGG = [{
            id:id,
            dentrozona:data,
            distanciasuc: distancia,
            zona:nombrezona,
            distanciapeticion: distanciapr
        }]
        test.push(datosGG)
        setanaliticazona(test)
    }
    const ActanaliticaZona = (id,data,distancia,nombrezona,dataset) =>{
      
        let distanciapr;
        arraypeticiones?.map(r => {
         
            if(r.idRecolectorAsignado === id && r.estatus === "R"){
            //    console.log("ENTRE")
                var to = turf.point(r.cords);
                var options = {units: 'kilometers'};
                var pt = turf.point([marcadores.current[id]._lngLat.lng, marcadores.current[id]._lngLat.lat])
                var distance = turf.distance(pt, to, options);
                distanciapr = distance.toFixed(2) * 1000;

                if(distanciapr === 30){
                    // console.log("ENTRE")
                    socket.invoke('Arrived', { id: r.id, origin: r.Origen, })
                }
            }
        })
         const newd = test.map(r=>{
            if(r[0].id === id){
                r[0].dentrozona=data
                r[0].distanciasuc=distancia
                r[0].distanciapeticion=distanciapr
            }
            return(r)
         })

         console.log(newd,"QUE ES ESTOOO")
         setanaliticazona(newd)
    }
     // Observables de Rxjs
     const movimientoMarcador = useRef( new Subject() );
     const nuevoMarcador = useRef( new Subject() );
      useEffect( () => {
          const map = new mapboxgl.Map({
              container: mapaDiv.current,
              style: 'mapbox://styles/mapbox/streets-v11',
              center: [ puntoInicial?.lng, puntoInicial?.lat ],
              zoom: puntoInicial.zoom
          });
          mapa.current = map;


          // MARCADOR DE SUC
          datodesuc.map(responsesucursales =>{
            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                `<div>
                    <div><h3>${responsesucursales.nombre}</h3></div>
                </div>`
                );
            const eldata = document.createElement('div');
            eldata.className = 'MarcadorSuc';
            const marcadorSuc = new mapboxgl.Marker(eldata);
            marcadorSuc.id = responsesucursales.id
            marcadorSuc
            .setLngLat([responsesucursales.lng,responsesucursales.lat])
            .setPopup(popup) // sets a popup on this marker
            .addTo( mapa.current )
            Sucursales.current[ marcadorSuc.id ] = marcadorSuc;
        })
      },[ puntoInicial ]);
        // Agregamos recolector al mapa
 const agregarMarcador = useCallback( (ev, id ) => {
        const { lng, lat } = ev.lngLat || ev;
        const marker = new mapboxgl.Marker({
            color: "#39aa29"
        });
        const popupcustom = new mapboxgl.Popup({ closeOnClick: false });
        marker.id = ev.id ?? v4();
        marker
        .setLngLat([ lng, lat ])
        .addTo( mapa.current )
        .setPopup(popupcustom)
        .setDraggable( false );
        marcadores.current[ marker.id ] = marker;
        if ( !id ) {
            nuevoMarcador.current.next({
                id: marker.id,
                lng, 
                lat
            });
        }
        let pt = turf.point([lng, lat]);
       // analiticaZonaDentro(ev.id,pt)
       datosdezona?.map(responsef => {
        let userxzona = responsef.recolectores.map(res => res.id)
            if(userxzona.includes(ev.id)){
                var poly = turf.polygon(JSON.parse(responsef.cords))
                var zona = turf.booleanPointInPolygon(pt, poly)
                var to = turf.point([Sucursales.current[1]._lngLat.lng, Sucursales.current[1]._lngLat.lat]);
                        var options = {units: 'kilometers'};
                        var distance = turf.distance(pt, to, options);
                        let distancia = distance.toFixed(2) * 1000;
                analiticaZona(ev.id,zona,distancia,responsef.observaciones)
            }
      })

       
    },[]);
    // Agregamos peticiones
    const Agregarnuevapeticion = useCallback( (ev, id ) => {
            console.log(ev, "EVENTO ¨PARA AGREgar PETICIONES")
           let arraypeticiones = new Array
           const datasettest = JSON.parse(ev[0].coordenadas)
           let datap = new Array;
           datap.push(datasettest.lng,datasettest.lat)
           let datasetpp = JSON.stringify(datap)
           let p1 = datasetpp;
           let p2 = p1.replace(/['"]+/g, '')
           let p3 = p2.replaceAll(":", '')
           let p4 = p3.replaceAll("lng", '')
           let p5 = p4.replaceAll("lat", '')
           let p6 = p5.replaceAll("{", '[')
           let p7 = p6.replaceAll("}", "]")
           let p = JSON.parse(p7)
           let dt ={
            id: ev[0].id,
            cords: p,
            estatus: ev[0].estatus,
            Origen: ev[0].origen,
            idRecolectorAsignado:  ev[0].idusuarioasignado,
         }
            arraypeticiones.push(dt)
            arraypeticiones.map(Peticionesex =>{
               const el = document.createElement('div');
               el.className = 'MarcadorPeticion';
                 const marcadorpeticiones = new mapboxgl.Marker(el);
                 marcadorpeticiones
                 .setLngLat(Peticionesex.cords)
                 .addTo( mapa.current )
             })
       },[]);
        // Agregar Zonas a mapa de zonas 
        useEffect(() => {
            mapa.current?.on('load', () => {
                datosdezona?.map(responsecords =>{
                    let Arraygeocerca = new Array(responsecords.cords)
                    let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                    const cordenadas = ObjGeocerca.map(resto => {return resto});  
                    geocercadata = turf.polygon(cordenadas);
                    mapa.current.addSource(responsecords.observaciones, {
                        "type": "geojson",
                        "data": {
                            "type": "Feature",
                            "geometry": geocercadata.geometry
                        }
                    })
                    mapa.current.addLayer({
                        'id': responsecords.observaciones,
                        'type': 'fill',
                        'source': responsecords.observaciones, // reference the data source
                        'paint': {
                        'fill-color': responsecords.color, // blue color fill
                        'fill-opacity': 0.5
                        },
                        'filter': ['==', '$type', 'Polygon']
                        });
                        mapa.current.on('click',responsecords.observaciones, (e) => {
                            if(iszonaclick === false){
                                setiszonaclick(true)
                            }else {
                                setiszonaclick(false)
                            }
                            });
                })
               
            })
        }, [])

        useEffect(() => {
           
            mapa.current?.on('load', () => {
                   // MARCADOR PETICION
        peticionesdata?.map(respeticiones => {
            console.log(respeticiones,"PETICIONES MMMP")
            if(respeticiones.estatus !== "T"){
                const datasettest = JSON.parse(respeticiones.coordenadas)
                let datap = new Array;
                datap.push(datasettest.lng,datasettest.lat)
                let datasetpp = JSON.stringify(datap) 
                let p1 = datasetpp;
                let p2 = p1.replace(/['"]+/g, '')
                let p3 = p2.replaceAll(":", '')
                let p4 = p3.replaceAll("lng", '')
                let p5 = p4.replaceAll("lat", '')
                let p6 = p5.replaceAll("{", '[')
                let p7 = p6.replaceAll("}", "]")
                 let p = JSON.parse(p7)
                 let dt ={
                    id:respeticiones.id,
                    cords: p,
                    estatus: respeticiones.estatus,
                    Origen: respeticiones.origen,
                    idRecolectorAsignado:  respeticiones.idusuarioasignado,
                 }
                 arraypeticiones.push(dt)
            }
              
           })

          
           arraypeticiones.map(Peticionesex =>{
               const elp = document.createElement('div');
               elp.className = 'MarcadorPeticion';
                 const marcadorpeticiones = new mapboxgl.Marker(elp);
                 marcadorpeticiones.id = Peticionesex.id
                 marcadorpeticiones
                 .setLngLat(Peticionesex.cords)
                 .addTo( mapa.current )
                 Peticiones.current[ marcadorpeticiones.id ] = marcadorpeticiones;
             })
            })
            }, [])


            const AddpetitionsRec = (peticionesdata) =>{

                console.log(peticionesdata,"MAMAMAIA")
               
                    // MARCADOR PETICION
         peticionesdata?.map(respeticiones => {
             console.log(respeticiones.estatus,"PETICIONES MMMP")
             if(respeticiones.estatus !== "T"){
                 const datasettest = JSON.parse(respeticiones.coordenadas)
                 let datap = new Array;
                 datap.push(datasettest.lng,datasettest.lat)
                 let datasetpp = JSON.stringify(datap) 
                 let p1 = datasetpp;
                 let p2 = p1.replace(/['"]+/g, '')
                 let p3 = p2.replaceAll(":", '')
                 let p4 = p3.replaceAll("lng", '')
                 let p5 = p4.replaceAll("lat", '')
                 let p6 = p5.replaceAll("{", '[')
                 let p7 = p6.replaceAll("}", "]")
                  let p = JSON.parse(p7)
                  let dt ={
                     id:respeticiones.id,
                     cords: p,
                     estatus: respeticiones.estatus,
                     Origen: respeticiones.origen,
                     idRecolectorAsignado:  respeticiones.idusuarioasignado,
                  }
                  arraypeticiones.push(dt)
             }
               
            })
            arraypeticiones.map(Peticionesex =>{
                console.log("MMMMMMMMMMMMMM")
                const elp = document.createElement('div');
                elp.className = 'MarcadorPeticion';
                  const marcadorpeticiones = new mapboxgl.Marker(elp);
                  marcadorpeticiones.id = Peticionesex.id
                  marcadorpeticiones
                  .setLngLat(Peticionesex.cords)
                  .addTo( mapa.current )
                  Peticiones.current[ marcadorpeticiones.id ] = marcadorpeticiones;
              })
           
            }
            

            const analiticaZonaDentro = (data,point,suc) =>{
                var to = turf.point([Sucursales.current[1]._lngLat.lng, Sucursales.current[1]._lngLat.lat]);
                var options = {units: 'kilometers'};
                var distance = turf.distance(point, to, options);
                let distancia = distance.toFixed(2) * 1000;
                if(distancia == 0 || distancia == 30){
                    setisSuc(true)
                }else{
                    setisSuc(false)
                }
                datosdezona?.map(responsef => {
                    let userxzona = responsef.recolectores.map(res => res.id)
                        if(userxzona.includes(data)){
                            var poly = turf.polygon(JSON.parse(responsef.cords))
                            let datax ={
                                id:data,
                                iszone: turf.booleanPointInPolygon(point, poly)
                            }
                            setisintozone([...isintozone,datax])
                        }
                  })
            }

        const actualizarPosicion = useCallback( (datosg) => {
            // marcadores.current[id].setLngLat([ lng, lat ]);
            let data={
                id: datosg.id,
                lng: datosg.lng,
                lat: datosg.lat
            }
            if(marcadores.current[datosg.id] === undefined){
                setisnotmarker(true)
                agregarMarcador(data.id)
            }
                setisnotmarker(false)
                marcadores.current[datosg.id].setLngLat([ datosg.lng, datosg.lat ]);
                let pt = turf.point([datosg.lng, datosg.lat]);
               // analiticaZonaDentro(id,pt)
               datosdezona?.map(responsef => {
                let userxzona = responsef.recolectores.map(res => res.id)
                    if(userxzona.includes(datosg.id)){
                        var poly = turf.polygon(JSON.parse(responsef.cords))
                        var zona = turf.booleanPointInPolygon(pt, poly)
                        var to = turf.point([Sucursales.current[1]._lngLat.lng, Sucursales.current[1]._lngLat.lat]);
                        var options = {units: 'kilometers'};
                        var distance = turf.distance(pt, to, options);
                        let distancia = distance.toFixed(2) * 1000;
                        ActanaliticaZona(datosg.id,zona,distancia,responsef.observaciones,analiticazona)
                    }
              })   
        },[])
        
    const handleflyuser = (data1, data2) =>{
        mapa.current?.flyTo({zoom: 15.5, center: [JSON.stringify(data1), JSON.stringify(data2)],})
    }
  return {
    setRef,
    agregarMarcador,
    marcadores,
    Peticiones,
    actualizarPosicion,
    handleflyuser,
    Agregarnuevapeticion,
    isintozone,
    isSuc,
    isnotmarker,
    analiticazona,
    AddpetitionsRec
  }
}