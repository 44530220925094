import React,{useState} from 'react';
import { StyledTable } from '../../themes/layout';

const Tablarecolectores = (props) => {
    const [headerDatos, setheaderDatos] = useState(Object.values(props?.header));
    const [openModal, setopenModal] = useState(false);
  return (
    <StyledTable>
        <div ref={props.divtablarecolectores} className="containerTablaNuevaZona nvazona" >
                        <table className='containertablezones tablezone'>
                            {
                                headerDatos?.map(responseheader => {
                                    return (
                                        <td className='containerverde'>{responseheader}</td>
                                    )
                                })
                            }
                            {
                                props.data?.map((responsedata,index) => {
                                    if(responsedata.id !== 0){
                                        return (
                                            <tr className='dd'>
                                                <th className='campoCliente'>{responsedata.descripcion}</th>
                                                <th>{responsedata.idsucursal === 1 ? 'Los Mochis' : 'Culiacan'}</th>
                                                    <th>
                                                        <div className="containerAccion containerccheck">
                                                            <label class="main">
                                                                <input type="checkbox" onClick={() => props.accionguardar(responsedata.id,responsedata.descripcion,index)} />
                                                                <span class="geekmark"></span>
                                                            </label>
                                                        </div>
                                                    </th>
                                            </tr>
                                        )
                                    }
                                    
                                })
                            }
                        </table>
                    </div>
    </StyledTable>
  )
}

export default Tablarecolectores