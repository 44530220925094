import React, { useContext, useState } from 'react'
import { authContext } from '../context/Authcontext'
import { MapcomponentNewfance } from '../components/mapas/Addgeofance'
import Tablarecolectores from '../components/tablas/recolectores'
import useGetusuariosuc from '../hooks/apis/get/useUsuarioSuc'
import { Useaddrecolector } from '../hooks/apis/useAddrecolector'
import useGetZona from '../hooks/apis/get/useZona'
import Addfance from '../components/addgeofance'
import WidgetGraficahome from '../components/widgets/widgetGraficahome'
import WidgetCardhome from '../components/dashboard/DashboardCardhome'
import { LayoutDashboard } from '../themes/layout'
function Dashboard() {
  const{user} = useContext(authContext)
  const { datousuariosuc } = useGetusuariosuc()
  const { recolectores, handlerecolectorgeo } = Useaddrecolector()
  const { datoZona, loadZonas, loadingzona } = useGetZona()
  const [headerTable, setheaderTable] = useState({
    nombre: "Recolector",
    sucursal: "Sucursal",
    acciones: "Acciones"
  })
  const datag = {
    mesanterior: 12000,
    mesactual: 32000,
    total: 44000
  }


  return (

    <LayoutDashboard>
      {
        user?.id === 119 ?
        <>
        <div className="containerTopDashboard">
        <WidgetCardhome></WidgetCardhome>
        <WidgetGraficahome  data={datag} usuarios={datousuariosuc}></WidgetGraficahome>
      </div>
        </>
        :
        <>
        
        </>
      }
      
      {/* <div>
        <p>FF</p>
      </div> */}
    </LayoutDashboard>

  )
}

export default Dashboard