import React,{useRef} from 'react'

export const Test = () => {
    const divprincipalcrearzona = useRef()
    const divtablarecolectores = useRef()
  return {
    divprincipalcrearzona,
    divtablarecolectores
  }
}
