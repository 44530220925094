import { useRef, useState, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { v4 } from 'uuid';
import { Subject } from 'rxjs';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import axios from 'axios';
import * as turf from "@turf/turf";
import API_URL from '../config';
import { Datossuc } from './apis/get/datossuc';
mapboxgl.accessToken = 'pk.eyJ1IjoicGt0MWRldmVsb3BlciIsImEiOiJjbDZ6OXVjbzgwMmFrM3BsY3FweGkyYWJzIn0.r8Iu1ZteE0WwYt1LFfT1yg';

export const Usemapboxgeofance = ( puntoInicial, datosdezona,guardarcordenada, datazona, datodesuc ) => {
const{datossuc}=Datossuc()
    const [nombregeofance, setnombregeofance] = useState()
    const [datosgeofance, setdatosgeofance] = useState()
    const [cordsdata, setcordsdata] = useState()
    const draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
        polygon: true,
        trash: true
        },
        defaultMode: 'draw_polygon'
        });
    const [marcadoresp, setmarcadoresp] = useState([])
    const mapaDiv = useRef();
    var geocercadata;
    
    const setRef = useCallback( (node) => {
        mapaDiv.current = node;
    },[]);
    // Mapa y coords
    const mapa = useRef();
    const [ coords, setCoords ] = useState( puntoInicial );
    useEffect( () => {
        const map = new mapboxgl.Map({
            container: mapaDiv.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [ puntoInicial.lng, puntoInicial.lat ],
            zoom: puntoInicial.zoom
        });
        mapa.current = map;
    },[ puntoInicial ]);
    // Cuando se mueve el mapa
    useEffect(() => {

        mapa.current?.on('move', () => {
            const { lng, lat } = mapa.current.getCenter();
            setCoords({
                lng: lng.toFixed(4),
                lat: lat.toFixed(4),
                zoom: mapa.current.getZoom().toFixed(2)
            })
        });

    },[]);

     // Agregar Zonas a mapa de zonas 
     useEffect(() => {
        mapa.current?.on('load', () => {
            datosdezona?.map(responsecords =>{
                let Arraygeocerca = new Array(responsecords.cords)
                let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                const cordenadas = ObjGeocerca.map(resto => {return resto});  
                geocercadata = turf.polygon(cordenadas);
                mapa.current.addSource(responsecords.observaciones, {
                    "type": "geojson",
                    "data": {
                        "type": "Feature",
                        "geometry": geocercadata.geometry
                    }
                })
                mapa.current.addLayer({
                    'id': responsecords.observaciones,
                    'type': 'fill',
                    'source': responsecords.observaciones, // reference the data source
                    'paint': {
                    'fill-color': responsecords.color, // blue color fill
                    'fill-opacity': 0.5
                    },
                    'filter': ['==', '$type', 'Polygon']
                    });
            })

            // Agregar Sucursales a mapa de zona
            datodesuc.map(responsesucursales =>{
                const el = document.createElement('div');
                el.className = 'MarcadorSuc';
                const marcadorSuc = new mapboxgl.Marker(el);
                marcadorSuc
                .setLngLat([responsesucursales.lng,responsesucursales.lat])
                .addTo( mapa.current )
            })
        })
    }, [])

    

useEffect(() => {
        mapa.current.addControl(draw);
        mapa.current.on('draw.create', updateArea);
        mapa.current.on('draw.delete', updateArea);
        mapa.current.on('draw.update', updateArea);
}, []);

const handlernombregeofance = (data) =>{
        setnombregeofance(data)
}

function updateArea(e) {
        setcordsdata(JSON.stringify(e.features[0].geometry.coordinates))
        guardarcordenada(JSON.stringify(e.features[0].geometry.coordinates))
        const data = draw.getAll();
        const answer = document.getElementById('calculated-area');
        if (data.features.length > 0) {
        const area = turf.area(data);
        // Restrict the area to 2 decimal points.
        const rounded_area = Math.round(area * 100) / 100;
        } else {
        answer.innerHTML = '';
        if (e.type !== 'draw.delete')
        alert('Click the map to draw a polygon.');
        }
    }

    return {
        setRef,
        cordsdata
    }
}
