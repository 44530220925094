import React, { useState, useEffect, useContext } from 'react';
import { SocketContext } from '../../context/SocketContext';
import imgNoti from '../../assets/img/Noti.svg';
import imgNotiactive from '../../assets/img/imgNotiactive.svg';
import imgUsuario from '../../assets/img/imgUsuario.svg';
import imgAbrir from '../../assets/img/abrir.svg';
import imgCerrar from '../../assets/img/exit.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/Authcontext';
import useSucursal from '../../hooks/apis/get/useSucursal';
import { LayoutHeader } from '../../themes/layout';
import { usePeticiones } from '../../hooks/apis/get/usePeticiones';
import useCiudad from '../../hooks/apis/get/useCiudad';
import { Notificacioncomponent } from './Notificacioncomponent';
import { type } from '@testing-library/user-event/dist/type';


const Headercomponent = () => {
  const navigate = useNavigate()
  const [IsActive, setIsActive] = useState(false);
  const [IsActivesuc, setIsActivesuc] = useState(false)
  const { Sucursales, loadingSuc } = useSucursal()
  const { Ciudades } = useCiudad()
  const { logout, ciudadSelecionada, handlercambioSucursal, user} = useAuth()
  const { Peticiones,Alertas,loadpeticiones} = usePeticiones()
  const [Notif, setNotif] = useState()
  const{socket,online,recolectoressocket}=useContext(SocketContext);
  const CierreSesion = () => {
    window.location.reload()
    logout()
  }


  useEffect( () => {
    if (!socket) return;
    socket.on( 'UpdatePetition', ( data) => {
     
          loadpeticiones()
    })
  },[ socket])
  useEffect( () => {
    if (!socket) return;
    socket.on( 'NewPetition', ( data) => {
      
          loadpeticiones()
    })
  },[ socket])
  
  const handlerActive = (e) => {
    e.preventDefault()
    if (IsActive === true) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }

  const handlerActivesuc = (e) => {
    e.preventDefault()
    if (IsActivesuc) {
      setIsActivesuc(false)
    } else {
      setIsActivesuc(true)
    }
  }
  const handlerselectedsuc = (e, data) => {
    e.preventDefault()
    if (IsActivesuc) {
      handlercambioSucursal(data)
      navigate("/")
      setIsActivesuc(false)
    } else {
      setIsActivesuc(true)
    }
  }



  const handlerverNoti = (e, data) => {

    let datos = data;

    if (Notif === "Notificacion") {
      setNotif()
    } else {
      setNotif(datos)
    }

  }
  const handlerverpapaya = (e, data) => {

  }
  const handlernoverNoti = (e, data) => {
    if (type === "Notificacion") {
      setNotif()
    } else {
      setNotif(data)
    }
  }
  return (
    <LayoutHeader>
      <>
        <div className="containerHeader">
          <div className="containerCiudades">
            <div className="containerflexselect" onClick={(e) => handlerActivesuc(e)}>
              <div className="containertextselected" >
                <div className="spanhedersuc">{ciudadSelecionada?.nombre}</div>
              </div>
              <div className="volvervolver">
                <img src={imgAbrir} alt="" srcset="" />

              </div>
            </div>
            <div className="divselected">
              <ul className='listaSucursalesHeader' style={IsActivesuc === true ? { display: 'block' } : { display: 'none' }}>
                {
                  Ciudades === undefined || Ciudades === "" || Ciudades === [] ?
                    <>
                    </>
                    :
                    Ciudades?.map(rest => {
                      return (
                        // <li onClick={(e) => handlerselectedsuc(e, rest)}>{rest.nombre}</li>
                        <li onClick={(e) => handlerselectedsuc(e, rest)}>{rest.nombre}</li>
                      )
                    })
                }
              </ul>
            </div>
          </div>
          <div className="containerUsuario">
            <div className={`containerNoti ${Alertas?.length > 0  ? "alertactive" : ''}`} onClick={(e) => handlerverNoti(e, "Notificacion")}>
              <img src={Alertas?.length > 0  ?  imgNotiactive : imgNoti} alt="" />
              <span>{
                      Alertas?.length
              }</span>
            </div>
            <div className="containerCerrarSesion" onClick={(e) => handlerActive(e)}>
              <img src={imgUsuario} />
              <p className="containerusername">{user.descripcion}</p>
              <img className={IsActive === true ? "flechaAbrirActive" : "flechaAbrir"} src={imgAbrir} alt="" />
            </div>
          </div>
        </div>
        <div style={IsActive === true ? { display: "block" } : { display: "none" }}>
          <div className="CerrarSesion">
            <div className="containerCerrar">
              <button onClick={() => CierreSesion()}>Cerrar Sesión</button>
              <img src={imgCerrar} alt="" />
            </div>
          </div>
        </div>
        <div className="notificacionHeader">
          <Notificacioncomponent
            Peticiones={Peticiones}
            verNoti={handlerverNoti}
            noverNoti={handlernoverNoti}
            Notif={Notif}
          ></Notificacioncomponent>
        </div>
      </>
    </LayoutHeader>


  )
}

export default Headercomponent