import { type } from '@testing-library/user-event/dist/type';
import React, { useState } from 'react';

export const Notificacioncomponent = (props) => {

    return (
        <div className={`containerNotificacion ${props.Notif === 'Notificacion' ? "Active" : 'hiddenNoti'}`}>
            <ul >
                {
                    props.Peticiones?.map(respNoti => {
                        if (respNoti.estatus != "T") {
                            return (
                                <>
                                    <li className="noti">
                                        <div className="encabezado">
                                            <div className="origenNoti">
                                                <p>{respNoti.origen}</p>
                                            </div>
                                            <div className="fechaNoti">
                                                <p>{respNoti.fecha}</p>
                                            </div>
                                        </div>
                                        <div className="clienteNoti">
                                            <p>{respNoti.cliente}</p>
                                        </div>
                                        <div className="estatus">
                                            <p>{respNoti.estatus === 'A' ? "Asignado"
                                                :
                                                respNoti.estatus === 'D' ? "Documentando"
                                                    :
                                                    respNoti.estatus === 'R' ? "Recolectando"
                                                        :
                                                        respNoti.estatus === 'T' ? "Terminado"
                                                            :
                                                            respNoti.estatus === 'C' ? "Cancelado"
                                                                :
                                                                "Pendiente"}</p>
                                        </div>
                                    </li>
                                </>
                            )
                        }
                    })
                }
            </ul>
        </div>
    )
}
