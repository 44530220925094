import styled from 'styled-components';

const Layoutcontainer = styled.div`



@import "./var.css";
@import url('https://fonts.googleapis.com/css2?family=Amiko:wght@400;600;700&display=swap');
:root{
    --azul: #151B4E;
    --verde: #3BAF29;
    --verdeb: #B4DBB0;
    --verdef: #40A859;
    --blanco: #fff;
    --grisb: #F0F1F3;
    --grism: #9597A7;
    --grisf: #666770;
    --fondo: #D9D9D9;
    

}

*{
    font-family: 'Amiko', sans-serif;
}
.containerusername{
    font-weight: bold;
    font-size: 15px;
    margin-left: 3%;
    margin-bottom: 0px;
    color: #F0F1F3;
}
.mapContainer {
  width: 100%;
height: 89vh; 
margin-top: -2px;
}
.mapContainerZona{
    width: 95%;
    height: 60vh; 
    margin-left:2.5%;
    margin-top: -2px;
}
.mapContainerMon{
    width: 97%;
    height: 70vh; 
    margin-left:1.5%;
    margin-top: -2px;
}
.MarcadorPeticion {
  background-image: url(${(props) => props.imgpetition});
  background-size: cover;
  width:40px;
  height: 39px;
  border-radius:50%;
  cursor: pointer;
}
.MarcadorSuc {
    background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png);
  background-size: cover;
  width: 40px;
  height: 32px;
  cursor: pointer;
}
@media only screen and (min-width: 300px) {}

@media only screen and (max-width: 600px) {
    .containerCiudades {
        display: none;
    }

    .containerHeader {
        justify-content: right;
        height: 48px;
    }

    .menumovil {
        position: relative;
        width: 70px;
        display: flex;
        flex-direction: column;
        background-color: #F0F1F3;
    }

    .menumovil span:nth-child(1) {
        background-color: #151B4E;
        border-radius: 10px;
        height: 5px;
        width: 40px;
        position: absolute;
        left: 16px;
        top: 17px;
    }

    .menumovil span:nth-child(2) {
        background-color: #151B4E;
        border-radius: 10px;
        height: 5px;
        width: 40px;
        position: absolute;
        top: 27px;
        left: 16px;
    }

    .menumovil span:nth-child(3) {
        background-color: #151B4E;
        border-radius: 10px;
        height: 5px;
        width: 40px;
        position: absolute;
        top: 37px;
        left: 16px;
    }

    .container-sidemenuLayout {
        display: none;
    }

}

@media only screen and (min-width: 600px) {
    .containerCiudades {
        display: none;
    }

    .containerHeader {
        justify-content: right;
        height: 58px;
    }


    .container-sidemenuLayout {
        display: none;
    }
    
    .menumovil {
        position: relative;
        width: 70px;
        display: flex;
        flex-direction: column;
        background-color: #F0F1F3;
    }

    .menumovil span:nth-child(1) {
        background-color: #151B4E;
        border-radius: 10px;
        height: 5px;
        width: 40px;
        position: absolute;
        left: 16px;
        top: 17px;
    }

    .menumovil span:nth-child(2) {
        background-color: #151B4E;
        border-radius: 10px;
        height: 5px;
        width: 40px;
        position: absolute;
        top: 27px;
        left: 16px;
    }

    .menumovil span:nth-child(3) {
        background-color: #151B4E;
        border-radius: 10px;
        height: 5px;
        width: 40px;
        position: absolute;
        top: 37px;
        left: 16px;
    }

}

@media only screen and (min-width: 768px) {
    .containerCiudades {
        display: block;
    }

    .containerHeader {
        justify-content: space-between;
        height: 58px;
    }

    .container-sidemenuLayout {
        display: block;
    }

    .menumovil {
        display: none;
    }
    
}

@media only screen and (min-width: 992px) {}
`;
const LayoutcontainerLogin = styled.div`
.login-principal {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-size:cover !important;
    background-repeat: no-repeat !important;
    height: 100vh;
}
.contendor-login {
    display: flex;
    flex-direction: row;
}
.contenedor-datos {
    width: 430px;
    height: 550px;
    background: #ffffff36;
    border-radius: 10px 0px 0px 10px;
    color: #fff;
}
.btnIngresar button {
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    height: 34px;
    width: 130px;
    padding-top: 4px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    margin-left: 60%;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}
.btnIngresar button:hover {
    background-color: #2A7A1E;
}
.contenedor-img{
    position: relative;
    padding-top: 23px;

}
.contenedor-img img {
    height: 551px;
    width: 430px;
}

.contenedor-datos h1 {
    font-size: 35px;
    padding-left: 40px;
    padding-top: 80px;
    text-shadow: rgb(70, 73, 71) 0.1em 0.1em 0.2em;
}

.contenedor-datos h2 {
    font-size: 18px;
    padding-left: 40px;
    margin-top: 50px;
}

.contenedor-datos h2:nth-of-type(1) {
    margin-top: 50px;
}

.contenedor-datos h3 {
    font-size: 14px;
    padding-left: 40px;
    margin-top: 32px;
}

.contenedor-datos input {
    width: 340px;
    display: block;
    margin: auto;
    height: 20px;
    background: transparent;
    border: none;
    border-bottom: solid;
    border-radius: 10px;
    padding-top: 10px;
    color: #fff !important;
    padding-left: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background: none !important;
    border-color: #fff;
    color: #fff !important;
    padding-left: 10px;
}

input:focus-visible {
    outline-offset: none !important;
}

input:focus-visible {
    outline: none !important;
    outline-offset: none !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}


.contenedor-datos img {
    height: 20px;
    padding-right: 10px;
}

`;
const StyledTable = styled.div`
    .tablezone{
    color: #666770;
    font-weight: 400;
    font-size: 15px;
    border-width: 1px;
    padding-top: 8px;
    padding-bottom: 10px;
    width: 98%;
    margin: auto;
    border-spacing: 0px;
    background: #F0F1F3;
    border-radius: 18px;
    margin-top: 18px;
    }
    .containerTablaNuevaZona th {
    color: #666770;
    font-weight: 400;
    font-size: 15px;
    border-bottom: solid;
    border-color: #9597A7;
    border-bottom-style: dashed;
    border-width: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.containerverde {
    color: #151B4E;
    width: 240px;
    text-align: center;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 15px;
    font-weight:bold;
    font-size: 19px;
    border-bottom: solid;
    border-bottom-color: #3BAF29;
    border-width: 2px;

}

.main {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;
    margin-left: 28px;
    margin-top: 3px;
}
  .containerccheck{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 35px;

  }
/* Ocultar el input default */
input[type=checkbox] {
    visibility: hidden;
}
  
/* Empezamos a creear checkbox custom    */
.geekmark {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    height: 30px;
    width: 30px;
    background-color: #9597A7;
}
  
/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input ~ .geekmark {
    background-color: #9597A7;
}
  
/* Specify the background color to be
shown when checkbox is active */
.main input:active ~ .geekmark {
    background-color: #3BAF29;
}
  
/* Specify the background color to be
shown when checkbox is checked */
.main input:checked ~ .geekmark {
    background-color: #3BAF29;
}
  
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
    display: block;
}
  
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
    left: 10px;
    bottom: 10px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
} 
.containerverde:nth-of-type(1){
    border-bottom-left-radius: 20px;
}
.containerverde:nth-of-type(3){
    border-bottom-right-radius: 20px ;
}
`;
const LayoutLoader = styled.div`

.loaderImg {
  position: relative;
  width: 100px;
  margin: 80px auto;
  transform: rotate(180deg);
}
.loader img{
    height: 85%;
    width: 85%;
}
.duo {
  height: 55px;
  width: 110px;
  /* background: hsla(0, 0%, 0%, 0.0); */
  position: absolute;
  
}

.duo, .dot {
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.duo1 {
    right: 27px;
}

.duo2 {
  left: 20px;
}


.dot {
  width: 40px;
  height: 40px;
  /* border-radius: 100%; */
  position: absolute;
}

.dot-a {
  left: 0px;
  /* background: #3BAF29; */
}

.dot-b {
  right: 0px;
  /* background: #151B4E; */

}


@keyframes spin {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(180deg) }
  100% { transform: rotate(180deg) }
}

@keyframes onOff {
    0% { opacity: 0; }
   49% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 1; }
}

.duo1 {
  animation-name: spin;
}

.duo2 {
  animation-name: spin;
  animation-direction: reverse;
}

.duo2 .dot-b {
  animation-name: onOff;
}

.duo1 .dot-a {
  opacity: 0;
  animation-name: onOff;
  animation-direction: reverse;
}
.loaderTexto{
    display: flex;
    justify-content: center;
    color: #3BAF29;
    font-weight: bold;
    font-size: 22px;
    padding-top: 8px;
    letter-spacing: 1px;
}
`
const LayoutSidebar = styled.div`

height: 100vh;
background: #F0F1F3;

.containerLogo img {
    width: 90px !important;
    display: block;
    margin: auto;
    padding-top: 5px;
}
.acti{
    color: #40A859 !important;
}
.activmapa{
    color: #40A859 !important;
    position: absolute;
    bottom: 7px;
    width: 100%;
    text-align: center;
}
.inactiv{
    color: #666770 !important;
}
.containerSidebar {
    display: flex;
    flex-direction: column;
    background-color: #F0F1F3 !important;
}

.containerSidebar img {
    width: 65px;
    height: 60px;
    display: block;
    margin: auto;
}

.containerVerde {
    border-radius: 25px;
    height: 90px;
    width: 95px;
    margin: auto;
    margin-top: 10px;
    padding-top: 5px;
    position: relative;
    cursor: pointer;
}
.containerVerdeActive {
    border-radius: 25px;
    height: 90px;
    width: 95px;
    margin: auto;
    margin-top: 10px;
    padding-top: 5px;
    position: relative;
    cursor: pointer;
    background-color: #B4DBB0;
}

.containerVerde:hover {
    background-color: #B4DBB0;
    border-radius: 25px;
    height: 90px;
    width: 95px;
    margin: auto;
    margin-top: 10px;
    padding-top: 5px;
    position: relative;
    cursor: pointer;
}

.ActiveMenu {
    background-color: #B4DBB0;
    border-radius: 25px;
    height: 90px;
    width: 95px;
    margin: auto;
    margin-top: 10px;
    padding-top: 5px;
    position: relative;
    cursor: pointer;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.35));
}

.containerSidebar span {
    display: flex;
    justify-content: center;
    color: #666770;
    font-weight: bold;
    padding-top: 5px;
}

.submenu {
    background-color: #B4DBB0;
    height: 193px;
    width: 130px;
    position: absolute;
    top: 4px;
    border-left: 5px solid #fff;
    left: 95px;
    border-color: #F0F1F3;
    z-index: 200;
}
.submenu li {
    background-color: #B4DBB0;
    color: #666770;
    font-weight: bold;
    line-height: 27px;
    padding: 7px;
}

.submenu li:hover {
    background-color: #666770;
    color: #fff;
    font-weight: bold;
    line-height: 27px;
    width: 135px;
    cursor: pointer;
}
.submenu li::marker {
    color: transparent;
}
.mapadiv {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    background: transparent;
    height: 100%;
}
.letraMapa{
    position: absolute;
    bottom: 7px;
    width: 100%;
    text-align: center;
}
`;
const LayoutHeader = styled.div`

.containerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F0F1F3;
    height: 58px;
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);}

/* SUCURSALES */
.containerCiudades {
    max-width: 228px;
    width: 100%;
}

.containerflexselect {
    display: flex;
    border: 2px solid #666770;
    border-width: 1px;
    padding-left: 10px;
    align-items: center;
    margin-left: 14px;
    margin-top: 13px;
    justify-content: space-between;
    position: relative;
}

.containertextselected {
    margin-right: 18px;
}

.volvervolver {
    background: #9597A7;

}

.volvervolver img {
    width: 25px;
    padding: 4px;
    cursor: pointer;
}

.containerCiudades option {
    color: #151B4E;
}

.listaSucursalesHeader {
    list-style: none;
    background: #F0F1F3;
    display: block;
    width: 11.6%;
    top: 29px;
    left: 14px;
    /* margin-left: 8%; */
    padding-left: 9px;
    position: absolute;
    z-index: 1;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
}

.spanhedersuc {
    color: #151B4E;
    font-weight: bold !important;
}

.listaSucursalesHeader li {
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 400;
    margin-left: 10px;
    color: #666770;
}

.listaSucursalesHeader li:hover {
    color: #151B4E;
    font-weight: bold;
}



/* NOTI */
.containerUsuario {
    display: flex;
    flex-direction: row;
}

.containerNoti img {
    cursor: pointer;
    height: 70%;
    margin-right: 10px;
    padding-top: 8px;

}
.alertactive img{
    -webkit-animation-name: animate;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 58% 0;
    -webkit-animation-timing-function: ease-in-out;
}
@keyframes animate{
    0%, 100%{transform: rotate(15deg);}
    10%, 80%{transform: rotate(-15deg);}
    30%{transform: rotate(15deg);}    

}

.containerNoti {
    position: relative;
}

.containerNoti span {
    cursor: pointer;
    height: 16px;
    width: 16px;
    padding-bottom: 3px;
    padding-top: 2px;
    padding-left: 3px;
    padding-right: 2px;
    background-color: #F40000;
    border-radius: 100%;
    position: absolute;
    top: 7px;
    left: 20px;
    border: solid;
    border-width: 2px;
    border-color: #F0F1F3;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.notificacionHeader{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    /* background-color: tomato; */
    
}
.containerNotificacion{
    background: #F0F1F3;
    width: 22%;
    padding: 20px;
    padding-top: 10px;
    margin-top: 7px;
    border: solid #3BAF29 2px;
    box-shadow: -1px 7px 17px rgb(0 0 0 / 35%);
    position: absolute;
    z-index: 999;
    right: 9%;
    max-height: 20vh;
    overflow: hidden;
    overflow-y:scroll;
    border-radius: 8px;
}
.hiddenNoti{
    display: none;
}
.containerNotificacion ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.encabezado{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 2rem;
}
.origenNoti{
    color: #151B4E;
    font-size: 15px;
    display: flex;
    align-items: center;
    height: 2rem;
}
.origenNoti p{
    margin: 0px;
    font-weight: bold;
}
.noti{
    color: #666770;
    font-weight: 500;
    font-size: 14px;
    border-bottom: solid #3BAF29 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
}
.clienteNoti p{
    margin-top: 0px;
    margin-bottom: 3px;
    font-weight: bold;
    font-size: 15px;
}
.fechaNoti{
    height: 2rem;
    display: flex;
    align-items: center;
}
.estatus p{
    margin-top: 0px;
}
/* CERRAR SESION */
.containerCerrarSesion {
    background-color: #9597A7;
    padding-right: 15px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    flex-direction: row;
    width: 230px;
    text-align: center;
    align-items: center;
    padding-bottom: 8px;
}

.containerCerrarSesion img {
    height: 40px;
    margin-left: 20px;
    padding-top: 4px;
}

.containerCerrarSesion .flechaAbrir {
    height: 20px;
    margin-left: 17px;
    padding-top: 18px;
    cursor: pointer;
}

.containerCerrarSesion .flechaAbrirActive {
    height: 20px;
    margin-left: 17px;
    padding-top: 6px;
    cursor: pointer;
    transform: rotate(180deg);
    margin-top: 14px;
}

.CerrarSesion {
    /* display: flex;
    justify-content: flex-end; */
    width: 100%;
    position: relative;
}

.containerCerrar {
    background-color: #9597A7;
    width: 205px;
    border-top: solid #F0F1F3 1px;
    z-index: 2;
    margin-top: 4px;
    box-shadow: -2px 3px 15px 4px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    color: #fff !important;
    position: absolute;
    right: 0px;
}

.containerCerrar button {
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.containerCerrar img {
    height: 40px;
    margin-left: 30px;
}

`;
const LayoutGraficahome = styled.div`
width: 32%;

.graficaHome{
    position: relative;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #151B4E;
    border-radius: 17px;
    box-shadow: -1px 7px 17px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    cursor: pointer;
}
.containerGrafica{
    width: 39%;
    height: 50%;
    padding-left: 10px;
    margin-top: 14px;
}
.letrasGrafica{
    color: #fff;
    text-align: center;
    margin-left: -10px;
    padding-right: 5px
}
.letrasGrafica h3{
    padding-top: 25px;
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 50px;
    margin-left: 22px;
}
.letrasGrafica p{
    margin-top: 3px;
}
`;
const LayoutDashboard = styled.div`
    display: flex;
    flex-direction: column;

    .containerTopDashboard{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 25px;
    position: relative;
    }
    .floattotal{
        position: absolute;
color: #F0F1F3;
left: 14%;
bottom: 41%;
font-weight: bold;
font-size: 19px;
    }
    .containeran{
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-around;
        color: white;
        margin-right: 32px;
        margin-left: 5%;
    }
    .contanieranc{
        position: absolute;
        height: 10px;
        width: 10px;
        left: -14px;
        bottom: 22px;
        border-radius: 10px;
    }
    .widgetCardhome{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        background-color: #F0F1F3;
        border-radius: 17px;
        width: 60%;
        padding-left: 29px;
        box-shadow: -1px 7px 17px rgba(0, 0, 0, 0.35);
    }
    .cardhome{
        border: solid;
        border-width: 1px;
        width: 40%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        position: relative !important;
        margin-right:35px;
        overflow: hidden;
       
    }
    .cardhome2{
        border: solid;
        border-color: #35C1ED;
        border-width: 1px;
        width: 40%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        position: relative !important;
        margin-right:35px;
        overflow: hidden;
        background: #35C1ED;
    }
    .cardhome3{
        border: solid;
        border-color: #1EE27B;
        border-width: 1px;
        width: 40%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        position: relative !important;
        margin-right:35px;
        overflow: hidden;
        background: #1EE27B;
    }
    
    .cardhome img{
        width: 35%;
        height: 40%;
        position: absolute;
        top: 90px;
        left: 130px;
    }
    .cardhome h3{
        font-size: 22px;
        justify-content: center;
        text-align: center;
        color: #666770;
        position: absolute;
        top: 0px;
        margin-left: 3px;
        line-height: 20px;
    }
    .cardhome p{
        font-size: 60px;
        font-weight: bold;
        color: #151B4E;
        position: absolute;
        left: 51px;
        top: 25px;
    }

`
const Footerlayout = styled.footer`
    width: '100%';
    display: flex;
    background: #f0f1f3;
    flex: 1;
    justify-content: flex-end;
    .copymonitor p{
        margin-right: 13px;
        color: #666770;
        font-weight: bold;
    }
`

const AddfanceLayout = styled.div`
   .containneraddfanceg{
    width: 15%;
    padding-left: 14px;
    padding-right: 10px;
    margin-right: 10px;
    margin-left: 11px;
    border-radius: 13px;
    height: 100%;
    box-shadow: 1px 3px 12px rgb(149, 151, 167);
    margin-top: 6px;
    background: #F0F1F3;
   }
   .containerbuttonadd{
    text-align: center;
   }
   .containerbuttonadd button{
    background: #3caf2a;
    color: #fff;
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 15px;
   }
   .inputnameaddfance{
    width: 90%;
    margin-left: -14px;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: 1px solid #171d4f;
    border-radius: 6px;
    font-size: 18px;
   }
   .title{
  color: #fff;
  padding-top: 1px;
  padding-bottom: 1px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: right;
}
    .title h3{
        background: #171d50;
        padding: 10px 20px 10px 20px;
        border-radius: 9px;
    }
`
const ZonaMapLayout = styled.div`
    display:flex;
    flex:1;
    flex-direction: column;
`
const LayoutTable = styled.div`
    
    width:100%;
    .containerTablaZonas table {
    background-color: #F0F1F3;
    border-spacing: 0px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 25px;
    max-height: 200px;
    overflow-y: scroll;
    overflow: auto;
    border-collapse: collapse;
}

.containerTablaZonas th {
    color: #666770;
    font-weight: 400;
    font-size: 15px;
    border-bottom: solid;
    border-color: #9597A7;
    border-bottom-style: dashed;
    border-width: 1px;
}

.containerImg {
    border-width: 1px;
}

.containergris {
    background-color: #9597A7;
    height: 30px;
    width: 240px;
    text-align: center;
    align-items: center;
    padding-top: 8px;
    font-weight: bold;
    font-size: 19px;
}

.containerImg {
    display: flex;
    justify-content: center;
}
.containerImg img{
    height: 30px;
}

.activarimg {
    padding-top: 3px;

}

.divTabla {
    width: 98%;
    margin: auto;
    background-color: #F0F1F3;
    margin-top: 15px;
    border-radius: 20px;
}

.divTabla h4 {
    color: #151B4E;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
}
.containertablezones{
    width: 95%;
    margin-left: 2.5%;
    border-collapse: collapse;
    padding-bottom: 10px;
    margin-bottom: 30px;
    background: #F0F1F3;
}
.containerbodyzona{
    border-bottom: 1px dashed #9597A7;
}
    .containervermasrec{
        background: #9597a7;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
    }
    .containervermasrec img{
        position: absolute;
        width: 14px;
        height: 35px;
        left: 2px;
        top: -7px;
        padding: 4px;
    }
    .containerdivflex{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .spanredgg{
        position: absolute;
        right: 91px;
    }
`
const ZonaMonitor = styled.div`
display:flex;
flex:1;
flex-direction: row;
.Monitorcads{
    padding-left: 14px;
    padding-right: 10px;
    margin-right: 10px;
    margin-left: 11px;
    border-radius: 13px;
    height: 100%;
    box-shadow: 1px 3px 12px rgb(149,151,167);
    margin-top: 6px;
    max-height: 648px;
    overflow: hidden;
    overflow-y: scroll;
    background: #F0F1F3;
  }
.title{
    color: #fff;
  padding-top: 1px;
  padding-bottom: 1px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: right;
}
    .title h3{
        background: #171d50;
        padding: 8px 20px 8px 20px;
    }
    .containerpeticionesbox{
        display:flex;
        flex-direction: column;
        margin:0px;
        margin-bottom: 5px;
        margin-top: 7px;
    }
    .containerpeticionesbox p{
        margin:0px;
        color: #666770;
        font-size: 15px;

    }
    .containerpeticionesbox p:nth-child(1){
        color: #151B4E;
        font-weight:bold;
    }
    .containerdetallespeticion p:nth-child(1){
        color: #151B4E;
        font-weight:bold;
        margin: 0px;
    }
    .containerdetallespeticion p:nth-child(2){
        margin-top: 0px;
        color: #666770;
    }
    .containerpeticionesdetalle{
        border-bottom: 1px dashed gray;
        margin-bottom: 18px;
    }
    .containerdetallespeticion{
        display:flex;
        flex-direction: column !important;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .containerporfile{
        display:flex;
        flex-direction: row;
    }
    .succontainer{
        margin-top: 0px;
        color: #3baf2a;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
    }
    .containerporfile p{
        margin-left: 10px;
        color: #151B4E;
        font-weight: bold;
        font-size: 17px;
    }
    .containerzonatitle{
        margin-bottom: 0px;
        font-weight: bold;
    }
    .containeractionsRecolectores{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 45%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .containeractionsRecolectores img{
        cursor: pointer;
    }
    .containermodal{
        width: 80%;
        text-align: center;
        margin: auto;
    }
    .containertextwsp{
    width: 100%;
    height: 150px;
    }
    .containerbuttonswsp{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .containerbuttonenviar{
        background: #3BAF29;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #fff;
        font-weight: bold;
        margin-top: 10px;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        font-size: 16px;
    }
    .containerbuttoncerrar{
        background: red;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #fff;
        font-weight: bold;
        margin-top: 10px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
    }
`
const TableCustomMonitor = styled.div`
background: #F0F1F3 !important;
width: 97%;
margin: auto;
border-radius: 18px;
box-shadow: -1px 7px 17px rgba(0, 0, 0, 0.35);
    .containertablecustomMonitor{
        margin: auto;
        border-radius: 10px;
        border: 1px;
        }
    .containertitlependientes{
        color: #171d52;
        font-weight: bold;
        text-align: center;
        font-size: 1.4rem;
        margin: 0px !important;
        }
    .containertitlependientes h4{
        margin-bottom: 15px;
        margin-top: 15px;
        }
    .containerhedercustomtable{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        background: #9597A7;
        font-weight: bold;
        font-size: 19px;
        border-radius: 5px 5px 0px 0px;
        width: 100%;
        height: 50px;
        color: #fff;
        }
    .containerbodycustomtable{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        font-size: 15px;
        border-bottom: solid #9597A7 1px;
        border-bottom-style: dashed;
        }
        .containerbodycustomtable > div > p {
        margin-left: 10px;
        }
    `
const LayouPeticiones = styled.div`
    .containerPeticiones{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
    .containerIzq{
        width: 40%;
        display: flex;
        justify-content: center;
        color: #666770;
        font-weight: 500;
        height: 755px;
          max-height: 900px;
        padding: 13px;
    }
    .containerAdd h3{
        color: #fff;
    }
    .containerTituloAdd{
        background-color: #9597A7;
        height: 40px;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .containerDer{
        width: 50%;
        display: flex;
        flex-direction: column;
        
    }
    .containerAdd{
        background-color:#F0F1F3;
        width:100%;  
        border-radius: 18px;
        box-shadow: -1px 7px 17px rgba(0, 0, 0, 0.35);
    }
    .containerResumen{
        background-color:#F0F1F3;
        width: 95%;
        border-radius: 8px;
        box-shadow: -1px 7px 17px rgba(0, 0, 0, 0.35);
    }
    .containerRecolectores{
        background-color:#F0F1F3;
        width: 95%;
        border-radius: 8px;
        box-shadow: -1px 7px 17px rgba(0, 0, 0, 0.35);
    }
    .campocliente{
        display: flex;
        justify-content: center;
        max-width: 450px;
        margin: auto;
    }
   .campocliente input{
        background-color: #F0F1F3;
        border-color: #666770;
        width: 92%;
        height: 28px;
        margin-top: 10px;
   }
   .buscar p{
    margin-top: 0px !important;
   }
   .buscar{
    width: 460px;
   }
   .iconoBuscar{
    height: 45%;
    width: 7%;
    display: flex;
    justify-content: center;
    margin-top: 28px;
   }
   .iconoBuscar img{
    height: 36px;
    width: 36px;
    cursor: pointer;
   }
   .iconoUbi{
    margin-top: 29px;
    margin-left: 12px;
    margin-right: 4px;
   }
   .iconoUbi img{
    height: 36px;
    width: 36px;
    cursor: pointer;
   }
   .iconoEliminar{
    margin-top: 28px;
   }
   .iconoEliminar img{
    height: 37px;
    width: 45px;
    cursor: pointer;
   }
    .container1,
    .container2,
    .container3{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
   .containerDatosAdd input{
        background: transparent;
        width: 130px;
        display: block;
        margin: auto;
        height: 20px;
        border: none;
        border-bottom: solid #666770;
        border-radius: 10px;
        padding-top: 10px;
        padding-left: 10px;
   }
    .campoG input{
        width: 220px;
    }
    
    .campoEstado,
    .campoPais{
        margin-left: 25px;
    }
    .campoG{
        margin-left:70px;
    }
    .campoCiudad input,
    .campoEstado input,
    .campoPais input{
        width: 120px;
    }
    .origen{
        max-width: 444px;
        margin: auto;
    }
    .imgOrigen{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
    }
    .containerimgorigen img{
        width: 100%;
    }
    .containerimgorigen{
        margin-top:10px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        height: 100px;
        width: 100px;
        border-radius: 30px;
        padding: 5px
        
    }
    
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background: none !important;
    border-color: #fff;
    color: #fff !important;
    padding-left: 10px;
}

input:focus-visible {
    outline-offset: none !important;
}

input:focus-visible {
    outline: none !important;
    outline-offset: none !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}

    .active{
        background: #B4DBB0 !important;
    }
    .btnDetalles{
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    height: 34px;
    width: 130px;
    padding-top: 4px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    margin-left: 60%;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}
.containerResumen{
    height: 60%;
    max-height: 60%;
    overflow-y: scroll;
    max-height: 310px;
    margin-top: 15px;
}
.containerAdd p{
    margin-bottom: 0px;
    margin-top: 35px;
}
.resumen{
        background: #151B4E;
        height: 40px;
        width: 40%;
        margin: auto;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
}
.resumen{
        color: #ffff;
}
.containerLista{
    display: flex;
    flex-direction: row;
    margin-top: 0%;
    justify-content: center;
    width: 90%;
    margin: auto
}
.containerLista div{
   width: 20%;
   display: flex;
   justify-content: center;
}
.containerInfoLista{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    justify-content: center;
    margin-top: 0px;
    width: 90%;
    margin: auto
    
}
.containerInfoLista div{
    width: 10px;
   width: 20%;
   display: flex;
   justify-content: center;
}
.containerInfoLista:hover{
    background-color: #B4DBB0;

}
.containerInfoResumen p{
    color: #151B4E;
    font-weight: 500;
}
.containerRecolectores{
    margin-top: 30px;
    height: 60%;
    max-height: 410px;
    overflow-y: scroll;
}
.iconosAcciones{
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 80px;
    cursor: pointer;
}
.iconosAcciones img{
    margin-right: 15px;
}
.iconosAcciones img:nth-of-type(1){
    height: 47px;
    width: 47px;
    margin-top: 5px;
}
.iconosAcciones img:nth-of-type(3){
    height: 33px;
    width: 37px;
    margin-top: 12px;
}
.containerRecolectores h3{
    color: #151B4E;
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
.recolectores{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}
.imgrecolector{
    width: 20%;
}
.nombreRecolector{
    width: 50%;
}
.nombreRecolector p{
    color: #3BAF29;
    font-weight: 500;
}
.containerRecolectores img{
    display: block;
    margin: auto;
    padding-left: 20px;
    cursor: pointer;
}
.btnAceptarPeticion{
    margin-top: 30px;
}
.btnAceptarPeticion a{
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    padding: 8px;
    padding-left: 40px;
    padding-right: 40px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    margin-left: 47%;
}
.btnAceptarPeticion a:hover{
    background-color: #2A7A1E;
}
    `
 const LayoutSeleccionarCliente = styled.div`
 .clientes{
 background: #151B4E;
 color: #fff;
 height: 40px;
 width: 40%;
 margin: auto;
 margin-top: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 30%;
 margin: auto;
}
 .headerClientes{
     display: flex;
     flex-direction: row;
     color: #151B4E;
     font-weight: bold;
     text-align: center;
     padding-top: 35px;
 }
 .bodyClientes{
     color: #666770;
     font-weight: 500;
     text-align: center;
 }
 .containerlistclientes{
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid #9597A7 1px;
    border-bottom-style: dashed;
 }
 .containerlistclientes:hover{
    background-color: #B4DBB0;
 }
 .modalDetallesPeticion{
     width: 95%;
    margin: auto;
 }
 .nombre{
     width: 30%;
     cursor: pointer;
 }
 .rfc{
     width: 27%;   
     cursor: pointer;
 }
 .direccion{
     width: 35%;
     cursor: pointer;
 }

 .cerrar{
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
 `   
const LayoutModalDetalles = styled.div`
.detalle{
    background: #151B4E;
    height: 40px;
    width: 40%;
    margin: auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin: auto;
}
.detalle h3{
    color: #fff;
}
.containerimg img{
    width: 60%;
}
.containerimg{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
    cursor: pointer;
    margin-top: 50px;
}
.caja{
    margin-right: 30px;
}
.tarima{
    margin-left: 30px;
}
.caja,
.sobre,
.tarima{
    display: flex;
    justify-content: center;
    width: 35%;
    padding-bottom: 15px;
    padding-top: 10px;
}
.caja:hover,
.sobre:hover,
.tarima:hover{
    display: flex;
    justify-content: center;
    background-color: #B4DBB0;
    width: 35%;
    padding-bottom: 15px;
    padding-top: 10px;
    border-radius: 25px;
}
.tarima img{
    width: 80%;
    height: 110%;
    padding-left: 10px;

}
.containerTipo{
    margin-top: 40px;
}
.containerCantidad{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}
.containerMedidas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 80px;
}
.containerMedidas div,
.containerCantidad div{
    margin-left: 50px;
}
.containerMedidas div:nth-of-type(1),
.containerCantidad div:nth-of-type(1){
    margin-left: 0px;
}

.btnAceptarDetalles button{
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    height: 34px;
    width: 130px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}
.btnAceptarDetalles button:hover{
    background-color: #2A7A1E;
}
.btnAceptarDetalles{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding-bottom: 25px;
}

.modalDetallesPeticion p{
    text-align: center;
    color: #666770;
    font-weight: 500;
    margin-bottom: 10px;
}
.modalDetallesPeticion input{
    background: transparent;
    width: 70px;
    display: block;
    margin: auto;
    height: 20px;
    border: none;
    border-bottom: solid #666770;
    border-radius: 10px;
    padding-left: 10px;
}
.containerMedidas input{
    text-align: center;
}
.inputCont input{
    width: 230px;
}
.cerrar{
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.active{
        background: #B4DBB0 !important;
    }
    .containerlistclientes{
        width: 570px;
        padding: 7px;
        text-align: center;
        border-radius: 14px;
        margin-top: 11px;
        cursor: pointer;  
    }
    .containerlistclientes:hover{
        background: #B4DBB0 !important;
    }
    //sf
    
 .headerDirecciones{
     display: flex;
     flex-direction: row;
     color: #151B4E;
     font-weight: bold;
     text-align: center;
     padding-top: 35px;
 }
 .bodyDirecciones{
    display: flex;
     flex-direction: row;
     color: #666770;
     font-weight: 500;
     text-align: center;
 }
  .containerlistdirec{
    align-items: center;
    border-bottom: solid #9597A7 1px;
    border-bottom-style: dashed;
 } 
 .containerlistdirec:hover{
    background-color: #B4DBB0;
 }
 .modalDetallesPeticion{
     width: 95%;
    margin: auto;
 }
 .calle{
     width: 25%;
     cursor: pointer;
 }
 .Num{
     width: 10%;   
     cursor: pointer;
 }
 .colonia{
     width: 25%;
     cursor: pointer;
 }
 .ciudad{
    width: 20%;
     cursor: pointer; 
 }
 .estado{
     width: 20%;
 }
`
const LayoutGrafica = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    
    height: 82.5vh;
    max-height: 82.5vh;
    overflow-x: scroll;
    overflow-y: hidden;
    .wrapertop{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px,auto);
        width: 100%;
    }
    .containergraficasdonas{
        background: #f0f1f3;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 10px;
    margin: 25px;
    border-radius: 10px;
    flex-direction: column;
    height: 33vh;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .containerbodyanalitica{
        margin-top: -100px;
        margin-left: 10%;
    }
    .continerSessionUsers{
        grid-column: 1 / 3;
        grid-row: 1;
    }
    
`
const LayoutTablaPeticion = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
.headerTabla{
    display: flex;
    flex-direction: row;
    width: 90%;
    background: #9597A7;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}
.headerTabla div,
.bodyTabla div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bodyTabla p{
text-align: center;
}
.bodyTabla{
    height: 338px !important;
}
.containerlastpetitions{
    position: relative !important;
}
.containeractionstablepetitions{
position: absolute;
right: 8px;
top: 5px;
width: 35px !important;
height: 35px;
border-radius: 100%;
}
.active{
position: absolute;
right: 8px;
top: 5px;
width: 35px !important;
height: 35px;
background-color: #D9D9D9;
border-radius: 100%;
opacity: 40%;
cursor: pointer;

}
.containerrowstablepetitions p{
    font-size: 13px;
    text-align: center;
}
.containeractionstablepetitions span{
    position: absolute;
    background: #9597A7;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    cursor: pointer;
}
.containeractionstablepetitions span:nth-child(1){
    bottom: 26px;
}
.containeractionstablepetitions span:nth-child(2){
    bottom: 16px;
}
.containeractionstablepetitions span:nth-child(3){
    bottom: 6px;
}
.containerrowstablepetitions{
    width: 100%;
    border-bottom: solid;
    border-color: #9597A7;
    border-bottom-style: dashed;
    border-width: 1px;
}
.headerTabla p{
    color: #fff;
    font-weight: 500;
    font-size: 19px;
}
.bodyTabla{
    display: flex;
    flex-direction: column;
    width: 90%;
    background: #F0F1F3;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #666770;
    font-weight: 500;
    max-height: 54vh;
    overflow: hidden;
    overflow-y: scroll;
}
.divOrigen img{
    height: 32%;
    width: 32%;
}
.divOrigen,
.divEstatus{
    width: 10% !important;
}
.divRec{
    width: 10% !important;
}
.divCliente{
    width: 20%  !important;
}
.divHora{
    width: 15% !important;
}
.divDir{
    width: 35% !important;
}

.containerAction{
    display: flex;
    flex-direction: column !important;
    position: absolute !important;
    top: 43px;
    right: 8px;
    /* width: 65% !important; */
    background: #D9D9D9;
    border-radius: 8px;
    z-index: 2;
    box-shadow: -1px 7px 17px rgb(0 0 0 / 35%);    
    border: solid #D4D3D3 1px;
    color: #666770;
    font-weight: bold;
    cursor: pointer;
}
.reasignar{
    display: flex !important;
    flex-direction: row;
}
.containerAction img{
    padding-right: 12px;
}
.reasignar{

}
.modificarEstatus img{
    padding-right: 20px;
    height: 23px;
}
//PAGINADOR
.paginador{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-end;
    margin-top: 20px;
}
.num{
    background: #fff;
    height: 33px;
    width: 35px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 10px;
    padding-top: 2px;
}
.flecha{
    background: #fff;
    height: 35px;
    width: 35px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 10px;
}
.flecha img{
    height: 20px;
    width: 20px;
}
`
const LayoutSeleccionarRec = styled.div`
.containerlistclientes{
    text-align: center !important;
    cursor: pointer;
}

.recolectores{
 background: #151B4E;
 color: #fff;
 height: 40px;
 width: 50% !important;
 margin: auto;
 margin-top: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 30%;
 text-align: center;
 margin: auto;
}

.bodyClientes{
    margin-top: 30px;
}
.containerlistclientes p{
    padding-left: 25px;
    
}
.containerlistclientes img{
    padding-left: 40px;
}
 .containerlistclientes{
    display: flex;
    align-items: center;
    height: 75px;
    border-bottom: solid #9597A7 1px;
    border-bottom-style: dashed;
 }
 .containerlistclientes:hover{
    background-color: #B4DBB0;
 }
 .cerrar{
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.editarEstatus{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.clienteEstatus{
    padding-top: 10px;
    padding-left: 67px;
    padding-bottom: 20px;
}
.clienteEstatus h3,
.editarEstatus h3{
    color: #151B4E;
}
.clienteEstatus p,
.editarEstatus p{
    color: #666770;
    font-weight: 500;
}
`
const LayoutListaPeticion = styled.div`
.containerListaPeticiones{
    display: flex;
    flex-direction: column;
}
.containerTitulo{
    display: flex;
    justify-content: center;
    color: #151B4E;
    font-size: 26px;
}
/* .containerTabla{
    max-height: 54vh;
    overflow: hidden;
    overflow-y: scroll;
} */
.containerlistpetitionactions{
    width: 100%;
    margin: auto;
}
.containerbuttonsheaderlist{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.containerbuttonsheaderlist p{
    margin: 0px;
}
.inputListPet{
    margin-bottom: 20px;
}
.buscar{
    display: flex;
    flex-direction: row;
}
.buscar input{
    height: 27px;
    width: 280px;
    border-radius: 4px;
    border: solid #666770 1px;
    margin-top: 2px;
}
.calendar input{
    height: 27px;
    border-radius: 4px;
    border: solid #666770 1px;
    padding-left: 7px;
    color: #666770;
    margin-top: 2px;
}
.buscar img,
.calendar img{
    height: 31px;
    padding-left: 5px;
    margin-top: 23px;

}
.calendar{
    display: flex;
    flex-direction: row;
    width: 35%;
}
.relleno{
    width: 10%;
}

.containerlistpetitionactions{
    display:flex;
    flex-direction: row;
    width: 90%;
}
.containerlistpetitionactions img{
    cursor: pointer;
}
.containerbuttonsheaderlist{
    flex: 1
}
.estatus {
    max-width: 228px;
    width: 100%;
}
.containerflexselect {
    position: relative;
    display: flex;
    background: #fff;
    border: 2px solid #666770;
    border-width: 1px;
    padding-left: 7px;
    align-items: center;
    margin-top: 2px;
    justify-content: space-between;
    border-radius: 4px;
}
.containertextselected {
    margin-right: 18px;
}
.volvervolver {
    background: #9597A7;
    border-radius: 0px 3px 3px 0px;
}
.volvervolver img {
    width: 25px;
    padding: 4px;
    cursor: pointer;
}
.divselected{
    position: fixed;
}
.divselecteddd{
    position: relative;
    width:100%;
}
.listaEstatus {
    list-style: none;
    background: #F0F1F3 !important;
    display: block;
    width: 96.5%;
    padding-bottom: 10px;
    top: -16px;
    padding-left: 8px;
    position: absolute;
    z-index: 1;
    border-radius: 2px 5px 5px 3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));

}

.spanhedersuc {
    color: #151B4E;
    font-weight: bold !important;
}

.listaEstatus li {
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 400;
    margin-left: 10px;
    color: #666770;
}
.listaEstatus li:hover {
    color: #151B4E;
    font-weight: bold;
}
`
    ;
export { Layoutcontainer, LayoutcontainerLogin, StyledTable, LayoutLoader, LayoutSidebar, LayoutHeader, LayoutGraficahome, LayoutDashboard, Footerlayout, AddfanceLayout, ZonaMapLayout, LayoutTable, ZonaMonitor, TableCustomMonitor, LayouPeticiones, LayoutSeleccionarCliente, LayoutModalDetalles, LayoutGrafica, LayoutTablaPeticion, LayoutListaPeticion,LayoutSeleccionarRec};
