import React,{useState} from 'react';
import ZonasTable from '../components/tablas/ZonasTable';
import { ZonaMapLayout } from '../themes/layout'
import {GeofanceMap} from '../components/mapas/geofanceMap';
import useGetZona from '../hooks/apis/get/useZona';

function Zona() {
    const {loadZonas,loadingzona,datoZona,geoJSonZona} = useGetZona();
    const [headerTable, setheaderTable] = useState({
        nombre: "Nombre",
        sucursal: "Sucursal",
        recolector: "Recolector",
        acciones: "Acciones"
      })
  return (
    <ZonaMapLayout>
        {
            loadingzona === true ?
            <>
            <h1>Cargando...</h1>
            </>
            :
            <>
            <GeofanceMap
                datosdezona={datoZona}
                geoJsonZona={geoJSonZona}
            />  
            <ZonasTable
                header={headerTable}
                data={datoZona}
            />
            </>
        }
    </ZonaMapLayout>
  )
}

export default Zona