import React from 'react';
import Widgettophome from '../widgets/widgettophome';
import documentaciones from '../../assets/img/documentaciones.png';
import peticionesZona from '../../assets/img/peticionesZona.png';
import peticionesSinAsig from '../../assets/img/peticionesSinAsig.png';

const ContainerCardhome = () => {
  const data1 = {
      dataset: "30",
      icon: peticionesZona,
      color: "Naranja"   
  }
  const data2 = {
    dataset: "80",
    icon: peticionesSinAsig,
    color: "Azul"
}
const data3 = {
  dataset: "50",
  icon: documentaciones,
  color: "Verde"
}

  return (
     <div className="widgetCardhome">
       <Widgettophome
        titulo="Peticiones de zona 1"
        data={data1}
       ></Widgettophome>
        <Widgettophome
        titulo="Peticiones sin asignar"
        data={data2}
       ></Widgettophome>
       <Widgettophome
        titulo="Documentaciones realizadas"
        data={data3}
       ></Widgettophome>
    </div>
   
    
   
  )
}

export default ContainerCardhome