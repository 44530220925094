import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Layoutcontainer } from "../themes/layout";
import { useAuth } from "../context/Authcontext";
import SidebarComponent from "../components/Sidebarcomponent";
import Headercomponent from "../components/header/header";
import Footer from "../components/footer/footer";
import imgpetition from "../assets/img/locationpetition.svg"

export function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  if (!user) return <Navigate to="/login" />;
  return <Layoutcontainer imgpetition={imgpetition}>
    <div style={{display:'flex', flexDirection:'row'}}>
      <div style={{flex:'1',background:'#F0F1F3'}} className='containersidebar'>
       <SidebarComponent></SidebarComponent>
      </div>
        <div style={{flex:'11', position:'relative', height: '100vh' ,overflowY:'scroll', background: '#D9D9D9'}}>
          <Headercomponent></Headercomponent>
          {/* <p className="containerusername">Bienvenido {user.descripcion}</p> */}
          <div style={{maxHeight:'100vh', height:'85vh',overflowY:'scroll',paddingTop: '30px'}}>
          {children}
          </div>
          <Footer></Footer>
        </div>
    </div>
  </Layoutcontainer>;
}