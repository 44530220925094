import React, {useEffect,useState} from 'react';
import axios from 'axios';
import * as turf from "@turf/turf";
import config from '../../../config';
const useGetZona = () => {
    const [datoZona, setdatoZona] = useState();
    const [geoJSonZona, setgeoJSonZona] = useState()
    const [loadingzona, setloadingzona] = useState(false)

    useEffect(() => {
        setloadingzona(true)
        var configzonas = {
            method: 'get',
            url: `${config.API_URL}Geolocalizacion/getgeofanceall`,
            headers: { }
          };
          axios(configzonas)
          .then(function (response) {
            setloadingzona(false)
            if(response.status === 204){
              setdatoZona([])
              setgeoJSonZona([])
            }else {
              setdatoZona(response.data)
              response.data?.map(responsecords =>{
                let Arraygeocerca = new Array(responsecords.cords)
                let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                const cordenadas = ObjGeocerca.map(resto => {return resto});  
                let geocercadata = turf.polygon(cordenadas);
                setgeoJSonZona(geocercadata)
              })
            }
          
          })
          .catch(function (error) {
            setloadingzona(false)
            console.log(error);
          });
    }, [setdatoZona])
    
    const loadZonas = () =>{
        setloadingzona(true)
        var configzonas = {
            method: 'get',
            url: `${config.API_URL}Geolocalizacion/getgeofanceall`,
            headers: { }
          };
          
          axios(configzonas)
          .then(function (response) {
            setloadingzona(false)
            setdatoZona(response.data)
          })
          .catch(function (error) {
            setloadingzona(false)
            console.log(error);
          });
    }


  return {
        datoZona,
        loadZonas,
        loadingzona,
        geoJSonZona
  }
}

export default useGetZona