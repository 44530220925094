import React,{useState} from 'react'
import { LayoutTable } from '../../themes/layout'
import imgActivar from '../../assets/img/activar.svg';
import imgDesactivar from '../../assets/img/inactivar.svg';
import imgEditar from '../../assets/img/editar.svg';
import imgAbrir from '../../assets/img/abrir.svg';
function ZonasTable(props) {
  const [headerDatos, setheaderDatos] = useState(Object.values(props?.header));
  const [datos, setdatos] = useState(props?.data);
  const [isactive, setisactive] = useState(null);
  const handlerisactive = (data) =>{
    if(isactive === data){
      setisactive(null)
    }else{
      setisactive(data)
    }
  } 
  return (
   <LayoutTable>
<div className='divTabla'>
<h4>Zonas activas</h4>
  </div>
      <table className='containertablezones'>
        {
          headerDatos?.map(responseheader => {
            return (
              <td className='containergris'>{responseheader}</td>
            )
          })
        }
          {
              props?.data === "" ? 
              <>
                <p>Sin datos registre nueva zona</p>
              </>
              :
              datos?.map((responsedata,index) => {
                return (
                  <tr className='containerbodyzona'>
                    <th className='campoCliente'>{responsedata.observaciones}</th>
                    <th>{responsedata.nombreciudad}</th>
                    <th>
                      <div className='containerdivflex'>
                         <p>{responsedata.recolectores[0]?.nombre}</p>
                         <span className='spanredgg'>{responsedata.recolectores?.length > 1 ? <div onClick={() => handlerisactive(index)} className='containervermasrec'>
                          <img src={imgAbrir}/>
                         </div> : ''}</span>
                      </div>
                      <div>
                        {
                          responsedata.recolectores.map((res,indexs)=>{
                              return(
                                <>
                                  <div style={isactive === index ? {background:'transparent'} : {display:'none'}}>
                                    <p>{responsedata.recolectores[0]?.nombre === res.nombre ? '' : res.nombre}</p>
                                  </div>
                                </>
                              )
                          })
                        }
                      </div>
                    </th>
                    <td>
                      <div className="containerImg">
                          {/* <img src={imgEditar}  onClick={(e) => props.handlereditzona(e,true)} />
                          <img src={imgDesactivar} alt="" onClick={(e) => handletest(e)} /> */}
                      <img src={imgEditar}/>
                      <img src={imgDesactivar}/>
                      <img className='activarimg' src={imgActivar} alt=""/>
                      </div>
                    </td>
                  </tr>
                )
              })
          }
        </table>
   </LayoutTable>
  )
}

export default ZonasTable