const URL_DESAROLLO = process.env.REACT_APP_API_URL
const URL_PROD = process.env.REACT_APP_PROD_URL
const URL_SOCKET = process.env.REACT_APP_SOCKET_URL
const URL_SOCKETDESAROLLO = process.env.REACT_APP_SOCKETDESAROLLO_URL
const API_SOCKET = `${URL_SOCKET}`
const API_URL = `${URL_PROD}/api/v2/`



export default {
    API_URL,
    API_SOCKET
}


