import React from 'react';
import { createContext } from 'react';
import { useSocket } from '../hooks/useSocket'

export const SocketContext = createContext();


export const SocketProvider = ({ children }) => {
    //const { socket, online } = useSocket('https://ubi.paq1.com.mx/');
    //  const { socket, online,recolectoressocket } = useSocket('https://api.argoxx.com/');
  // const { socket, online,recolectoressocket } = useSocket('http://10.148.210.55:3001/'); // NODE
   //const { socket, online,recolectoressocket } = useSocket('https://apidesarrollo.paq1.com.mx/hub/map/picker/'); // NODE
    const { socket, online,recolectoressocket } = useSocket('https://socket.paq1.com.mx/hub/map/picker/');
  // const { socket, online,recolectoressocket } = useSocket('https://socketdesarrollo.paq1.com.mx/hub/map/picker/');
// const { socket, online } = useSocket('http://192.168.100.48:3001/');
    return (
        <SocketContext.Provider value={{ socket, online, recolectoressocket}}>
            { children }
        </SocketContext.Provider>
    )
}
